import {
  TextField,
  Button,
  Grid,
  Card,
  IconButton,
  CircularProgress,
  Chip,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import styled from "styled-components";
import RecipientCard from "./shared/RecipientCard";
import { useParams } from "react-router-dom";
import OrderDetailNav from "./OrderDetailNav";
import { useDashboardContext } from "../../contexts/DashboardContext/DashboardContext";
import { useAuth } from "../../contexts/AuthContext";
import MenuSidebar from "../../components/MenuSideBar";
import SingleSelect from "../../components/AppSelect/SingleSelect";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BatchModal from "../batch-modal/BatchModal";
import { Toast } from "../../components/Toaster/Toaster";
import CustomTable from "../../components/Table/CustomTable";
import SynnexModel from "./shared/SynnexModel";
import IngramModel from "./shared/IngramModel";
import { useForm } from "react-hook-form";
import { CARRIER_LOGOS } from "../../utils/constant";
import CancelIcon from "@mui/icons-material/Cancel";
const PackageCardContainer = styled("div")(() => ({
  marginTop: 20,
}));
const RadioUnCheckButton = styled("div")(() => ({
  width: "20px",
  height: "20px",
  position: "relative",
  background: "white",
  borderRadius: "10px",
  border: "1px #d0d5dd solid",
}));
const RadioCheckButton = styled("div")(() => ({
  width: "20px",
  height: "19px",
  position: "relative",
  background: "#ebf8f9",
  borderRadius: "11px",
  border: "1px solid #3d3bde",
  overflow: "hidden",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
}));
const RadioButtonDot = styled("div")(() => ({
  width: "6px",
  height: "6px",
  background: "#3d3bde",
  borderRadius: "9999px",
  border: "1px #d0d5dd solid",
}));
const unit_values = [
  { value: "in", label: "in" },
  { value: "cm", label: "cm" },
  { value: "mm", label: "mm" },
];
const weight_values = [
  { value: "lb", label: "lb" },
  { value: "g", label: "g" },
  { value: "oz", label: "oz" },
  { value: "kg", label: "kg" },
];
const senderAddressValue = {
  name: "Prestige IT Goods",
  company: "A5 IT",
  street1: "1320 S VIRGINIA RD",
  state: "IL",
  zip: "60014",
  city: "CRYSTAL LAKE ",
  country: "US",
  email: "info@a5it.com",
  phone: "+1 8475059936",
};
const formValues = {
  length: "",
  width: "",
  height: "",
  unit: "in",
  weight: "",
  weightUnit: "lb",
};
const sLInitialState = {
  sLicenseAuthNumber: "",
  sLicenseReOrder: "Y",
  sLicenseAddress1: "",
  sLicenseAddressLine1: "",
  sLicenseCity: "",
  sLicenseState: "",
  sLicenseZipCode: "",
  sLicenseCountry: "",
  sLicenseContactName: "",
  sLicensePhoneNumber: "",
  sLicenseFaxNumber: "",
  sLicenseEmailAddress: "",
};

const eUInitialState = {
  eUserPONumber: "",
  eUserType: "",
  eUserReOrder: "N",
  eUserAddressName1: "",
  eUserAddressLine1: "",
  eUserCity: "",
  eUserState: "",
  eUserZipCode: "",
  eUserCountry: "",
  eUserContactName: "",
  eUserPhoneNumber: "",
  eUserFaxNumber: "",
  eUserEmailAddress: "",
};
const endUserTypeOptions = [
  { label: "Media", value: "Media" },
  { label: "Education", value: "Education" },
];
const OrderDetails = () => {
  const { id, orderType } = useParams();
  const {
    getAmzRatesApiCall,
    amzRates,
    walmartRates,
    ratesLoading,
    setPdfValue,
    createLabelLoading,
    orderDetailsLoading,
    getOrderDetailsApiCall,
    amzAllOrderDetails,
    ebayOrderDetails,
    walmartOrderDetails,
    updatingTrackingLoading,
    resetOrderDetails,
    setPdfType,
    postCreatePdfApiCall,
    createPdfLoading,
    postPurchaseShipmentCallApi,
    itemShipmentCancelCallApi,
    setAmzRates,
    displayPopup,
    setDisplayPopup,
    postCreatePackageSlipApiCall,
    getDeleverDayRatesApi,
    getWalmartRatesApi,
    deleveryDayRates,
    getAFiveRatesApi,
    aFiveRates,
    postPurchaseUpsShipmentCallApi,
    aFiveItemShipmentCancelCallApi,
    postBySynnexCreateOrderCall,
    postByShipWarehouse,
    shipWarehouse,
    setShipWarehouse,
    postByWalmartCreateOrderAPICALL,
    setIngramResponse,
  } = useDashboardContext();

  const [senderAddress, setSenderAddress] = useState(senderAddressValue);
  const [anchorUnit, setAnchorUnit] = useState(null);
  const [anchorWeight, setAnchorWeight] = useState(null);
  const [isCardSelected, setIsCardSelected] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [isBuyButtonDisabled, setIsBuyButtonDisabled] = useState(true);
  const [isEdited, setIsEdited] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [distanceValue, setDistanceValue] = useState("in");
  const [massValue, setMassValue] = useState("lb");
  const [selectItemValue, setselectedItemValue] = useState([]);
  const [values] = useState(formValues);
  const [rateData, setRateData] = useState();
  const [selectedRate, setSelectedRate] = useState("");
  const [itemQuantities, setItemQuantities] = useState(0);
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("A5 IT");
  const [itemSelected, setItemSelected] = useState(true);
  const [payload, setPayload] = useState("");
  const [a5Payload, setA5Payload] = useState("");
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const { user } = useAuth();
  const [itemSku, setItemSku] = useState("");
  const [formData, setFormData] = useState({
    customerLineNumbers: selectItemValue?.map((_, index) =>
      (index + 1).toString()
    ),
    ingramPartNumbers: Array(selectItemValue.length).fill(""),
    quantities: selectItemValue?.map((item) =>
      item?.QuantityOrdered.toString()
    ),
  });
  const [eUserType, setEUserType] = useState(endUserTypeOptions[0]);
  const [itemErrors, setItemErrors] = useState({
    customerNumber: false,
    quantity: "",
    sku: "",
    check: "",
  });
  const [LineErrors, setLineErrors] = useState({
    ingramPartNumber: "",
    quantity: "",
    customerOrderNumber: "",
  });
  const [sLicense, setSLicense] = useState(sLInitialState);
  const [sLicenseChecked, setSLicenseChecked] = useState(false);
  const [specialOrder, setSpecialOrder] = useState(false);
  const [eUserChecked, setEUserChecked] = useState(false);
  const [eUser, setEUser] = useState(eUInitialState);
  const [boxItem, setBoxItem] = useState([]);
  const [boxDimensions, setBoxDimensions] = useState([]);

  const grouped =
    aFiveRates && aFiveRates.length > 0
      ? aFiveRates.reduce((acc, obj) => {
          const code = obj?.Service?.Code;
          if (
            !acc[code] ||
            acc[code]?.RatedPackage?.TotalCharges?.MonetaryValue >
              obj?.RatedPackage?.TotalCharges?.MonetaryValue
          ) {
            acc[code] = obj;
          }
          return acc;
        }, {})
      : [];

  const filtered = Object?.values(grouped);

  useEffect(() => {
    getOrderDetailsApiCall(id, orderType, user?.id);
  }, [id, orderType, user]);

  const {
    handleSubmit: handleSynnexSubmit,
    control: synnexControl,
    register: synnexRegister,
    formState: { errors: synnexErrors },
    reset: synnexReset,
    setValue: synnexSetValue,
  } = useForm({
    reValidateMode: "onChange",
  });
  const {
    handleSubmit: handleIngramSubmit,
    control: ingramControl,
    register: ingramRegister,
    formState: { errors: ingramErrors },
    reset: ingramReset,
    setValue: ingramSetValue,
  } = useForm({
    reValidateMode: "onChange",
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
  });

  const amzOrderItemColumn = [
    {
      id: 1,
      name: <Box className="text-wrap">Order Item ID</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>{row?.OrderItemId}</Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 2,
      name: <Box className="text-wrap">Product Name</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>{row?.Title}</Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 3,
      name: <Box className="text-wrap">Status</Box>,
      cell: (row) => {
        return (
          <>
            <Chip
              size="small"
              color={
                row?.status === "Cancelled"
                  ? "error"
                  : row?.status === "Partially Ship"
                  ? "primary"
                  : row?.status === "Shipped"
                  ? "success"
                  : "default"
              }
              variant={"outlined"}
              label={row?.status ? row?.status : "Unshipped"}
            />
          </>
        );
      },
      sortable: false,
    },

    {
      id: 5,
      name: <Box className="text-wrap">Quantity</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              {row?.QuantityOrdered}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Shipped</Box>,
      cell: (row) => {
        const totalShipQuantity = row.shipmentDetails
          ? row.shipmentDetails.reduce((accumulator, currentItem) => {
              return accumulator + currentItem.quantity;
            }, 0)
          : 0;
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>{totalShipQuantity}</Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Unshipped</Box>,
      cell: (row) => {
        const totalShipQuantity = row.shipmentDetails
          ? row.shipmentDetails.reduce((accumulator, currentItem) => {
              return accumulator + currentItem.quantity;
            }, 0)
          : 0;
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              {parseInt(row?.QuantityOrdered) - parseInt(totalShipQuantity)}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 5,
      name: <Box className="text-wrap">Enter Ship Quantity</Box>,
      cell: (row) => {
        const totalShipQuantity = row.shipmentDetails
          ? row.shipmentDetails.reduce((accumulator, currentItem) => {
              return accumulator + currentItem.quantity;
            }, 0)
          : 0;
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              <TextField
                defaultValue={itemQuantities}
                onChange={handleQuantityChange}
                name="quantity"
                size="small"
                type="number"
                inputProps={{
                  min: 0,
                  max:
                    parseInt(row?.QuantityOrdered) -
                    parseInt(totalShipQuantity),
                }}
                style={{ width: 90 }}
              />
            </Box>
          </>
        );
      },
      sortable: false,
    },

    {
      id: 6,
      name: <Box className="text-wrap">Total Price</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              {row?.ItemPrice?.CurrencyCode}
              <span>{row?.ItemPrice?.Amount}</span>
            </Box>
          </>
        );
      },
      sortable: false,
    },
  ];
  const walmartOrderItemColumn = [
    {
      id: 1,
      name: <Box className="text-wrap">Order Item ID</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>{row?.lineNumber}</Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 2,
      name: <Box className="text-wrap">Product Name</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              {" "}
              {row?.item?.productName}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 3,
      name: <Box className="text-wrap">Status</Box>,
      cell: (row) => {
        function getColor(status) {
          switch (status) {
            case "Cancelled":
              return "error";
            case "Partially Ship":
              return "primary";
            case "Shipped":
              return "success";
            default:
              return "default";
          }
        }

        const status = row?.status || "Unshipped"; // Default to "Unshipped" if status is undefined
        const chipColor = getColor(status);

        return (
          <Chip
            size="small"
            color={chipColor}
            variant="outlined"
            label={status}
          />
        );
      },
      sortable: false,
    },

    {
      id: 5,
      name: <Box className="text-wrap">Quantity</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              {row?.orderLineQuantity?.amount}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Shipped</Box>,
      cell: (row) => {
        const totalShipQuantity = row.shipmentDetails
          ? row.shipmentDetails.reduce((accumulator, currentItem) => {
              return accumulator + currentItem.quantity;
            }, 0)
          : 0;
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>{totalShipQuantity}</Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Unshipped</Box>,
      cell: (row) => {
        const totalShipQuantity = row.shipmentDetails
          ? row.shipmentDetails.reduce((accumulator, currentItem) => {
              return accumulator + currentItem.quantity;
            }, 0)
          : 0;
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              {parseInt(row?.orderLineQuantity?.amount) -
                parseInt(totalShipQuantity)}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 5,
      name: <Box className="text-wrap">Enter Ship Quantity</Box>,
      cell: (row) => {
        const totalShipQuantity = row.shipmentDetails
          ? row.shipmentDetails.reduce((accumulator, currentItem) => {
              return accumulator + currentItem.quantity;
            }, 0)
          : 0;
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              <TextField
                defaultValue={itemQuantities}
                onChange={handleQuantityChange}
                name="quantity"
                size="small"
                type="number"
                inputProps={{
                  min: 0,
                  max:
                    parseInt(row?.QuantityOrdered) -
                    parseInt(totalShipQuantity),
                }}
                style={{ width: 90 }}
              />
            </Box>
          </>
        );
      },
      sortable: false,
    },

    {
      id: 6,
      name: <Box className="text-wrap">Total Price</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              CA$
              {row?.charges?.charge?.[0]?.chargeAmount?.amount}
            </Box>
          </>
        );
      },
      sortable: false,
    },
  ];

  const ebayOrderItemColumn = [
    {
      id: 1,
      name: <Box className="text-wrap">Product Name</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>{row?.title}</Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 2,
      name: <Box className="text-wrap">Status</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>UnShipped</Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 3,
      name: <Box className="text-wrap">Quantity</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>{row?.quantity}</Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Total Price</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              CA${row?.lineItemCost?.value}
            </Box>
          </>
        );
      },
      sortable: false,
    },
  ];
  const amzOrderStatus = amzAllOrderDetails?.status;
  const ebayStatus = ebayOrderDetails?.status;
  const walmartStatus = walmartOrderDetails?.status;
  const currentStatusValue =
    amzAllOrderDetails?.status ||
    ebayOrderDetails?.status ||
    walmartOrderDetails?.status;
  const trackingNumber =
    amzAllOrderDetails?.trackingNumber ||
    ebayOrderDetails?.trackingNumber ||
    walmartOrderDetails?.trackingNumber;
  const trackingUpdated =
    amzAllOrderDetails?.trackingUpdated ||
    ebayOrderDetails?.trackingUpdated ||
    walmartOrderDetails?.trackingUpdated;
  const providerImage =
    amzAllOrderDetails?.providerImage ||
    ebayOrderDetails?.providerImage ||
    walmartOrderDetails?.providerImage;
  const providerName =
    amzAllOrderDetails?.providerName ||
    ebayOrderDetails?.providerName ||
    walmartOrderDetails?.providerName;
  const labels =
    amzAllOrderDetails?.label ||
    ebayOrderDetails?.label ||
    walmartOrderDetails?.label;
  const disablePackages =
    amzOrderStatus === "Ready for Ship" ||
    ebayStatus === "Ready for Ship" ||
    walmartStatus === "Ready for Ship" ||
    amzOrderStatus === "Shipped" ||
    ebayStatus === "Shipped" ||
    walmartStatus === "Shipped"
      ? true
      : false;

  const generateSuccessToast = (textValue) => {
    Toast.success(textValue);
  };

  const handleUnitSelectClick = (event) => {
    setAnchorUnit(event.currentTarget);
  };
  const handleUnitSelectClose = () => {
    setAnchorUnit(null);
  };

  const handleUnitClick = (value) => {
    setDistanceValue(value);
    handleUnitSelectClose();
    setIsEdited(true);
  };
  const handleWeightSelectClose = () => {
    setAnchorWeight(null);
  };
  const handleWeightSelectClick = (event) => {
    setAnchorWeight(event.currentTarget);
  };
  const handleWeightClick = (value) => {
    setMassValue(value);
    handleWeightSelectClose();
    setIsEdited(true);
  };

  const handleSelectUpsRate = (item) => {
    setActiveCard(
      item?.Service?.Code === activeCard ? null : item?.Service?.Code
    );
    setIsCardSelected(!isCardSelected);
    const itemsData = selectItemValue.map((item) => ({
      itemId: item?.OrderItemId,
      shipQuantity: itemQuantities,
    }));
    const itemData = selectItemValue.map((selectedItem) => {
      const quantity = parseInt(selectedItem.QuantityOrdered);
      const unitPrice = parseInt(selectedItem.ItemPrice.Amount);
      const taxPrice = parseInt(selectedItem.ItemTax.Amount);
      const subTotal = unitPrice;
      const total = subTotal + taxPrice;
      return {
        quantity: quantity,
        title: selectedItem.Title,
        sku: selectedItem.SellerSKU,
        asin: selectedItem.ASIN,
        ItemCondition: selectedItem.ConditionSubtypeId,
        itemId: selectedItem.OrderItemId,
        unitPrice: selectedItem?.ItemPrice?.Amount,
        taxPrice: selectedItem?.ItemTax?.Amount,
        subTotal: subTotal,
        refund: "-",
        initialTotal: total,
        itemTotal: total,
      };
    });
    const grandTotal = itemData.reduce(
      (total, item) => total + item.itemTotal,
      0
    );
    const date = new Date(amzAllOrderDetails.PurchaseDate);
    const formattedDate = `${date.getDate()},${
      monthNames[date.getMonth()]
    } ${date.getFullYear()} ${date.toTimeString().slice(0, 8)}${date
      .toISOString()
      .slice(-6)}`;
    const shipmentReqest = {
      orderId: id,
      carrierName: "UPS",
      serviceName: item?.TimeInTransit?.ServiceSummary?.Service?.Description,
      address_to: payload.address_to,
      itemData: itemData,
      grandTotal: grandTotal,
      orderDate: formattedDate,
      itemsInfo: itemsData,
      ShipmentRequest: {
        Shipment: {
          Shipper: {
            Name: "ShipperName",
            AttentionName: "ShipperZs Attn Name",
            TaxIdentificationNumber: "123456",
            Phone: {
              Number: "1115554758",
              Extension: " ",
            },
            ShipperNumber: "W6168A",
            FaxNumber: "8002222222",
            Address: {
              AddressLine: "2311 York Rd",
              City: "Timonium",
              StateProvinceCode: "MD",
              PostalCode: "21093",
              CountryCode: "US",
            },
          },
          ShipTo: {
            Name: payload?.address_to?.name,
            AttentionName: "1160b_74",
            Phone: { Number: "9225377171" },
            Address: {
              AddressLine: payload?.address_to?.street1,
              City: payload?.address_to?.city,
              StateProvinceCode: payload?.address_to?.state,
              PostalCode: payload?.address_to?.zip,
              CountryCode: payload?.address_to?.country,
            },
          },
          ShipFrom: {
            Name: payload?.address_from?.name,
            AttentionName: "1160b_74",
            Phone: { Number: "1234567890" },
            FaxNumber: "1234567890",
            Address: {
              AddressLine: payload?.address_from?.street1,
              City: payload?.address_from?.city,
              StateProvinceCode: payload?.address_from?.state,
              PostalCode: payload?.address_from?.zip,
              CountryCode: payload?.address_from?.country,
            },
          },
          PaymentInformation: {
            ShipmentCharge: {
              Type: "01",
              BillShipper: { AccountNumber: "W6168A" },
            },
          },
          Service: {
            Code: item?.Service?.Code,
            Description:
              item?.TimeInTransit?.ServiceSummary?.Service?.Description,
          },
          Package: {
            Description: " ",
            Packaging: {
              Code: "02",
              Description: "Nails",
            },
            Dimensions: {
              UnitOfMeasurement: {
                Code: distanceValue.toUpperCase(),
                Description: "Inches",
              },
              Length: rateData?.length,
              Width: rateData?.width,
              Height: rateData?.height,
            },
            PackageWeight: {
              UnitOfMeasurement: {
                Code: massValue.toUpperCase() + "S",
                Description: "Pounds",
              },
              Weight: rateData?.weight,
            },
          },
        },
        LabelSpecification: {
          LabelImageFormat: {
            Code: "GIF",
            Description: "GIF",
          },
          HTTPUserAgent: "Mozilla/4.5",
        },
      },
    };

    setA5Payload(shipmentReqest);
    setSelectedRate(shipmentReqest);

    setIsBuyButtonDisabled(activeCard === item?.Service?.Code ? true : false);
  };
  const handleRate = (item) => {
    setActiveCard(item?.service === activeCard ? null : item?.service);
    setIsCardSelected(!isCardSelected);
    let updatedPayload = payload;
    updatedPayload.label = {
      serviceName: item?.serviceType,
      amount: item?.discountShippingCost,
      providerName: item?.carrierCode,
    };
    setPayload(updatedPayload);
    setSelectedRate(updatedPayload);
    setIsBuyButtonDisabled(activeCard === item?.service ? true : false);
  };
  const handleWalmartRate = (item) => {
    setActiveCard(item?.name === activeCard ? null : item?.name);
    setIsCardSelected(!isCardSelected);
    const itemsData = selectItemValue.map((item) => ({
      itemId: item?.lineNumber,
      shipQuantity: itemQuantities,
    }));
    const updatedPayload = {
      orderId: id,
      itemsInfo: itemsData,
      walmartPayload: {
        packageType: "CUSTOM_PACKAGE",
        boxDimensions: boxDimensions,
        boxItems: boxItem,
        fromAddress: {
          contactName: payload?.address_from?.name,
          addressLine1: payload?.address_from?.street1,
          companyName: payload?.company || "dummy",
          city: payload?.address_from?.city,
          state: payload?.address_from?.state,
          postalCode: payload?.address_from?.zip,
          country: payload?.address_from?.country,
          phone: payload?.address_from?.phone,
          email: payload?.address_from?.email,
        },
        toAddress: {
          contactName: payload?.address_to?.name,
          addressLine1: payload?.address_to?.street1,
          companyName: payload?.address_to?.company || "dummy",
          city: payload?.address_to?.city,
          state: payload?.address_to?.state,
          postalCode: payload?.address_to?.zip,
          country: payload?.address_to?.country,
          phone: payload?.address_to?.phone,
          email: payload?.address_to?.email || "dummy@gmail.com",
        },
        purchaseOrderId: id,
        carrierName: item?.carrierName,
        carrierServiceType: item?.serviceTypeGroupName,
      },
    };

    setPayload(updatedPayload);
    setSelectedRate(updatedPayload);
    setIsBuyButtonDisabled(activeCard === item?.service ? true : false);
  };
  const handleSelectRate = async (item) => {
    const rateId = item?.rateId;

    const supportedDocumentSpecifications =
      item?.supportedDocumentSpecifications;
    const carrierName = item?.carrierName;
    const serviceName = item?.serviceName;

    setActiveCard(rateId === activeCard ? null : rateId);
    setIsCardSelected(!isCardSelected);
    let requestedValueAddedServices = [];
    item?.availableValueAddedServiceGroups.map((requestValue) =>
      requestValue?.valueAddedServices.map((requestItem) => {
        requestedValueAddedServices.push({
          id: requestItem.id,
        });
      })
    );
    function findMatchingDocumentSpecification() {
      for (let i = 0; i < supportedDocumentSpecifications?.length; i++) {
        const documentSpec = supportedDocumentSpecifications[i];
        if (
          documentSpec.format === "PDF" &&
          documentSpec.size?.length === 6 &&
          documentSpec.size.unit === "INCH" &&
          documentSpec.size.width === 4
        ) {
          for (let j = 0; j < documentSpec.printOptions?.length; j++) {
            const printOption = documentSpec.printOptions[j];
            for (
              let k = 0;
              k < printOption.supportedDocumentDetails?.length;
              k++
            ) {
              const docDetail = printOption.supportedDocumentDetails[k];
              if (docDetail.name === "LABEL") {
                return documentSpec;
              }
            }
          }
        }
      }
      return null;
    }

    const matchingDocumentSpec = findMatchingDocumentSpecification();
    if (!matchingDocumentSpec) {
      setIsBuyButtonDisabled(true);
      setActiveCard(null);
      return Toast.error("This rate is not available right now");
    }

    const updatedPayload = {
      rateId,
      matchingDocumentSpec,
      requestedValueAddedServices,
      requestToken: amzRates?.requestToken,
      carrierName,
      serviceName,
    };
    setSelectedRate(updatedPayload);
    setIsBuyButtonDisabled(activeCard === rateId ? true : false);
  };
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handlRadioSelect = (option) => {
    setSelectedRadio(option.value);
    setSelectedLabel(option.label);
    if (option.openModal) {
      handleModalOpen();
      setSelectedLabel(option.label);
    }

    if (option?.label === "DeleveryDay") {
    }
  };
  const handleCreateLabel = async () => {
    if (selectedLabel === "A5 IT") {
      postPurchaseUpsShipmentCallApi(id, orderType, a5Payload);
    } else if (selectedLabel === "Walmart") {
      postByWalmartCreateOrderAPICALL(payload, id, orderType);
    } else {
      const matchingDoc = selectedRate?.matchingDocumentSpec;
      const requestedValueAddedServices =
        selectedRate?.requestedValueAddedServices;
      const printOptions =
        selectedRate?.matchingDocumentSpec?.printOptions?.[0];
      const reqDocType = printOptions.supportedDocumentDetails.map((reqDoc) => {
        return reqDoc.name;
      });
      const itemsData = selectItemValue.map((item) => ({
        itemId: item.OrderItemId,
        shipQuantity: itemQuantities,
      }));
      const itemData = selectItemValue.map((selectedItem) => {
        const quantity = parseInt(selectedItem.QuantityOrdered);
        const unitPrice = parseInt(selectedItem.ItemPrice.Amount);
        const taxPrice = parseInt(selectedItem.ItemTax.Amount);
        const subTotal = unitPrice;
        const total = subTotal + taxPrice;
        return {
          quantity: quantity,
          title: selectedItem.Title,
          sku: selectedItem.SellerSKU,
          asin: selectedItem.ASIN,
          ItemCondition: selectedItem.ConditionSubtypeId,
          itemId: selectedItem.OrderItemId,
          unitPrice: selectedItem?.ItemPrice?.Amount,
          taxPrice: selectedItem?.ItemTax?.Amount,
          subTotal: subTotal,
          refund: "-",
          initialTotal: total,
          itemTotal: total,
        };
      });
      const grandTotal = itemData.reduce(
        (total, item) => total + item.itemTotal,
        0
      );
      const date = new Date(amzAllOrderDetails.PurchaseDate);
      const formattedDate = `${date.getDate()},${
        monthNames[date.getMonth()]
      } ${date.getFullYear()} ${date.toTimeString().slice(0, 8)}${date
        .toISOString()
        .slice(-6)}`;
      let shipPayload = {
        address_to: payload.address_to,
        itemData: itemData,
        grandTotal: grandTotal,
        orderDate: formattedDate,
        orderId: id,
        itemsInfo: itemsData,
        carrierName: selectedRate.carrierName,
        serviceName: selectedRate.serviceName,
        purchaseLabelData: {
          requestToken: amzRates?.requestToken,
          rateId: selectedRate?.rateId,
          requestedDocumentSpecification: {
            format: matchingDoc.format,
            size: {
              width: matchingDoc.size.width,
              length: matchingDoc.size?.length,
              unit: matchingDoc.size.unit,
            },
            pageLayout: printOptions?.supportedPageLayouts?.[0],
            needFileJoining: printOptions.supportedFileJoiningOptions?.[0],
            requestedDocumentTypes: reqDocType,
          },
        },
      };
      if (requestedValueAddedServices?.[0]) {
        shipPayload.purchaseLabelData.requestedValueAddedServices = [
          requestedValueAddedServices?.[0],
        ];
      }

      postPurchaseShipmentCallApi(shipPayload, id, orderType);
    }
  };
  useEffect(() => {
    setPayload({
      parcels: [
        {
          length: values?.length,
          width: values?.width,
          height: values?.height,
          distance_unit: distanceValue,
          weight: values?.weight,
          mass_unit: massValue,
          quantity: itemQuantities?.quantity,
        },
      ],
      address_from: {
        name: senderAddress?.name,
        company: "",
        street1: senderAddress?.street1,
        city: senderAddress?.city,
        state: senderAddress?.state,
        zip: senderAddress?.zip,
        country: senderAddress?.country,
        phone: senderAddress?.phone,
        email: senderAddress?.email,
      },
      address_to: {
        name:
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.fullName ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.name ||
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Name,
        company:
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.fullName ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.name ||
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Name,
        street1:
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.AddressLine1 ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.address1 ||
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.addressLine1,
        city:
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.city ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.city ||
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.City,
        state:
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.StateOrRegion ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.state ||
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.stateOrProvince,
        zip:
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.postalCode ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.postalCode ||
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.PostalCode,
        country: "US",
        phone:
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Phone ||
          walmartOrderDetails?.shippingInfo?.phone ||
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.primaryPhone?.phoneNumber,
      },
      object_purpose: "PURCHASE",
      async: false,
      shipment_date: dateValue?.toISOString(),
      userId: user?.id,
      orderId: id,
      orderType: orderType,
    });
  }, [
    senderAddress,
    user,
    amzAllOrderDetails,
    ebayOrderDetails,
    walmartOrderDetails,
    id,
    dateValue,
  ]);
  const conditionalRowStyles = [
    {
      when: (row) => row.status === "Shipped",
      style: {
        pointerEvents: "none",
        opacity: 0.5,
      },
    },
  ];
  const handlePdf = (label, pdfType) => {
    setDisplayPopup(true);
    setPdfType(pdfType);
    setPdfValue(label);
  };

  const orderItemMapping = {
    amazon: amzOrderItemColumn,
    walmart: walmartOrderItemColumn,
    ebay: ebayOrderItemColumn,
  };

  const handleQuantityChange = (event) => {
    const { value } = event.target;
    setItemQuantities(value);
  };
  const handleConfirmModelOpen = () => {
    setOpenConfirmModel(true);
  };
  const handleCreatePackage = (shipmentId) => {
    postCreatePackageSlipApiCall(id, shipmentId, orderType);
  };
  const handleConfirmModelClose = () => {
    setOpenConfirmModel(false);
  };
  const handlShipmentCancel = async (item) => {
    if (item?.type === "aFive") {
      const data = {
        orderId: id,
        shipmentId: item?.shipmentId,
        carrierName: item?.carrierName,
      };
      const resData = await aFiveItemShipmentCancelCallApi(data);
      Toast.success(resData.message);
    } else {
      const data = {
        orderId: id,
        shipmentId: item?.shipmentId,
      };
      const resData = await itemShipmentCancelCallApi(data, id);
      Toast.success(resData.message);
    }
    getOrderDetailsApiCall(id, orderType);
    handleConfirmModelClose();
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemErrors({
      customerNumber: false,
      quantity: "",
      sku: "",
    });
    setShipWarehouse(null);
    setItemSku("");
    setLineErrors({
      ingramPartNumber: "",
      quantity: "",
      customerOrderNumber: "",
    });
    if (amzAllOrderDetails) {
      const dataEUser = amzAllOrderDetails?.fullAddress?.ShippingAddress;
      const defaultSLicense = {
        sLicenseAddress1: senderAddressValue?.name,
        sLicenseAddressLine1: senderAddressValue?.address1,
        sLicenseCity: senderAddressValue?.city,
        sLicenseState: senderAddressValue?.state,
        sLicenseZipCode: senderAddressValue?.zip,
        sLicenseCountry: senderAddressValue?.country,
        sLicenseContactName: senderAddressValue?.company,
        sLicensePhoneNumber: senderAddressValue?.phone,
        sLicenseEmailAddress: senderAddressValue?.email,
      };
      const defaultEUser = {
        eUserAddressName1: dataEUser?.Name,
        eUserAddressLine1: dataEUser?.AddressLine1,
        eUserCity: dataEUser?.City,
        eUserState: dataEUser?.StateOrRegion,
        eUserZipCode: dataEUser?.PostalCode,
        eUserCountry: dataEUser?.CountryCode,
        eUserContactName: dataEUser?.company,
        eUserPhoneNumber: dataEUser?.Phone,
        eUserEmailAddress: dataEUser?.BuyerInfo?.BuyerEmail,
      };
      setSLicense(defaultSLicense);
      setEUser(defaultEUser);
    } else if (walmartOrderDetails) {
      const dataEUser = walmartOrderDetails;
      const defaultSLicense = {
        sLicenseAddress1: senderAddressValue?.name,
        sLicenseAddressLine1: senderAddressValue?.address1,
        sLicenseCity: senderAddressValue?.city,
        sLicenseState: senderAddressValue?.state,
        sLicenseZipCode: senderAddressValue?.zip,
        sLicenseCountry: senderAddressValue?.country,
        sLicenseContactName: senderAddressValue?.company,
        sLicensePhoneNumber: senderAddressValue?.phone,
        sLicenseEmailAddress: senderAddressValue?.email,
      };
      const defaultEUser = {
        eUserAddressName1: dataEUser?.shippingInfo?.postalAddress?.name,
        eUserAddressLine1: dataEUser?.shippingInfo?.postalAddress?.address1,
        eUserCity: dataEUser?.shippingInfo?.postalAddress?.city,
        eUserState: dataEUser?.shippingInfo?.postalAddress?.state,
        eUserZipCode: dataEUser?.shippingInfo?.postalAddress?.postalCode,
        eUserCountry: dataEUser?.shippingInfo?.postalAddress?.country,
        eUserContactName: dataEUser?.shippingInfo?.postalAddress?.company,
        eUserPhoneNumber: dataEUser?.shippingInfo?.phone,
        eUserEmailAddress: dataEUser?.customerEmailId,
      };
      setSLicense(defaultSLicense);
      setEUser(defaultEUser);
    }
    setSLicenseChecked(false);
    setEUserChecked(false);
    setEUserType(endUserTypeOptions[0]);
    setFormData({
      customerLineNumbers: selectItemValue?.map((_, index) =>
        (index + 1).toString()
      ),
      ingramPartNumbers: Array(selectItemValue.length).fill(""), // Initialize with empty strings
      quantities: selectItemValue?.map((item) =>
        item?.QuantityOrdered.toString()
      ),
    });
    setIngramResponse(null);
    ingramReset();
  };
  const handleTableSelectChange = (selectedRow) => {
    setselectedItemValue(selectedRow.selectedRows);
    if (selectedRow?.selectedCount > 0) {
      setItemSelected(false);
    } else {
      setAmzRates(null);
      setItemSelected(true);
    }
  };
  const renderRadioOptions = (options) => {
    return options.map((option) => (
      <div
        key={option.key}
        onClick={() => {
          handlRadioSelect(option);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          cursor: "pointer",

          padding: 10,
          ...(selectedRadio === option.value && {
            backgroundColor: "#f9fafb",
            borderRadius: "4px",
          }),
        }}
      >
        <div>
          {selectedRadio === option.value ? (
            <RadioCheckButton>
              <RadioButtonDot />
            </RadioCheckButton>
          ) : (
            <RadioUnCheckButton />
          )}
        </div>
        <div>{option.label}</div>
      </div>
    ));
  };
  function getCurrentUTCTime() {
    const nowUTC = new Date();
    const offset = -8 * 60;
    const nowUSPacific = new Date(nowUTC.getTime() + offset * 60000);
    const formattedTime = nowUSPacific.toISOString();
    return formattedTime;
  }
  const utcTime = getCurrentUTCTime();
  const renderOptions = () => {
    const commonOptions = [
      { key: 1, value: 1, label: "A5 IT" },
      {
        key: 2,
        value: 2,
        label:
          (orderType === "amazon" && "Amazon") ||
          (orderType === "ebay" && "Ebay") ||
          (orderType === "walmart" && "Walmart"),
      },
      { key: 5, value: 5, label: "DeleveryDay" },
    ];

    const orderTypeOptions = [
      ...commonOptions,
      { key: 3, value: 3, label: "TD Synnex", openModal: true },
      { key: 4, value: 4, label: "Ingram Micro", openModal: true },
    ];

    return selectItemValue?.length > 0
      ? renderRadioOptions(orderTypeOptions)
      : renderRadioOptions(commonOptions);
  };

  const rateSubmitData = (data) => {
    setRateData(data);
    if (itemSelected) {
      Toast.error("Please select at least one item");
      return;
    }
    if (selectedLabel !== "TD Synnex" && selectedLabel !== "Ingram Micro") {
      if (itemQuantities === 0) {
        Toast.error("Item quantity should be greater than 0");
        return;
      }
    }

    if (selectedLabel === "Amazon") {
      const amazonPayload = {
        shipTo: {
          name: payload?.address_to?.name,
          addressLine1: payload?.address_to?.street1,
          addressLine2: "",
          postalCode: payload?.address_to?.zip,
          stateOrRegion: payload?.address_to?.state,
          city: payload?.address_to?.city,
          countryCode: payload?.address_to?.country,
          email: "",
          phoneNumber: (payload?.address_to?.phone || "").split(" ext.")?.[0],
        },
        shipFrom: {
          name: payload?.address_from?.name,
          addressLine1: payload?.address_from?.street1,
          stateOrRegion: payload?.address_from?.state,
          postalCode: payload?.address_from?.zip,
          city: payload?.address_from?.city,
          countryCode: payload?.address_from?.country,
          email: payload?.address_from?.email,
          phoneNumber: payload?.address_from?.phone,
        },
        packages: [
          {
            dimensions: {
              length: Number(data?.length),
              width: Number(data?.width),
              height: Number(data?.height),
              unit: "INCH",
            },
            weight: {
              unit: "POUND",
              value: Number(data.weight) * Number(itemQuantities),
            },
            items: amzAllOrderDetails?.orderItems?.map((orderItem) => ({
              itemValue: {
                unit: orderItem?.ItemPrice?.CurrencyCode || "",
                value: Number(orderItem?.ItemPrice?.Amount) || 0,
              },
              quantity: Number(itemQuantities),
              itemIdentifier: orderItem?.OrderItemId,
              weight: {
                unit: "POUND",
                value: Number(data.weight),
              },
            })),
            insuredValue: {
              unit: "USD",
              value: (amzAllOrderDetails?.orderItems || []).reduce(
                (accumulator, orderItem) =>
                  accumulator + (Number(orderItem?.ItemPrice?.Amount) || 0),
                0
              ),
            },
            packageClientReferenceId:
              amzAllOrderDetails?.orderItems?.[0]?.OrderItemId || "",
          },
        ],
        channelDetails: {
          channelType: "AMAZON",
          amazonOrderDetails: {
            orderId: payload.orderId,
          },
        },
      };
      getAmzRatesApiCall(amazonPayload);
    } else if (selectedLabel === "DeleveryDay") {
      const deleveryDayPayload = {
        parcels: [
          {
            length: Number(data?.length),
            width: Number(data?.width),
            height: Number(data?.height),
            distance_unit: payload?.parcels?.[0]?.distance_unit,
            weight: Number(data.weight) * Number(itemQuantities),
            mass_unit: payload?.parcels?.[0]?.mass_unit,
          },
        ],
        address_from: {
          name: payload?.address_from?.name,
          city: payload?.address_from?.city,
          company: payload?.address_from?.company,
          country: payload?.address_from?.country,
          phone: payload?.address_from?.phone,
          state: payload?.address_from?.state,
          street1: payload?.address_from?.street1,
          zip: payload?.address_from?.zip,
        },
        address_to: {
          name: payload?.address_to?.name,
          city: payload?.address_to?.city,
          company: payload?.address_to?.company,
          country: payload?.address_to?.country,
          phone: payload?.address_to?.phone,
          state: payload?.address_to?.state,
          street1: payload?.address_to?.street1,
          zip: payload?.address_to?.zip,
        },
        object_purpose: "PURCHASE",
        async: false,
        userId: "655bbc67c75c3dbc28068ce4",
        orderId: id,
        orderType: orderType,
      };
      getDeleverDayRatesApi(deleveryDayPayload);
    } else if (selectedLabel === "Walmart") {
      const estimatedDeliveryTimestamp =
        walmartOrderDetails?.shippingInfo?.estimatedDeliveryDate;
      const estimatedShipTimestamp =
        walmartOrderDetails?.shippingInfo?.estimatedShipDate;

      const estimatedDeliveryDate = new Date(estimatedDeliveryTimestamp);
      const estimatedShipDate = new Date(estimatedShipTimestamp);

      const estimatedDeliveryISO = estimatedDeliveryDate.toISOString();
      const estimatedShipISO = estimatedShipDate.toISOString();
      const boxItems = selectItemValue.map((item, index) => {
        const sku =
          walmartOrderDetails?.orderLines?.orderLine?.[index]?.item?.sku;
        return {
          lineNumber: index + 1,
          sku: sku,
          quantity: Number(itemQuantities),
        };
      });
      const boxDimensions = {
        boxWeightUnit: payload?.parcels?.[0]?.mass_unit?.toUpperCase(),
        boxWeight: Number(data.weight) * Number(itemQuantities),
        boxLength: Number(data?.length),
        boxWidth: Number(data?.width),
        boxHeight: Number(data?.height),
        boxDimensionUnit: payload?.parcels?.[0]?.distance_unit?.toUpperCase(),
      };
      const walmartPayload = {
        packageType: "CUSTOM_PACKAGE",
        purchaseOrderId: id,
        boxItems: boxItems,
        shipByDate: estimatedShipISO,
        deliverByDate: estimatedDeliveryISO,
        boxDimensions: boxDimensions,
        fromAddress: {
          postalCode: payload?.address_from?.zip,
          countryCode: payload?.address_from?.country,
          addressLines: [payload?.address_from?.street1],
          city: payload?.address_from?.city,
          state: payload?.address_from?.state,
          //  company: payload?.address_from?.company,
          //  phone: payload?.address_from?.phone,
          //  country: payload?.address_from?.country,
        },
        toAddress: {
          postalCode: payload?.address_to?.zip,
          countryCode: payload?.address_to?.country,
          addressLines: [payload?.address_to?.street1],
          city: payload?.address_to?.city,
          state: payload?.address_to?.state,
          // name: payload?.address_to?.name,
          //  company: payload?.address_to?.company,
          //  phone: payload?.address_to?.phone,
          //  country: payload?.address_to?.country,
          // email: payload?.address_to?.email
        },
        includeServicesNotMeetingDeliveryPromise: true,
      };

      setBoxDimensions(boxDimensions);
      setBoxItem(boxItems);
      getWalmartRatesApi(walmartPayload);
    } else if (selectedLabel === "A5 IT") {
      let mass_unit = massValue.toUpperCase() + "S";
      const aFivePayload = {
        RateRequest: {
          Request: {
            RequestOption: "shop",
          },
          CustomerClassification: {
            Code: "00",
          },
          Shipment: {
            OriginRecordTransactionTimestamp: utcTime,
            Shipper: {
              Name: "ShipperName",
              ShipperNumber: "W6168A",
              Address: {
                AddressLine: "1320 South Virginia Road",
                City: "Crystal Lake",
                StateProvinceCode: "IL",
                PostalCode: "60014",
                CountryCode: "US",
              },
            },
            ShipTo: {
              Name: payload?.address_to?.name,
              Address: {
                AddressLine: payload?.address_to?.street1,
                City: payload?.address_to?.city,
                StateProvinceCode: payload?.address_to?.state,
                PostalCode: payload?.address_to?.zip,
                CountryCode: payload?.address_to?.country,
              },
            },
            ShipFrom: {
              Name: payload?.address_from?.name,
              Address: {
                AddressLine: payload?.address_from?.street1,
                City: payload?.address_from?.city,
                StateProvinceCode: payload?.address_from?.state,
                PostalCode: payload?.address_from?.zip,
                CountryCode: payload?.address_from?.country,
              },
            },
            Package: [
              {
                PackagingType: {
                  Code: "00",
                },
                Dimensions: {
                  UnitOfMeasurement: {
                    Code: distanceValue.toUpperCase(),
                    Description: "Inches",
                  },
                  Length: data?.length,
                  Width: data?.width,
                  Height: data.height,
                },
                PackageWeight: {
                  UnitOfMeasurement: {
                    Code: mass_unit,
                    Description: "Pounds",
                  },
                  Weight: data.weight,
                },
              },
            ],
            DeliveryTimeInformation: {
              PackageBillType: "07",
              Pickup: {
                Date: "2024-02-26",
              },
            },
          },
        },
      };
      getAFiveRatesApi(aFivePayload);
    }
  };
  function generateRandomId() {
    const min = 10000000;
    const max = 99999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const handleCreatePdf = () => {
    if (itemSelected) {
      return Toast.error("Please select at least one item");
    }
    if (orderType === "amazon") {
      const itemData = selectItemValue.map((selectedItem) => {
        const quantity = parseInt(selectedItem.QuantityOrdered);
        const unitPrice = parseInt(selectedItem.ItemPrice.Amount);
        const taxPrice = parseInt(selectedItem.ItemTax.Amount);
        const subTotal = unitPrice;
        const total = subTotal + taxPrice;
        return {
          quantity: quantity,
          title: selectedItem.Title,
          sku: selectedItem.SellerSKU,
          asin: selectedItem.ASIN,
          ItemCondition: selectedItem.ConditionSubtypeId,
          itemId: selectedItem.OrderItemId,
          unitPrice: selectedItem?.ItemPrice?.Amount,
          taxPrice: selectedItem?.ItemTax?.Amount,
          subTotal: subTotal,
          refund: "-",
          initialTotal: total,
          itemTotal: total,
        };
      });

      const grandTotal = itemData.reduce(
        (total, item) => total + item.itemTotal,
        0
      );
      const date = new Date(amzAllOrderDetails.PurchaseDate);
      const formattedDate = `${date.getDate()},${
        monthNames[date.getMonth()]
      } ${date.getFullYear()} ${date.toTimeString().slice(0, 8)}${date
        .toISOString()
        .slice(-6)}`;
      const payloadCreatePdf = {
        address_from: payload.address_from,
        address_to: payload.address_to,
        itemData,
        orderType,
        grandTotal: grandTotal,
        orderDate: formattedDate,
      };
      postCreatePdfApiCall(payloadCreatePdf, id, orderType);
    } else if (orderType === "ebay") {
      const itemData = selectItemValue.map((selectedItem) => {
        const quantity = parseInt(selectedItem.quantity);
        const unitPrice = parseInt(selectedItem.lineItemCost.value);
        const taxPrice = parseInt(0.0);
        const subTotal = quantity * unitPrice;
        const total = subTotal + taxPrice;

        return {
          quantity: quantity,
          title: selectedItem.title,
          sku: selectedItem?.SellerSKU ? selectedItem?.SellerSKU : "No Sku",
          asin: selectedItem?.ASIN ? selectedItem?.ASIN : "No Asin",
          ItemCondition: "new",
          itemId: selectedItem.legacyItemId,
          unitPrice: unitPrice,
          taxPrice: taxPrice,
          subTotal: subTotal,
          refund: "-",
          initialTotal: total,
          itemTotal: total,
        };
      });
      const payload = {
        itemData,
        orderType,
        grandTotal: 200,
      };
      postCreatePdfApiCall(payload, id, orderType);
    } else if (orderType === "walmart") {
      const itemData = selectItemValue.map((selectedItem) => {
        const taxValue = selectedItem.charges.charge?.[0].tax.taxAmount.amount;
        const quantity = parseInt(selectedItem.orderLineQuantity.amount);
        const unitPrice = parseInt(
          selectedItem.charges.charge?.[0].chargeAmount.amount
        );
        const taxPrice = parseInt(taxValue ? taxValue : 0.0);
        const subTotal = quantity * unitPrice;
        const total = subTotal + taxPrice;
        return {
          quantity: quantity,
          title: selectedItem.item.productName,
          sku: selectedItem?.SellerSKU ? selectedItem?.SellerSKU : "No Sku",
          asin: selectedItem?.ASIN ? selectedItem?.ASIN : "No Asin",
          ItemCondition: "new",
          itemId: generateRandomId(),
          unitPrice: unitPrice,
          taxPrice: taxPrice,
          subTotal: subTotal,
          refund: "-",
          initialTotal: total,
          itemTotal: total,
        };
      });
      const payload = {
        itemData,
        orderType,
        grandTotal: 200,
      };
      postCreatePdfApiCall(payload, id, orderType);
    }
  };
  return (
    <MenuSidebar>
      {createLabelLoading ||
      orderDetailsLoading ||
      updatingTrackingLoading ||
      createPdfLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {(amzAllOrderDetails !== null ||
            ebayOrderDetails !== null ||
            walmartOrderDetails !== null) && (
            <>
              <OrderDetailNav
                orderId={id}
                isBuyButtonDisabled={isBuyButtonDisabled}
                amzOrderStatus={amzOrderStatus || ebayStatus || walmartStatus}
                userRole={user?.role}
                currentStatusValue={currentStatusValue}
                handleCreateLabel={handleCreateLabel}
                orderType={orderType}
                userId={user?.id}
                trackingUpdated={trackingUpdated}
                resetOrderDetails={resetOrderDetails}
                labels={labels}
                handlePdf={handlePdf}
                handleCreatePdf={handleCreatePdf}
                selectItemValue={selectItemValue}
                btnDisabled={!isCardSelected}
              />
              <Grid container>
                <Grid item md={9}>
                  <form onSubmit={handleSubmit(rateSubmitData)}>
                    <Card sx={{ ml: 2, mr: 2, mt: 2, p: 2 }} elevation={4}>
                      <Grid item md={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Fulfillment Type
                        </div>
                        <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 20,
                            border: "none",
                            backgroundColor: "white",
                          }}
                          type="submit"
                        >
                          {renderOptions()}
                        </button>

                        {orderType === "amazon" && (
                          <>
                            {selectedRadio === 3 && (
                              <SynnexModel
                                isModalOpen={isModalOpen}
                                selectedLabel={selectedLabel}
                                selectItemValue={selectItemValue}
                                amzAllOrderDetails={amzAllOrderDetails}
                                handleCloseModal={handleCloseModal}
                                handleSynnexSubmit={handleSynnexSubmit}
                                postBySynnexCreateOrderCall={
                                  postBySynnexCreateOrderCall
                                }
                                postByShipWarehouse={postByShipWarehouse}
                                shipWarehouse={shipWarehouse}
                                synnnexRegister={synnexRegister}
                                synnnexSetValue={synnexSetValue}
                                synnexErrors={synnexErrors}
                                orderType={orderType}
                                itemQuantities={itemQuantities}
                                itemErrors={itemErrors}
                                setItemErrors={setItemErrors}
                                itemSku={itemSku}
                                setItemSku={setItemSku}
                                sLicense={sLicense}
                                setSLicense={setSLicense}
                                eUser={eUser}
                                setEUser={setEUser}
                                senderAddressValue={senderAddressValue}
                                sLicenseChecked={sLicenseChecked}
                                setSLicenseChecked={setSLicenseChecked}
                                setSpecialOrder={setSpecialOrder}
                                specialOrder={specialOrder}
                                eUserChecked={eUserChecked}
                                setEUserChecked={setEUserChecked}
                                setEUserType={setEUserType}
                                eUserType={eUserType}
                                endUserTypeOptions={endUserTypeOptions}
                                id={id}
                              />
                            )}

                            {selectedRadio === 4 && (
                              <IngramModel
                                isModalOpen={isModalOpen}
                                selectedLabel={selectedLabel}
                                selectItemValue={selectItemValue}
                                amzAllOrderDetails={amzAllOrderDetails}
                                handleCloseModal={handleCloseModal}
                                handleIngramSubmit={handleIngramSubmit}
                                ingramReset={ingramReset}
                                ingramRegister={ingramRegister}
                                ingramSetValue={ingramSetValue}
                                orderType={orderType}
                                itemQuantities={itemQuantities}
                                errors={ingramErrors}
                                setErrors={setLineErrors}
                                itemErrors={itemErrors}
                                setItemErrors={setItemErrors}
                                formData={formData}
                                setFormData={setFormData}
                              />
                            )}
                          </>
                        )}
                        {orderType === "ebay" && (
                          <>
                            {selectedRadio === 3 && (
                              <SynnexModel
                                isModalOpen={isModalOpen}
                                selectedLabel={selectedLabel}
                                selectItemValue={selectItemValue}
                                ebayOrderDetails={ebayOrderDetails}
                                handleCloseModal={handleCloseModal}
                                handleSynnexSubmit={handleSynnexSubmit}
                                synnnexRegister={synnexRegister}
                                synnnexSetValue={synnexSetValue}
                                synnexErrors={synnexErrors}
                                itemQuantities={itemQuantities}
                                id={id}
                              />
                            )}

                            {selectedRadio === 4 && (
                              <IngramModel
                                isModalOpen={isModalOpen}
                                selectedLabel={selectedLabel}
                                selectItemValue={selectItemValue}
                                ebayOrderDetails={ebayOrderDetails}
                                handleCloseModal={handleCloseModal}
                                handleIngramSubmit={handleIngramSubmit}
                                ingramReset={ingramReset}
                                ingramRegister={ingramRegister}
                                ingramSetValue={ingramSetValue}
                                orderType={orderType}
                                itemQuantities={itemQuantities}
                                formData={formData}
                                setFormData={setFormData}
                              />
                            )}
                          </>
                        )}
                        {orderType === "walmart" && (
                          <>
                            {selectedRadio === 3 && (
                              <SynnexModel
                                isModalOpen={isModalOpen}
                                selectedLabel={selectedLabel}
                                selectItemValue={selectItemValue}
                                walmartOrderDetails={walmartOrderDetails}
                                handleCloseModal={handleCloseModal}
                                handleSynnexSubmit={handleSynnexSubmit}
                                synnnexRegister={synnexRegister}
                                synnnexSetValue={synnexSetValue}
                                synnexErrors={synnexErrors}
                                itemQuantities={itemQuantities}
                                eUser={eUser}
                                setEUser={setEUser}
                                sLicense={sLicense}
                                setSLicense={setSLicense}
                                senderAddressValue={senderAddressValue}
                                eUserChecked={eUserChecked}
                                setEUserChecked={setEUserChecked}
                                sLicenseChecked={sLicenseChecked}
                                setSLicenseChecked={setSLicenseChecked}
                                setSpecialOrder={setSpecialOrder}
                                specialOrder={specialOrder}
                                setEUserType={setEUserType}
                                eUserType={eUserType}
                                endUserTypeOptions={endUserTypeOptions}
                                id={id}
                              />
                            )}

                            {selectedRadio === 4 && (
                              <IngramModel
                                isModalOpen={isModalOpen}
                                selectedLabel={selectedLabel}
                                selectItemValue={selectItemValue}
                                walmartOrderDetails={walmartOrderDetails}
                                handleCloseModal={handleCloseModal}
                                handleIngramSubmit={handleIngramSubmit}
                                ingramReset={ingramReset}
                                ingramRegister={ingramRegister}
                                ingramSetValue={ingramSetValue}
                                orderType={orderType}
                                itemQuantities={itemQuantities}
                                errors={LineErrors}
                                setErrors={setLineErrors}
                                formData={formData}
                                setFormData={setFormData}
                              />
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid sx={{ mt: 3 }} item md={12}>
                        {(amzAllOrderDetails ||
                          ebayOrderDetails ||
                          walmartOrderDetails) && (
                          <RecipientCard
                            EbayShipTo={
                              ebayOrderDetails
                                ?.fulfillmentStartInstructions?.[0]
                                ?.shippingStep?.shipTo
                            }
                            WalmartShipTo={walmartOrderDetails}
                            AmazonShipTo={
                              amzAllOrderDetails?.fullAddress?.ShippingAddress
                            }
                            orderStatus={amzOrderStatus || ebayStatus}
                            userRole={user?.role}
                            disablePackages={disablePackages}
                            senderAddress={senderAddress}
                            setSenderAddress={setSenderAddress}
                          />
                        )}
                        <div
                          style={{
                            fontWeight: "bold",
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          Items
                        </div>
                        <div>
                          <CustomTable
                            data={
                              amzAllOrderDetails?.orderItems ||
                              ebayOrderDetails?.lineItems ||
                              walmartOrderDetails?.orderLines?.orderLine
                            }
                            columns={orderItemMapping[orderType]}
                            handleTableSelectChange={handleTableSelectChange}
                            pagination={false}
                            selectableRows={true}
                            conditionalRowStyles={conditionalRowStyles}
                          />
                        </div>
                      </Grid>{" "}
                      <Grid item md={12}>
                        <PackageCardContainer>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: 10,
                                alignItems: "center",
                                fontWeight: "bold",
                              }}
                            >
                              Package
                            </div>{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",

                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    color: "#666",
                                    marginBottom: "5px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Length
                                </div>
                                <TextField
                                  {...register(`length`, {
                                    required:
                                      selectedLabel === "Ingram Micro" ||
                                      selectedLabel === "TD Synnex"
                                        ? false
                                        : `Please enter length`,
                                    pattern: {
                                      value: /^[1-9][0-9]*$/i,
                                      message: "Please enter valid length",
                                    },
                                  })}
                                  size="small"
                                  type="number"
                                  style={{ width: 120 }}
                                  inputProps={{
                                    min: 0,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <div
                                        style={{
                                          padding: "3px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {distanceValue}
                                      </div>
                                    ),
                                  }}
                                  error={
                                    errors?.length?.type === "required" ||
                                    errors?.length?.type === "pattern"
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  color: "#5c7786",
                                  fontSize: "17px",
                                  marginTop: "25px",
                                }}
                              >
                                X
                              </div>
                              <div>
                                <div
                                  style={{
                                    color: "#666",
                                    marginBottom: "5px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Width
                                </div>
                                <TextField
                                  {...register(`width`, {
                                    required:
                                      selectedLabel === "Ingram Micro" ||
                                      selectedLabel === "TD Synnex"
                                        ? false
                                        : `Please enter length`,
                                    pattern: {
                                      value: /^[1-9][0-9]*$/i,
                                      message: "Please enter valid width",
                                    },
                                  })}
                                  size="small"
                                  type="number"
                                  style={{ width: 120 }}
                                  inputProps={{
                                    min: 0,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <div
                                        style={{
                                          padding: "3px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {distanceValue}
                                      </div>
                                    ),
                                  }}
                                  error={errors?.width}
                                />
                              </div>

                              <div
                                style={{
                                  color: "#5c7786",
                                  fontSize: "17px",
                                  marginTop: "25px",
                                }}
                              >
                                X
                              </div>
                              <div>
                                <div
                                  style={{
                                    color: "#666",
                                    marginBottom: "5px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Height
                                </div>
                                <TextField
                                  {...register(`height`, {
                                    required:
                                      selectedLabel === "Ingram Micro" ||
                                      selectedLabel === "TD Synnex"
                                        ? false
                                        : `Please enter length`,
                                    pattern: {
                                      value: /^[1-9][0-9]*$/i,
                                      message: "Please enter valid height",
                                    },
                                  })}
                                  size="small"
                                  type="number"
                                  style={{ width: 120 }}
                                  inputProps={{
                                    min: 0,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <div
                                        style={{
                                          padding: "3px",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {distanceValue}
                                      </div>
                                    ),
                                  }}
                                  error={errors?.height}
                                />
                              </div>
                              <div style={{ paddingTop: 25 }}>
                                <SingleSelect
                                  width={"120px"}
                                  height={"40px"}
                                  title={distanceValue}
                                  options={unit_values}
                                  variant={"outlined"}
                                  anchor={anchorUnit}
                                  handleMenuItemClick={handleUnitClick}
                                  handleClick={handleUnitSelectClick}
                                  handleClose={handleUnitSelectClose}
                                  disabled={disablePackages}
                                />
                              </div>

                              <div>
                                <div
                                  style={{
                                    color: "#666",
                                    marginBottom: "5px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Weight
                                </div>
                                <TextField
                                  {...register(`weight`, {
                                    required:
                                      selectedLabel === "Ingram Micro" ||
                                      selectedLabel === "TD Synnex"
                                        ? false
                                        : `Please enter length`,
                                    pattern: {
                                      value: /^[1-9][0-9]*$/i,
                                      message: "Please enter valid weight",
                                    },
                                  })}
                                  size="small"
                                  type="number"
                                  style={{ width: 120 }}
                                  inputProps={{
                                    min: 0,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <div
                                        style={{
                                          padding: "3px",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {massValue}
                                      </div>
                                    ),
                                  }}
                                  error={errors?.weight}
                                />
                              </div>
                              <div style={{ paddingTop: 25 }}>
                                <SingleSelect
                                  width={"120px"}
                                  height={"40px"}
                                  title={massValue}
                                  options={weight_values}
                                  variant={"outlined"}
                                  anchor={anchorWeight}
                                  handleMenuItemClick={handleWeightClick}
                                  handleClick={handleWeightSelectClick}
                                  handleClose={handleWeightSelectClose}
                                  disabled={disablePackages}
                                />
                              </div>
                            </div>{" "}
                            <div
                              style={{
                                display: "flex",
                                marginTop: "10px",
                                gap: "10px",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              {!isEdited && (
                                <div
                                  style={{
                                    paddingTop: "18px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: "10px",
                                  }}
                                >
                                  <Button type="submit" variant="contained">
                                    Get Rates
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </PackageCardContainer>
                      </Grid>
                    </Card>
                  </form>
                </Grid>
                <Grid item md={3}>
                  {amzAllOrderDetails?.orderItems?.some(
                    (item) =>
                      item.shipmentDetails && item.shipmentDetails.length > 0
                  ) && (
                    <Card
                      sx={{
                        ml: 2,
                        mr: 2,
                        mt: 2,
                        maxHeight: 560,
                        overflowY: "auto",
                        p: 2,
                      }}
                      elevation={4}
                    >
                      <div>
                        <div
                          style={{
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Shipment Details
                        </div>
                        <Divider />
                        <div>
                          {amzAllOrderDetails?.orderItems?.map((orderItem) => (
                            <>
                              {orderItem?.shipmentDetails?.map((item) => {
                                let carrierLogo =
                                  CARRIER_LOGOS[item?.carrierName];

                                return (
                                  <>
                                    {item?.trackingId && (
                                      <div style={{ width: "100%" }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: 10,
                                          }}
                                        >
                                          <img
                                            style={{ marginRight: 5 }}
                                            width="40px"
                                            src={`/assets/media/carrier/${carrierLogo}`}
                                            alt={"logo"}
                                          />
                                          -
                                          <div
                                            style={{
                                              marginLeft: 5,
                                              fontWeight: 500,
                                            }}
                                          >
                                            {item.carrierName}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: 5,
                                            fontWeight: 500,
                                          }}
                                        >
                                          {item.serviceName}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: 5,
                                            marginBottom: 5,
                                            marginTop: 5,
                                          }}
                                        >
                                          <div>Order ID :</div>
                                          <div>{orderItem?.OrderItemId}</div>
                                        </div>
                                        {(item?.trackingId !== "-" &&
                                          item?.trackingId !== "") && (
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: 5,
                                              marginBottom: 5,
                                              marginTop: 5,
                                            }}
                                          >
                                            <div>Tracking ID :</div>
                                            <div>{item?.trackingId}</div>
                                          </div>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: 5,
                                            marginBottom: 5,
                                            marginTop: 5,
                                          }}
                                        >
                                          <div>Quantity :</div>
                                          <div>{item?.quantity}</div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: 5,
                                            marginBottom: 5,
                                            marginTop: 5,
                                          }}
                                        >
                                          <div>Status:</div>
                                          <Chip
                                            size="small"
                                            color={
                                              item?.status === "Cancelled"
                                                ? "error"
                                                : item?.status ===
                                                  "Partially Ship"
                                                ? "primary"
                                                : item?.status === "Shipped"
                                                ? "success"
                                                : item?.status ===
                                                  "Awaiting Tracking"
                                                ? "warning"
                                                : "default"
                                            }
                                            variant={"outlined"}
                                            label={
                                              item?.status
                                                ? item?.status
                                                : "Unshipped"
                                            }
                                          />
                                        </div>
                                        {item?.type === "Synnex" ||
                                        item?.type === "Ingram" ? (
                                          <></>
                                        ) : (
                                          <>
                                            <div style={{ marginTop: "10px" }}>
                                              <Button
                                                onClick={handleConfirmModelOpen}
                                                sx={{
                                                  color: "white",
                                                  backgroundColor: "#3D3BDE",
                                                  "&:hover": {
                                                    backgroundColor: "#423dff",
                                                  },
                                                }}
                                              >
                                                Cancel Label
                                              </Button>
                                            </div>
                                            {!item.packageSlip && (
                                              <div
                                                style={{
                                                  marginTop: "10px",
                                                }}
                                              >
                                                <Button
                                                  onClick={() =>
                                                    handleCreatePackage(
                                                      item.shipmentId
                                                    )
                                                  }
                                                  sx={{
                                                    color: "white",
                                                    backgroundColor: "#3D3BDE",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "#423dff",
                                                    },
                                                  }}
                                                >
                                                  Create Package Slip
                                                </Button>
                                              </div>
                                            )}
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <div style={{ fontWeight: 600 }}>
                                                Label
                                              </div>
                                              <div></div>
                                              <IconButton
                                                onClick={() =>
                                                  handlePdf(
                                                    item.label.contents,
                                                    "blob"
                                                  )
                                                }
                                              >
                                                <PictureAsPdfIcon />
                                              </IconButton>
                                            </div>
                                            {item?.packageSlip && (
                                              <div
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                  marginTop: "10px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  Package Slip
                                                </div>
                                                <div></div>
                                                <IconButton
                                                  onClick={() =>
                                                    handlePdf(item?.packageSlip)
                                                  }
                                                >
                                                  <PictureAsPdfIcon />
                                                </IconButton>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    <Dialog open={openConfirmModel}>
                                      <div
                                        style={{
                                          width: 400,
                                          height: 250,
                                          borderRadius: 8,
                                          boxShadow:
                                            "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                        }}
                                      >
                                        <DialogTitle
                                          sx={{
                                            fontWeight: 600,
                                            pt: "15px",
                                            pb: "10px",
                                            background: "#f0f0f0",
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>Confirmation</div>
                                          <CancelIcon
                                            style={{
                                              color: "#666666",
                                              cursor: "pointer",
                                              fontSize: 27,
                                            }}
                                            onClick={handleConfirmModelClose}
                                          />
                                        </DialogTitle>
                                        <hr
                                          style={{
                                            margin: 0,
                                            background: "#e0e0e0",
                                          }}
                                        />
                                        <DialogContent>
                                          <DialogContentText
                                            sx={{ fontWeight: 600 }}
                                          >
                                            Are you certain you wish to cancel
                                            this order?
                                          </DialogContentText>
                                        </DialogContent>
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: 0,
                                            width: "100%",
                                          }}
                                        >
                                          <hr
                                            style={{
                                              margin: 0,
                                              background: "#e0e0e0",
                                            }}
                                          />
                                          <DialogActions
                                            style={{
                                              background: "#f0f0f0",
                                              padding: "10px",
                                              borderBottomLeftRadius: 8,
                                              borderBottomRightRadius: 8,
                                            }}
                                          >
                                            <Button
                                              onClick={handleConfirmModelClose}
                                              color="primary"
                                              autoFocus
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              onClick={() =>
                                                handlShipmentCancel(item)
                                              }
                                              variant="contained"
                                            >
                                              Confirm
                                            </Button>
                                          </DialogActions>
                                        </div>
                                      </div>
                                    </Dialog>
                                  </>
                                );
                              })}
                            </>
                          ))}

                          {!amzAllOrderDetails?.orderItems?.[0]?.shipmentDetails
                            ?.length < 0 && <div>No Shipment</div>}
                        </div>
                      </div>
                    </Card>
                  )}
                  {walmartOrderDetails?.orderLines?.orderLine?.some(
                    (item) =>
                      item.shipmentDetails && item.shipmentDetails.length > 0
                  ) && (
                    <Card
                      sx={{
                        ml: 2,
                        mr: 2,
                        mt: 2,
                        maxHeight: 560,
                        overflowY: "auto",
                        p: 2,
                      }}
                      elevation={4}
                    >
                      <div>
                        <div
                          style={{
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Shipment Details
                        </div>
                        <Divider />
                        <div>
                          {walmartOrderDetails?.orderLines?.orderLine?.map(
                            (orderItem) => (
                              <>
                                {orderItem?.shipmentDetails?.map((item) => {
                                  let carrierLogo =
                                    CARRIER_LOGOS[item?.carrierName];

                                  return (
                                    <>
                                      {item?.trackingId && (
                                        <div style={{ width: "100%" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginTop: 10,
                                            }}
                                          >
                                            <img
                                              style={{ marginRight: 5 }}
                                              width="40px"
                                              src={`/assets/media/carrier/${carrierLogo}`}
                                              alt={"logo"}
                                            />
                                            -
                                            <div
                                              style={{
                                                marginLeft: 5,
                                                fontWeight: 500,
                                              }}
                                            >
                                              {item?.carrierName}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: 5,
                                              fontWeight: 500,
                                            }}
                                          >
                                            {item?.serviceName}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: 5,
                                              marginBottom: 5,
                                              marginTop: 5,
                                            }}
                                          >
                                            <div>Order ID :</div>
                                            <div>{id}</div>
                                          </div>
                                          {item?.trackingId !== "-" &&
                                            item?.trackingId !== "" && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: 5,
                                                  marginBottom: 5,
                                                  marginTop: 5,
                                                }}
                                              >
                                                <div>Tracking ID :</div>
                                                <div>{item?.trackingId}</div>
                                              </div>
                                            )}
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: 5,
                                              marginBottom: 5,
                                              marginTop: 5,
                                            }}
                                          >
                                            <div>Quantity :</div>
                                            <div>{item?.quantity}</div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: 5,
                                              marginBottom: 5,
                                              marginTop: 5,
                                            }}
                                          >
                                            <div>Status:</div>
                                            <Chip
                                              size="small"
                                              color={
                                                item?.status === "Cancelled"
                                                  ? "error"
                                                  : item?.status ===
                                                    "Partially Ship"
                                                  ? "primary"
                                                  : item?.status === "Shipped"
                                                  ? "success"
                                                  : item?.status ===
                                                    "Awaiting Tracking"
                                                  ? "warning"
                                                  : "default"
                                              }
                                              variant={"outlined"}
                                              label={
                                                item?.status
                                                  ? item?.status
                                                  : "Unshipped"
                                              }
                                            />
                                          </div>
                                          {item?.type === "Synnex" ||
                                          item?.type === "Ingram" ? (
                                            <></>
                                          ) : (
                                            <>
                                              <div
                                                style={{ marginTop: "10px" }}
                                              >
                                                <Button
                                                  onClick={
                                                    handleConfirmModelOpen
                                                  }
                                                  sx={{
                                                    color: "white",
                                                    backgroundColor: "#3D3BDE",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "#423dff",
                                                    },
                                                  }}
                                                >
                                                  Cancel Label
                                                </Button>
                                              </div>
                                              {!item.packageSlip && (
                                                <div
                                                  style={{
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  <Button
                                                    onClick={() =>
                                                      handleCreatePackage(
                                                        item.shipmentId
                                                      )
                                                    }
                                                    sx={{
                                                      color: "white",
                                                      backgroundColor:
                                                        "#3D3BDE",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "#423dff",
                                                      },
                                                    }}
                                                  >
                                                    Create Package Slip
                                                  </Button>
                                                </div>
                                              )}
                                              <div
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                  marginTop: "10px",
                                                }}
                                              >
                                                <div
                                                  style={{ fontWeight: 600 }}
                                                >
                                                  Label
                                                </div>
                                                <div></div>
                                                <IconButton
                                                  onClick={() =>
                                                    handlePdf(
                                                      item.label.contents,
                                                      "blob"
                                                    )
                                                  }
                                                >
                                                  <PictureAsPdfIcon />
                                                </IconButton>
                                              </div>
                                              {item?.packageSlip && (
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    Package Slip
                                                  </div>
                                                  <div></div>
                                                  <IconButton
                                                    onClick={() =>
                                                      handlePdf(
                                                        item?.packageSlip
                                                      )
                                                    }
                                                  >
                                                    <PictureAsPdfIcon />
                                                  </IconButton>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      )}
                                      <Dialog open={openConfirmModel}>
                                        <div
                                          style={{
                                            width: 400,
                                            height: 250,
                                            borderRadius: 8,
                                            boxShadow:
                                              "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                          }}
                                        >
                                          <DialogTitle
                                            sx={{
                                              fontWeight: 600,
                                              pt: "15px",
                                              pb: "10px",
                                              background: "#f0f0f0",
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>Confirmation</div>
                                            <CancelIcon
                                              style={{
                                                color: "#666666",
                                                cursor: "pointer",
                                                fontSize: 27,
                                              }}
                                              onClick={handleConfirmModelClose}
                                            />
                                          </DialogTitle>
                                          <hr
                                            style={{
                                              margin: 0,
                                              background: "#e0e0e0",
                                            }}
                                          />
                                          <DialogContent>
                                            <DialogContentText
                                              sx={{ fontWeight: 600 }}
                                            >
                                              Are you certain you wish to cancel
                                              this order?
                                            </DialogContentText>
                                          </DialogContent>
                                          <div
                                            style={{
                                              position: "absolute",
                                              bottom: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <hr
                                              style={{
                                                margin: 0,
                                                background: "#e0e0e0",
                                              }}
                                            />
                                            <DialogActions
                                              style={{
                                                background: "#f0f0f0",
                                                padding: "10px",
                                                borderBottomLeftRadius: 8,
                                                borderBottomRightRadius: 8,
                                              }}
                                            >
                                              <Button
                                                onClick={
                                                  handleConfirmModelClose
                                                }
                                                color="primary"
                                                autoFocus
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                onClick={() =>
                                                  handlShipmentCancel(item)
                                                }
                                                variant="contained"
                                              >
                                                Confirm
                                              </Button>
                                            </DialogActions>
                                          </div>
                                        </div>
                                      </Dialog>
                                    </>
                                  );
                                })}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </Card>
                  )}
                  {!disablePackages ? (
                    <Card
                      sx={{
                        ml: 2,
                        mr: 2,
                        mt: 2,
                        maxHeight: 560,
                        overflowY: "auto",
                        p: 2,
                      }}
                      elevation={4}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 10,
                          }}
                        >
                          <div style={{ fontWeight: "bold" }}>Rates</div>
                          <div
                            style={{
                              color: "#3d3bde",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          ></div>
                        </div>
                        <div style={{ color: "#a1abb0" }}>
                          Transit times may be estimated.
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Shipments
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            defaultValue={dayjs(new Date())}
                            label="Select Date"
                            disablePast
                            onChange={(item) => {
                              setDateValue(item);
                            }}
                          />
                        </LocalizationProvider>
                        <div
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          More Rates
                        </div>

                        <div>
                          {ratesLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              {ratesLoading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CircularProgress />
                                </div>
                              ) : (
                                amzRates &&
                                selectedLabel === "Amazon" &&
                                amzRates?.rates?.map((item, i) => {
                                  let carrierLogo =
                                    CARRIER_LOGOS[item?.carrierName];
                                  return (
                                    <div
                                      onClick={() => handleSelectRate(item)}
                                      key={item?.rateId}
                                      style={{
                                        width: "100%",
                                        border:
                                          activeCard === item?.rateId
                                            ? "1px solid #3D3BDE"
                                            : "1px solid #e9f1f5",
                                        backgroundColor:
                                          activeCard === item?.rateId
                                            ? "rgba(116, 115, 218, 0.2)"
                                            : "#ffffff",
                                        borderRadius: "5px",
                                        marginTop: 10,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                          marginLeft: 10,
                                          marginRight: 10,
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "70%",
                                            alignItems: "center",
                                          }}
                                        >
                                          {carrierLogo && (
                                            <div>
                                              <img
                                                width="30px"
                                                src={`/assets/media/carrier/${carrierLogo}`}
                                                alt={""}
                                              />
                                            </div>
                                          )}
                                          <div
                                            style={{
                                              marginLeft: 10,
                                            }}
                                          >
                                            <div
                                              style={{
                                                color: "black",
                                              }}
                                            >
                                              {item?.carrierName}
                                            </div>
                                            <div
                                              style={{
                                                color: "black",
                                              }}
                                            >
                                              {item?.serviceName}
                                            </div>
                                          </div>
                                        </div>
                                        <div></div>
                                        <div>
                                          <div
                                            style={{
                                              fontWeight: "bold",
                                              color: "black",
                                            }}
                                          >
                                            ${item?.totalCharge?.value}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                              {ratesLoading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CircularProgress />
                                </div>
                              ) : (
                                walmartRates &&
                                selectedLabel === "Walmart" &&
                                walmartRates?.rates?.data?.estimates?.map(
                                  (item, i) => {
                                    let carrierLogo =
                                      CARRIER_LOGOS[item?.carrierName];
                                    return (
                                      <div
                                        onClick={() => handleWalmartRate(item)}
                                        key={item?.name}
                                        style={{
                                          width: "100%",
                                          border:
                                            activeCard === item?.name
                                              ? "1px solid #3D3BDE"
                                              : "1px solid #e9f1f5",
                                          backgroundColor:
                                            activeCard === item?.name
                                              ? "rgba(116, 115, 218, 0.2)"
                                              : "#ffffff",
                                          borderRadius: "5px",
                                          marginTop: 10,
                                          cursor: "pointer",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                            marginLeft: 10,
                                            marginRight: 10,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "70%",
                                              alignItems: "center",
                                            }}
                                          >
                                            {carrierLogo && (
                                              <div>
                                                <img
                                                  width="30px"
                                                  src={`/assets/media/carrier/${carrierLogo}`}
                                                  alt={""}
                                                />
                                              </div>
                                            )}
                                            <div
                                              style={{
                                                marginLeft: 10,
                                              }}
                                            >
                                              <div
                                                style={{
                                                  color: "black",
                                                }}
                                              >
                                                {item?.name}
                                              </div>
                                              <div
                                                style={{
                                                  color: "black",
                                                }}
                                              >
                                                {item?.displayName}
                                              </div>
                                            </div>
                                          </div>
                                          <div></div>
                                          <div>
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                            >
                                              ${item?.estimatedRate?.amount}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              )}
                              {ratesLoading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CircularProgress />
                                </div>
                              ) : (
                                aFiveRates &&
                                selectedLabel === "A5 IT" &&
                                filtered?.map((item, i) => {
                                  const carrierLogo = CARRIER_LOGOS["UPS"];
                                  return (
                                    <div
                                      onClick={() => handleSelectUpsRate(item)}
                                      key={item?.Service?.Code}
                                      style={{
                                        width: "100%",
                                        border:
                                          activeCard === item?.Service?.Code
                                            ? "1px solid #3D3BDE"
                                            : "1px solid #e9f1f5",
                                        backgroundColor:
                                          activeCard === item?.Service?.Code
                                            ? "rgba(116, 115, 218, 0.2)"
                                            : "#ffffff",
                                        borderRadius: "5px",
                                        marginTop: 10,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                          marginLeft: 10,
                                          marginRight: 10,
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "50%",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <img
                                              width="30px"
                                              src={`/assets/media/carrier/${carrierLogo}`}
                                              alt={""}
                                            />
                                          </div>
                                          <div style={{ marginLeft: 5 }}>
                                            <div>UPS</div>
                                            <div>
                                              {
                                                item?.TimeInTransit
                                                  ?.ServiceSummary?.Service
                                                  ?.Description
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div></div>
                                        <div>
                                          <div style={{ fontWeight: "bold" }}>
                                            $
                                            {
                                              item?.RatedPackage?.TotalCharges
                                                ?.MonetaryValue
                                            }
                                          </div>
                                          <div></div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                              {ratesLoading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CircularProgress />
                                </div>
                              ) : (
                                deleveryDayRates &&
                                selectedLabel === "DeleveryDay" &&
                                deleveryDayRates?.rates?.rates?.map(
                                  (item, i) => {
                                    const carrierLogo =
                                      CARRIER_LOGOS[item?.carrierCode];
                                    return (
                                      <div
                                        onClick={() => handleRate(item)}
                                        key={item?.service}
                                        style={{
                                          width: "100%",
                                          border:
                                            activeCard === item?.service
                                              ? "1px solid rgb(48, 136, 30)"
                                              : "1px solid #e9f1f5",
                                          backgroundColor:
                                            activeCard === item?.service
                                              ? "rgb(242, 255, 235)"
                                              : "#ffffff",
                                          borderRadius: "5px",
                                          marginTop: 10,
                                          cursor: "pointer",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                            marginLeft: 10,
                                            marginRight: 10,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              {carrierLogo && (
                                                <div>
                                                  <img
                                                    width="30px"
                                                    src={`/assets/media/carrier/${carrierLogo}`}
                                                    alt="Carrier Logo"
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            <div style={{ marginLeft: 5 }}>
                                              <div>{item?.service}</div>
                                              <div>{item?.serviceType}</div>
                                            </div>
                                          </div>

                                          <div>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontWeight: "bold",
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                ${item?.shippingCost}
                                              </div>
                                              <div
                                                style={{
                                                  textDecoration: "none",
                                                  marginLeft: "5px",
                                                  fontSize: "12px",
                                                  borderRadius: "50px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  padding: "2px 4px",
                                                  color: "white",
                                                  background: "#fcae1e",
                                                }}
                                              >
                                                {user?.ratePercentage}% SAVE
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                fontSize: "20px",
                                              }}
                                            >
                                              ${item?.discountShippingCost}
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              {item?.transitTime}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    // <Card
                    //   sx={{
                    //     ml: 2,
                    //     mr: 2,
                    //     mt: 2,
                    //     maxHeight: 560,
                    //     overflowY: "auto",
                    //     p: 2,
                    //   }}
                    //   elevation={4}
                    // >
                    //   <>
                    //     <div
                    //       style={{
                    //         marginBottom: 10,
                    //         fontWeight: "bold",
                    //       }}
                    //     >
                    //       Shipment Details
                    //     </div>
                    //     <div style={{ display: "flex", alignItems: "center" }}>
                    //       {providerImage && (
                    //         <img
                    //           style={{ marginRight: 5 }}
                    //           width="60px"
                    //           src={providerImage}
                    //           alt={""}
                    //         />
                    //       )}
                    //       -
                    //       {providerName && (
                    //         <div
                    //           style={{
                    //             marginLeft: 5,
                    //             fontWeight: 500,
                    //           }}
                    //         >
                    //           {providerName}
                    //         </div>
                    //       )}
                    //     </div>
                    //     {trackingNumber && (
                    //       <>
                    //         <div
                    //           style={{
                    //             marginTop: 10,
                    //             marginBottom: 10,
                    //             fontWeight: "bold",
                    //           }}
                    //         >
                    //           Tracking Number :
                    //         </div>
                    //         <div>
                    //           {trackingNumber}{" "}
                    //           <CopyToClipboard text={trackingNumber}>
                    //             <MdContentCopy
                    //               size={"15px"}
                    //               cursor="pointer"
                    //               onClick={() =>
                    //                 generateSuccessToast("Value Copied!")
                    //               }
                    //               title="Copy Value"
                    //             />
                    //           </CopyToClipboard>
                    //         </div>
                    //       </>
                    //     )}
                    //     {labels && (
                    //       <>
                    //         <div
                    //           style={{
                    //             marginTop: 10,
                    //             marginBottom: 10,
                    //             fontWeight: "bold",
                    //             color: "black",
                    //           }}
                    //         >
                    //           Labels
                    //         </div>
                    //         <div
                    //           style={{
                    //             display: "flex",
                    //             justifyContent: "space-between",
                    //             marginBottom: 10,
                    //           }}
                    //         >
                    //           <div style={{ fontWeight: "bold" }}>
                    //             Shipping Label
                    //           </div>
                    //           <IconButton onClick={() => handlePdf(labels)}>
                    //             <PictureAsPdfIcon />
                    //           </IconButton>
                    //         </div>
                    //       </>
                    //     )}
                    //   </>
                    // </Card>
                    <></>
                  )}
                  {displayPopup && (
                    <BatchModal
                      displayPopup={displayPopup}
                      setDisplayPopup={setDisplayPopup}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </MenuSidebar>
  );
};

export default OrderDetails;
