import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDashboardContext } from "../../../contexts/DashboardContext/DashboardContext";

const ModalContainer = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "5px",
}));
const ModalForm = styled("div")(() => ({
  marginTop: 10,
  width: "100%",
}));
const ModalTitle = styled("div")(() => ({
  marginTop: 10,
  marginBottom: 10,
  fontSize: 16,
  fontWeight: "700",
}));
const ModalFieldContainer = styled("div")(() => ({
  display: "flex",
  gap: "10px",
  width: "100%",
  marginTop: "5px",
}));

const IngramModel = ({
  isModalOpen,
  handleCloseModal,
  selectedLabel,
  handleIngramSubmit,
  selectItemValue,
  ingramRegister,
  ingramSetValue,
  amzAllOrderDetails,
  ebayOrderDetails,
  walmartOrderDetails,
  orderType,
  errors,
  setErrors,
  formData,
  setFormData,
  ingramReset,
  endUserTypeOptions,
}) => {
  const { postByIngramCreateOrderAPICALL, ingramResponse } =
    useDashboardContext();
  const [eUserChecked, setEUserChecked] = useState(false);
  const [specialBidChecked, setSpecialBidChecked] = useState(false);
  const [vmfChecked, setVmfChecked] = useState(false);

  const ingramSubmit = (data) => {
    const itemsData = selectItemValue.map((item, index) => ({
      itemId: item.OrderItemId,
      shipQuantity: data.quantities[index] || "",
    }));
    const ingramLine = selectItemValue.map((item, index) => ({
      customerLineNumber: data.customerLineNumbers[index] || "",
      ingramPartNumber: data.ingramPartNumbers[index] || "",
      quantity: Number(data.quantities[index]) || 0,
    }));

    let payload = {
      orderId: amzAllOrderDetails?._id || "",
      itemsInfo: itemsData,
      ingramPayload: {
        customerOrderNumber: data.orderDetailsCustomerNumber || "",
        // endCustomerOrderNumber: data.orderDetailsEndCustomerOrder || "",
        shipToInfo: {
          contact: data.shipInformationToContact || "",
          // companyName: data.shipInformationToCompanyName || "",
          addressLine1: data.shipInformationToAddressLine1 || "",
          city: data.shipInformationToCity || "",
          state: data.shipInformationToState || "",
          postalCode: data.shipInformationToPostalCode || "",
          countryCode: data.shipInformationToCountryCode || "",
          phoneNumber: data.shipInformationToPhoneNumber || "",
          email: data.shipInformationToEmail || "",
        },
        resellerInfo: {
          resellerId: "70-086662",
          contact: "Prestige IT Goods",
          companyName: "A5 IT",
          addressLine1: "1320 S VIRGINIA RD",
          city: "CRYSTAL LAKE",
          state: "IL",
          postalCode: "60014",
          countryCode: "US",
          phoneNumber: "+1 8475059936",
          email: "info@a5it.com",
        },

        lines: ingramLine,
        // additionalAttributes: [
        //   {
        //     attributeName:
        //       data.additionalAttributesAttributesName ||
        //       "allowDuplicateCustomerOrderNumber",
        //     attributeValue: data.additionalAttributesAttributesValue || "true",
        //   },
        // ],
      },
    };
    if (eUserChecked) {
      payload.ingramPayload.endUserInfo = {
        eUserId: "123456",
        contact: data?.eUserContact || "",
        companyName: data?.endUserCompanyName || "",
        addressLine1: data?.endUserAddressLine1 || "",
        city: data?.eUserCity || "",
        state: data?.eUserState || "",
        postalCode: data?.eUserPostalCode || "",
        countryCode: data?.eUserCountryCode || "",
        phoneNumber: data?.eUserPhoneNumber || "",
        email: data?.eUserEmail || "",
      };
    }
    if (specialBidChecked) {
      payload.ingramPayload.specialBidNumber = data?.specialBidNumber;
    }
    if (vmfChecked) {
      payload.ingramPayload.vmf = { vendAuthNumber: data.vendAuthNumber };
    }
    // console.log(payload, "payload");

    postByIngramCreateOrderAPICALL(amzAllOrderDetails?._id, payload, orderType,handleCloseModal);
    
  };
  const handleSpecialBidChecked = (e) => {
    const { checked } = e.target;
    setSpecialBidChecked(checked);
  };
  const handleVmfChecked = (e) => {
    const { checked } = e.target;
    setVmfChecked(checked);
  };
  const handleEUCheckboxChange = (e) => {
    const { checked } = e.target;
    //  const value = checked ? "Y" : "N";
    setEUserChecked(checked);
    //  setEUser((prev) => ({ ...prev, eUserReOrder: value }));
  };
  useEffect(() => {
    setFormData({
      customerLineNumbers: selectItemValue?.map((_, index) =>
        (index + 1).toString()
      ),
      ingramPartNumbers: Array(selectItemValue.length).fill(""), // Initialize with empty strings
      quantities: selectItemValue?.map((item) =>
        item?.QuantityOrdered.toString()
      ),
    });
  }, [selectItemValue]);
  useEffect(() => {
    if (amzAllOrderDetails && selectItemValue.length > 0) {
      selectItemValue.forEach((item, index) => {
        ingramSetValue(
          `customerLineNumbers[${index}]`,
          formData.customerLineNumbers[index] || ""
        );
        ingramSetValue(
          `ingramPartNumbers[${index}]`,
          formData.ingramPartNumbers[index] || ""
        );
        ingramSetValue(
          `quantities[${index}]`,
          formData.quantities[index] || ""
        );
      });
    }
  }, [amzAllOrderDetails, selectItemValue, formData, ingramSetValue]);
  useEffect(() => {
    if (isModalOpen) {
      if (amzAllOrderDetails) {
        ingramSetValue(
          "orderDetailsCustomerNumber",
          amzAllOrderDetails?._id?.replace(/-/g, "") || ""
        );
        ingramSetValue(
          "shipInformationToContact",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Name || ""
        );

        ingramSetValue(
          "shipInformationToAddressLine1",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.AddressLine1 || ""
        );
        ingramSetValue(
          "shipInformationToCity",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.City || ""
        );
        ingramSetValue(
          "shipInformationToState",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.StateOrRegion || ""
        );
        ingramSetValue(
          "shipInformationToPostalCode",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.PostalCode || ""
        );
        ingramSetValue(
          "shipInformationToCountryCode",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.CountryCode || ""
        );
        ingramSetValue(
          "shipInformationToPhoneNumber",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Phone || ""
        );
        ingramSetValue(
          "eUserContact",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Name || ""
        );
        ingramSetValue(
          "endUserCompanyName",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.company || ""
        );
        ingramSetValue(
          "endUserAddressLine1",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.AddressLine1 || ""
        );
        ingramSetValue(
          "eUserCity",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.City || ""
        );
        ingramSetValue(
          "eUserState",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Phone || ""
        );
        ingramSetValue(
          "eUserPostalCode",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.PostalCode || ""
        );
        ingramSetValue(
          "eUserCountryCode",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.CountryCode || ""
        );
        ingramSetValue(
          "eUserPhoneNumber",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Phone || ""
        );
        ingramSetValue(
          "eUserEmail",
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.BuyerInfo
            ?.BuyerEmail || ""
        );
      } else if (ebayOrderDetails) {
        ingramSetValue(
          "orderDetailsCustomerNumber",
          ebayOrderDetails?._id || ""
        );
        // ingramSetValue(
        //   "orderDetailsEndCustomerOrder",
        //   ebayOrderDetails?._id || ""
        // );
        ingramSetValue(
          "shipInformationToContact",
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.fullName || ""
        );
        ingramSetValue(
          "shipInformationToAddressLine1",
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.addressLine1 || ""
        );
        ingramSetValue(
          "shipInformationToCity",
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.city || ""
        );
        ingramSetValue(
          "shipInformationToState",
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.stateOrProvince || ""
        );
        ingramSetValue(
          "shipInformationToPostalCode",
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.postalCode || ""
        );
        ingramSetValue(
          "shipInformationToCountryCode",
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.contactAddress?.countryCode || ""
        );
        ingramSetValue(
          "shipInformationToEmail",
          ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
            ?.shipTo?.email || ""
        );
        ingramSetValue(
          "shipInformationToPhoneNumber",
          ebayOrderDetails?.fullAddress?.ShippingAddress?.Phone || ""
        );

        if (ebayOrderDetails && ebayOrderDetails.lineItems) {
          ebayOrderDetails.lineItems.forEach((item, index) => {
            ingramSetValue(
              `linesUnitPrice${index}`,
              item.lineItemCost?.value || ""
            );

            ingramSetValue(`linesOrderQuantity${index}`, item.quantity || "");
          });
        }
      } else if (walmartOrderDetails) {
        ingramSetValue(
          "orderDetailsCustomerNumber",
          walmartOrderDetails?._id || ""
        );
        // ingramSetValue(
        //   "orderDetailsEndCustomerOrder",
        //   walmartOrderDetails?._id || ""
        // );
        ingramSetValue(
          "shipInformationToContact",
          walmartOrderDetails?.shippingInfo?.postalAddress?.name || ""
        );
        ingramSetValue(
          "shipInformationToAddressName1",
          walmartOrderDetails?.shippingInfo?.postalAddress?.address1 || ""
        );
        ingramSetValue(
          "shipInformationToAddressLine1",
          walmartOrderDetails?.shippingInfo?.postalAddress?.address2 || ""
        );

        ingramSetValue(
          "shipInformationToCity",
          walmartOrderDetails?.shippingInfo?.postalAddress?.city || ""
        );
        ingramSetValue(
          "shipInformationToState",
          walmartOrderDetails?.shippingInfo?.postalAddress?.state || ""
        );
        ingramSetValue(
          "shipInformationToPostalCode",
          walmartOrderDetails?.shippingInfo?.postalAddress?.postalCode || ""
        );
        ingramSetValue(
          "shipInformationToCountryCode",
          walmartOrderDetails?.shippingInfo?.postalAddress?.country || ""
        );
        ingramSetValue(
          "shipInformationToPhoneNumber",
          walmartOrderDetails?.shippingInfo?.phone || ""
        );

        if (walmartOrderDetails && walmartOrderDetails.orderLines.orderLine) {
          walmartOrderDetails.orderLines.orderLine.forEach((item, index) => {
            ingramSetValue(
              `linesUnitPrice${index}`,
              item.charges?.charge?.[0]?.chargeAmount?.amount || ""
            );
            ingramSetValue(
              `linesOrderQuantity${index}`,
              item.orderLineQuantity?.amount || ""
            );
          });
        }
      }
    }
  }, [
    isModalOpen,
    amzAllOrderDetails,
    ebayOrderDetails,
    walmartOrderDetails,
    ingramSetValue,
  ]);
  return (
    <div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "80%",
            bgcolor: "background.paper",
            p: 2,
            overflowY: "auto",
            overflowX: "hidden",
            border: "none",
          }}
        >
          <form onSubmit={handleIngramSubmit(ingramSubmit)}>
            <ModalContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: 15,
                }}
              >
                <div style={{ fontSize: 22, fontWeight: "bold" }}>
                  {selectedLabel}
                </div>
                <div></div>
                <div
                  style={{
                    borderRadius: 20,
                    border: "1px #d0d5dd solid",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal}
                >
                  <img
                    style={{ padding: 3 }}
                    src="/assets/images/icon/close.svg"
                    alt=""
                  />
                </div>
              </div>
              <ModalForm>
                <ModalTitle>Order Details</ModalTitle>
                <ModalFieldContainer>
                  <div>
                    <div>Customer Order Number</div>
                    <TextField
                      size="small"
                      name="orderDetailsCustomerNumber"
                      {...ingramRegister("orderDetailsCustomerNumber", {
                        required: true,
                      })}
                      disabled={true}
                      style={{ width: "485px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.orderDetailsCustomerNumber && (
                      <div style={{ color: "red", marginTop: "5px" }}>
                        Customer Order Number is required
                      </div>
                    )}
                  </div>
                  {/* <div>
                    <div>End Customer Order Number</div>
                    <TextField
                      size="small"
                      name="orderDetailsEndCustomerOrder"
                      {...ingramRegister("orderDetailsEndCustomerOrder")}
                      style={{ width: "485px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div> */}
                </ModalFieldContainer>
                <ModalFieldContainer>
                  <div>
                    <div>Note</div>
                    <TextField
                      size="small"
                      name="orderDetailsNote"
                      {...ingramRegister("orderDetailsNote")}
                      style={{ width: "485px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </ModalFieldContainer>
                <ModalTitle>Ship To</ModalTitle>

                <ModalFieldContainer>
                  <div>
                    <div>AddreesLine 1</div>
                    <TextField
                      size="small"
                      name="shipInformationToAddressLine1"
                      {...ingramRegister("shipInformationToAddressLine1")}
                      style={{ width: "485px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <div>City</div>
                    <TextField
                      size="small"
                      name="shipInformationToCity"
                      {...ingramRegister("shipInformationToCity")}
                      style={{ width: "485px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </ModalFieldContainer>
                <ModalFieldContainer>
                  <div>
                    <div>State</div>
                    <TextField
                      size="small"
                      name="shipInformationToState"
                      {...ingramRegister("shipInformationToState")}
                      style={{ width: "485px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <div>Postal Code</div>
                    <TextField
                      size="small"
                      name="shipInformationToPostalCode"
                      {...ingramRegister("shipInformationToPostalCode")}
                      style={{ width: "485px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </ModalFieldContainer>
                <ModalFieldContainer>
                  <div>
                    <div>Country Code</div>
                    <TextField
                      size="small"
                      name="shipInformationToCountryCode"
                      {...ingramRegister("shipInformationToCountryCode")}
                      style={{ width: "485px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </ModalFieldContainer>
                <ModalTitle>Ship To Contact</ModalTitle>

                <ModalFieldContainer>
                  <div style={{ width: "100%" }}>
                    <div>Contact Name</div>
                    <TextField
                      fullWidth
                      size="small"
                      name="shipInformationToContact"
                      {...ingramRegister("shipInformationToContact", {
                        required: true,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors?.shipInformationToContact && (
                      <div style={{ color: "red" }}>
                        Contact Name is required
                      </div>
                    )}
                  </div>

                  <div style={{ width: "100%" }}>
                    <div>Phone Number</div>
                    <TextField
                      fullWidth
                      size="small"
                      name="shipInformationToPhoneNumber"
                      {...ingramRegister("shipInformationToPhoneNumber", {
                        required: true,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />{" "}
                    {errors?.shipInformationToPhoneNumber && (
                      <div style={{ color: "red" }}>
                        Contact Name is required
                      </div>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <div>Email</div>
                    <TextField
                      fullWidth
                      size="small"
                      name="shipInformationToEmail"
                      {...ingramRegister("shipInformationToEmail")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  {/* <div>
                    <div>Company Name</div>
                    <TextField
                      size="small"
                      style={{ width: "485px" }}
                      name="shipInformationToCompanyName"
                      {...ingramRegister("shipInformationToCompanyName")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div> */}
                </ModalFieldContainer>
                <ModalFieldContainer>
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      checked={eUserChecked}
                      onChange={handleEUCheckboxChange}
                    />
                    <div
                      style={{
                        marginTop: 9,
                      }}
                    >
                      End User
                    </div>
                  </div>
                </ModalFieldContainer>
                {eUserChecked === true && (
                  <>
                    <ModalTitle>End User</ModalTitle>
                    <ModalFieldContainer>
                      <div>
                        <div>End User Id</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="eUserId"
                          {...ingramRegister("eUserId", {
                            required: eUserChecked === true,
                          })}
                          style={{ width: "485px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors?.eUserId && (
                          <div style={{ color: "red" }}>
                            End User Id is required
                          </div>
                        )}
                      </div>
                    </ModalFieldContainer>
                    <ModalTitle>End User Address</ModalTitle>
                    <ModalFieldContainer>
                      <div>
                        <div>Address Line 1</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="endUserAddressLine1"
                          {...ingramRegister("endUserAddressLine1", {
                            required: eUserChecked === true,
                          })}
                          style={{ width: "485px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />{" "}
                        {errors?.endUserAddressLine1 && (
                          <div style={{ color: "red" }}>
                            Address Line 1 is required
                          </div>
                        )}
                      </div>
                      <div>
                        <div>Company Name </div>
                        <TextField
                          size="small"
                          fullWidth
                          {...ingramRegister("endUserCompanyName")}
                          name="endUserCompanyName"
                          style={{ width: "485px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </ModalFieldContainer>
                    <ModalFieldContainer>
                      <div>
                        <div>City</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="eUserCity"
                          {...ingramRegister("eUserCity", {
                            required: eUserChecked === true,
                          })}
                          style={{ width: "485px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors?.eUserCity && (
                          <div style={{ color: "red" }}>City is required</div>
                        )}
                      </div>
                      <div>
                        <div>State</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="eUserState"
                          {...ingramRegister("eUserState", {
                            required: eUserChecked === true,
                          })}
                          style={{ width: "485px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />{" "}
                        {errors?.eUserState && (
                          <div style={{ color: "red" }}>State is required</div>
                        )}
                      </div>
                    </ModalFieldContainer>
                    <ModalFieldContainer>
                      <div>
                        <div>Postal Code</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="eUserPostalCode"
                          {...ingramRegister("eUserPostalCode", {
                            required: eUserChecked === true,
                          })}
                          style={{ width: "485px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />{" "}
                        {errors?.eUserPostalCode && (
                          <div style={{ color: "red" }}>
                            Postal Code is required
                          </div>
                        )}
                      </div>
                      <div>
                        <div>Country Code</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="eUserCountryCode"
                          {...ingramRegister("eUserCountryCode", {
                            required: eUserChecked === true,
                          })}
                          style={{ width: "485px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />{" "}
                        {errors?.eUserCountryCode && (
                          <div style={{ color: "red" }}>
                            Country Code is required
                          </div>
                        )}
                      </div>
                    </ModalFieldContainer>
                    <ModalTitle>End User Contact</ModalTitle>
                    <ModalFieldContainer>
                      {" "}
                      <div>
                        <div>Contact Name</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="eUserContact"
                          {...ingramRegister("eUserContact", {
                            required: eUserChecked === true,
                          })}
                          style={{ width: "320px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors?.eUserContact && (
                          <div style={{ color: "red" }}>
                            Contact Name is required
                          </div>
                        )}
                      </div>
                      <div>
                        <div>Phone Number</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="eUserPhoneNumber"
                          {...ingramRegister("eUserPhoneNumber", {
                            required: eUserChecked === true,
                          })}
                          style={{ width: "320px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />{" "}
                        {errors?.eUserPhoneNumber && (
                          <div style={{ color: "red" }}>
                            Phone Number is required
                          </div>
                        )}
                      </div>
                      <div>
                        <div>Email</div>
                        <TextField
                          size="small"
                          fullWidth
                          name="eUserEmail"
                          {...ingramRegister("eUserEmail")}
                          style={{ width: "320px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />{" "}
                      </div>
                    </ModalFieldContainer>
                  </>
                )}
                <ModalFieldContainer>
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      checked={specialBidChecked}
                      onChange={handleSpecialBidChecked}
                    />
                    <div
                      style={{
                        marginTop: 9,
                      }}
                    >
                      Special Bid
                    </div>
                  </div>
                </ModalFieldContainer>

                {specialBidChecked === true && (
                  <>
                    <ModalTitle>Special Bid</ModalTitle>
                    <ModalFieldContainer>
                      <div>
                        <div>Special Bid Number</div>
                        <TextField
                          size="small"
                          name="specialBidNumber"
                          style={{ width: "485px", marginTop: 5 }}
                          {...ingramRegister("specialBidNumber", {
                            required: specialBidChecked === true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors?.specialBidNumber && (
                          <div style={{ color: "red" }}>
                            Special Bid Number is required
                          </div>
                        )}
                      </div>
                    </ModalFieldContainer>
                  </>
                )}
                <ModalFieldContainer>
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      checked={vmfChecked}
                      onChange={handleVmfChecked}
                    />
                    <div
                      style={{
                        marginTop: 9,
                      }}
                    >
                      Vmf Order
                    </div>
                  </div>
                </ModalFieldContainer>

                {vmfChecked === true && (
                  <>
                    <ModalTitle>Vmf</ModalTitle>
                    <ModalFieldContainer>
                      <div>
                        <div>Vend Auth Number</div>
                        <TextField
                          size="small"
                          name="vendAuthNumber"
                          style={{ width: "485px", marginTop: 5 }}
                          {...ingramRegister("vendAuthNumber", {
                            required: vmfChecked === true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />{" "}
                        {errors?.vendAuthNumber && (
                          <div style={{ color: "red" }}>
                            Vend Auth Number is required
                          </div>
                        )}
                      </div>
                    </ModalFieldContainer>
                  </>
                )}
                <ModalTitle>Line</ModalTitle>
                {amzAllOrderDetails &&
                  selectItemValue.length > 0 &&
                  selectItemValue.map((item, index) => (
                    <ModalFieldContainer key={item.id}>
                      <div>
                        <div>Customer Line Number</div>
                        <TextField
                          size="small"
                          name={`customerLineNumbers[${index}]`}
                          defaultValue={formData.customerLineNumbers[index]} // Assuming you have formData state
                          {...ingramRegister(`customerLineNumbers[${index}]`, {
                            defaultValue: formData.customerLineNumbers[index],
                          })}
                          style={{ width: "320px" }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div>
                        <div>IngramPartNumber*</div>
                        <TextField
                          size="small"
                          name={`ingramPartNumbers[${index}]`}
                          defaultValue={formData.ingramPartNumbers[index]} // Assuming you have formData state
                          {...ingramRegister(`ingramPartNumbers[${index}]`, {
                            defaultValue: formData.ingramPartNumbers[index],
                            required: true,
                          })}
                          style={{ width: "320px" }}
                          type="text"
                          InputLabelProps={{ shrink: true }}
                        />
                        {errors?.ingramPartNumbers?.[index] && (
                          <div style={{ color: "red" }}>
                            Ingram Part Number is required
                          </div>
                        )}
                        {ingramResponse?.notFoundProduct && (
                          <div style={{ color: "red" }}>
                            {
                              ingramResponse?.notFoundProduct?.[index]
                                ?.productStatusMessage
                            }
                          </div>
                        )}
                      </div>
                      <div>
                        <div>Quantity*</div>
                        <TextField
                          size="small"
                          name={`quantities[${index}]`}
                          defaultValue={formData.quantities[index]} // Assuming you have formData state
                          {...ingramRegister(`quantities[${index}]`, {
                            defaultValue: formData.quantities[index],
                            required: {
                              value: true,
                              message: "Quantity is required",
                            },
                            min: {
                              value: 1,
                              message: "Quantity must be greater than 0",
                            },
                            max: {
                              value: formData.quantities[index],
                              message: `Max Quantity is ${formData.quantities[index]}`,
                            },
                          })}
                          style={{ width: "320px" }}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ min: 0 }}
                          type="number"
                        />
                        {errors?.quantities?.[index] && (
                          <div style={{ color: "red" }}>
                            {errors.quantities[index] && (
                              <div>{errors.quantities[index].message}</div>
                            )}
                          </div>
                        )}
                        {ingramResponse?.quantityAvailability?.[index]
                          ?.productQuantityMessage && (
                          <div style={{ color: "red" }}>
                            {
                              ingramResponse?.quantityAvailability?.[index]
                                ?.productQuantityMessage
                            }
                          </div>
                        )}
                      </div>
                    </ModalFieldContainer>
                  ))}

                {ebayOrderDetails &&
                  ebayOrderDetails?.lineItems?.map((orderItem, index) => {
                    return (
                      <>
                        <ModalFieldContainer>
                          <div>
                            <div>Customer Line Number</div>
                            <TextField
                              size="small"
                              name="lineCustomerLineNumber"
                              style={{ width: "320px" }}
                              {...ingramRegister(`lineCustomerLineNumber]`)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                          <div>
                            <div>IngramPartNumber</div>
                            <TextField
                              size="small"
                              name="lineIngramPartNumber"
                              type="number"
                              style={{ width: "320px" }}
                              {...ingramRegister(`lineIngramPartNumber]`)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                          <div>
                            <div>Quantity</div>
                            <TextField
                              defaultValue={[orderItem.quantity]}
                              size="small"
                              name="lineQuantity"
                              type="number"
                              style={{ width: "320px" }}
                              {...ingramRegister(`lineQuantity]`)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </ModalFieldContainer>
                      </>
                    );
                  })}
                {walmartOrderDetails &&
                  walmartOrderDetails?.orderLines?.orderLine?.map(
                    (orderItem, index) => {
                      return (
                        <>
                          <ModalFieldContainer>
                            <div>
                              <div>Customer Line Number</div>
                              <TextField
                                defaultValue={[orderItem.lineNumber]}
                                size="small"
                                name="lineCustomerLineNumber"
                                style={{ width: "320px" }}
                                {...ingramRegister(`lineCustomerLineNumber]`)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                            <div>
                              <div>IngramPartNumber</div>
                              <TextField
                                size="small"
                                name="lineIngramPartNumber"
                                type="number"
                                style={{ width: "320px" }}
                                {...ingramRegister(`lineIngramPartNumber]`)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                            <div>
                              <div>Quantity</div>
                              <TextField
                                defaultValue={[
                                  orderItem.orderLineQuantity.amount,
                                ]}
                                size="small"
                                name="lineQuantity"
                                type="number"
                                style={{ width: "320px" }}
                                {...ingramRegister(`lineQuantity]`)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                          </ModalFieldContainer>
                        </>
                      );
                    }
                  )}
                {/* <ModalTitle>Additional Attributes</ModalTitle>
                <div>
                  <div>Attributes Name </div>
                  <TextField
                    size="small"
                    name="additionalAttributesAttributesName"
                    style={{ width: "980px" }}
                    {...ingramRegister("additionalAttributesAttributesName")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <div>Attributes Value</div>
                  <TextField
                    size="small"
                    name="additionalAttributesAttributesValue"
                    style={{ width: "980px" }}
                    {...ingramRegister("additionalAttributesAttributesValue")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div> */}
              </ModalForm>
            </ModalContainer>

            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  color: "black",
                  border: "0.5px solid black",
                  padding: "auto 15px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  color: "white",
                  backgroundColor: "#3D3BDE",
                  "&:hover": {
                    backgroundColor: "#423dff",
                  },
                }}
              >
                Create Order
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default IngramModel;
