import React, { createContext, useContext } from "react";
import { useAuth } from "./AuthContext";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const { updateUser } = useAuth();

  const updateUserDetails = async (data, token) => {
    try {
      updateUser(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        updateUserDetails,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
