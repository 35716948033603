import { Dialog } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState } from "react";
import styled from "styled-components";
import PopUp from "./PopUp";
import { DoubleArrow } from "@mui/icons-material";
const RecipientContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
}));
const RecipientInnerConatiner = styled("div")(() => ({
  width: "100%",
}));
const AdressConatiner = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
const AddressContainer = styled("div")(() => ({
  fontSize: "18px",
}));

const RecipientTitle = styled("div")(() => ({
  color: "black",
  fontSize: "20px",
  fontWeight: "bold",
}));

const AdressTitle = styled("div")(() => ({
  fontSize: "14px",
  color: "gray",
}));
const DefaultContainer = styled("div")(() => ({
  marginTop: "18px",
  width: "100%",
  minHeight: 120,
}));

const CompanyName = styled("div")(() => ({
  fontSize: "18px",
  fontWeight: 600,
}));
const Title = styled("div")(() => ({
  fontSize: "18px",
}));
const PopupText = styled("div")(() => ({
  fontSize: "14px",
  color: "#3d3bde",
  fontWeight: "bold",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const RecipientCard = ({
  EbayShipTo,
  AmazonShipTo,
  WalmartShipTo,
  senderAddress,
  setSenderAddress,
  disablePackages,
  userRole,
}) => {
  const [open, setOpen] = useState(true);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openPopUpSender, setOpenPopUpSender] = useState(false);

  const handleOpenPopUP = () => {
    setOpenPopUp(!openPopUp);
  };
  const handleOpenPopUPSender = () => {
    setOpenPopUpSender(!openPopUpSender);
  };
  const handleClose = () => {
    setOpenPopUp(false);
    setOpenPopUpSender(false);
  };

  return (
    <>
      <RecipientContainer>
        <RecipientInnerConatiner>
          <AdressConatiner>
            <RecipientTitle>Sender</RecipientTitle>
            {!disablePackages && (
              <AdressConatiner style={{ gap: "10px" }}>
                {senderAddress ? (
                  <PopupText onClick={handleOpenPopUPSender}>
                    <BorderColorIcon />
                  </PopupText>
                ) : (
                  <PopupText onClick={handleOpenPopUPSender}>
                    <BorderColorIcon />
                  </PopupText>
                )}
              </AdressConatiner>
            )}
          </AdressConatiner>
          <DefaultContainer>
            {senderAddress && (
              <div>
                <CompanyName>{senderAddress?.company}</CompanyName>
                <Title>{senderAddress?.name}</Title>
                <Title>{senderAddress?.street1}</Title>
                <Title>{senderAddress?.street2}</Title>
                <AddressContainer>
                  <>
                    <span>{senderAddress?.city}</span>
                    {" , "}
                    <span>{senderAddress?.state}</span>
                    {" , "}
                    <span>{senderAddress?.zip}</span>
                    {" , "}
                    <span>{senderAddress?.country}</span>
                  </>
                  {" , "}
                  <span>{senderAddress?.email}</span>
                  {" , "}
                  <div>{senderAddress?.phone}</div>
                </AddressContainer>
              </div>
            )}
          </DefaultContainer>
        </RecipientInnerConatiner>
        <DoubleArrow
          sx={{ pr: 2, pl: 2 }}
          style={{ fontSize: 25, color: "#3d3bde" }}
        />
        <RecipientInnerConatiner>
          <AdressConatiner>
            <RecipientTitle>Recipient</RecipientTitle>

            {!disablePackages && userRole === "SUPER_ADMIN" && (
              <PopupText onClick={handleOpenPopUP}>
                <BorderColorIcon />
              </PopupText>
            )}
          </AdressConatiner>
          <DefaultContainer>
            {EbayShipTo && (
              <div>
                <CompanyName>{EbayShipTo?.fullName}</CompanyName>
                <Title>{EbayShipTo?.contactAddress?.company}</Title>
                <Title>{EbayShipTo?.contactAddress?.addressLine1}</Title>
                <Title>{EbayShipTo?.contactAddress?.addressLine2}</Title>
                <AddressContainer>
                  <>
                    <span>{EbayShipTo?.contactAddress?.city}</span>
                    <span>{EbayShipTo?.contactAddress?.stateOrProvince}</span>
                    <span>{EbayShipTo?.contactAddress?.postalCode}</span>
                    <span>{EbayShipTo?.contactAddress?.countryCode}</span>
                  </>
                  <span>{EbayShipTo?.email}</span>
                  <div>{EbayShipTo?.primaryPhone?.phoneNumber}</div>
                </AddressContainer>
              </div>
            )}
            {WalmartShipTo && (
              <div>
                <CompanyName>
                  {WalmartShipTo?.shippingInfo?.postalAddress?.name}
                </CompanyName>
                <Title>
                  {WalmartShipTo?.shippingInfo?.postalAddress?.company}
                </Title>
                <Title>
                  {WalmartShipTo?.shippingInfo?.postalAddress?.address1}
                </Title>
                <Title>
                  {WalmartShipTo?.shippingInfo?.postalAddress?.address2}
                </Title>
                <AddressContainer>
                  <>
                    <span>
                      {WalmartShipTo?.shippingInfo?.postalAddress?.city}
                    </span>
                    {" , "}
                    <span>
                      {WalmartShipTo?.shippingInfo?.postalAddress?.state}
                    </span>
                    {" , "}
                    <span>
                      {WalmartShipTo?.shippingInfo?.postalAddress?.country}
                    </span>
                    {" , "}
                    <span>
                      {WalmartShipTo?.shippingInfo?.postalAddress?.postalCode}
                    </span>
                  </>
                  <span>{WalmartShipTo?.customerEmailId}</span>
                  {" , "}
                  <div>{WalmartShipTo?.shippingInfo?.phone}</div>
                </AddressContainer>
              </div>
            )}
            {AmazonShipTo && (
              <div style={{ fontSize: 14 }}>
                <CompanyName>{AmazonShipTo?.Name}</CompanyName>
                <Title>{AmazonShipTo?.company}</Title>
                <Title>{AmazonShipTo?.AddressLine1}</Title>
                <Title>{AmazonShipTo?.AddressLine2}</Title>
                <AddressContainer>
                  <>
                    <span>{AmazonShipTo?.City}</span>
                    <span>{AmazonShipTo?.StateOrRegion}</span>
                    <span>{AmazonShipTo?.CountryCode}</span>
                    <span>{AmazonShipTo?.PostalCode}</span>
                  </>

                  <span>{AmazonShipTo?.BuyerInfo?.BuyerEmail}</span>
                  <div>{AmazonShipTo?.Phone}</div>
                </AddressContainer>
              </div>
            )}
          </DefaultContainer>
        </RecipientInnerConatiner>

        {open ? (
          <></>
        ) : (
          <RecipientInnerConatiner>
            <AdressConatiner>
              <RecipientTitle>Return</RecipientTitle>
              <AdressConatiner style={{ gap: "10px" }}>
                <AdressTitle>Addresses...</AdressTitle>
                <PopupText>Edit Return</PopupText>
              </AdressConatiner>
            </AdressConatiner>
            <DefaultContainer>
              <CompanyName>BitsBay</CompanyName>
              <AddressContainer>
                1310 Nash lee dr,, New York Lane, Lilburn , GA 30047, United
                States nnashitfarooq0@gmail.com 9160499968
              </AddressContainer>
            </DefaultContainer>
          </RecipientInnerConatiner>
        )}
      </RecipientContainer>

      <Dialog open={openPopUp}>
        <PopUp
          onClose={handleClose}
          headingText="Edit Recipient"
          AmazonShipTo={AmazonShipTo}
          EbayShipTo={EbayShipTo}
          WalmartShipTo={WalmartShipTo}
        />
      </Dialog>
      <Dialog open={openPopUpSender}>
        <PopUp
          onClose={handleClose}
          headingText="Edit Sender Address"
          senderAddress={senderAddress}
          setSenderAddress={setSenderAddress}
        />
      </Dialog>
    </>
  );
};

export default RecipientCard;
