import React from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:"column"
      }}
    >
        <img src={"/assets/images/illustrations/page_not_found.jpg"}  alt=""/>
          <CustomButton
            label={"Go Back"}
            width={"120px"}
            onClick={() => navigate(-1)}
          />
    </div>
  );
};

export default PageNotFound;
