import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import * as pdfjs from "pdfjs-dist";
import { Download } from "@mui/icons-material";
import { Toast } from "../../../components/Toaster/Toaster";

const PdfViewer = ({ pdfContent, pdfType }) => {
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    if (
      pdfContent &&
      pdfContent.data &&
      pdfContent.data.length > 0 &&
      !pdfType
    ) {
      const uint8Array = new Uint8Array(pdfContent.data);
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      setPdfBlob(blob);
    } else {
      Toast.error("Invalid PDF content");
    }
  }, [pdfContent]);
  useEffect(() => {
    if (pdfContent && pdfType === "blob") {
      const binaryDataLabel = atob(pdfContent);
      const arrayBufferInvoice = new ArrayBuffer(binaryDataLabel.length);
      const uint8Array = new Uint8Array(arrayBufferInvoice);
      for (let i = 0; i < binaryDataLabel.length; i++) {
        uint8Array[i] = binaryDataLabel.charCodeAt(i);
      }
      const blob = new Blob([arrayBufferInvoice], { type: "application/pdf" });
      setPdfBlob(blob);
    }
  }, [pdfContent]);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(pdfBlob);
    a.download = "downloaded-pdf.pdf";
    a.click();
  };

  return (
    <div style={{ height: "700px" }}>
      {pdfBlob && (
        <div>
          <Download onClick={handleDownload} style={{ cursor: "pointer" }}>
            Download PDF
          </Download>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={URL.createObjectURL(pdfBlob)} />
          </Worker>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
