// orders url
export const GET_ORDERS = "orders";
export const POST_SYNC_ORDERS = "order/sync";
export const POST_BULK_DELETE_ORDER_ITEM = "order/delete";
export const GET_SHIP_WAREHOUSE = "amazon/synnex/availability";
// users related url
export const POST_USER_LOGIN = "user/login";
export const POST_USER_REGISTER = "user/register";
export const GET_MY_DETAILS = "user/me";
export const POST_DISCONNECT_STORE = "user/disconnect/store";
export const POST_CREATE_LABEL = "labels";
export const POST_ADMIN_CREATE_LABEL = "create/label";
export const POST_UPDATE_TRACKING_NUMBER = "order/tracking";
export const POST_UPDATE_ORDER_STATUS = "order/statusUpdate";
export const GET_ORDER_DETAILS = "order/detail";
export const POST_PACKAGE_SLIP = "package/slip/create";
export const GET_AMZ_RATES = "amazon/getRates";
export const GET_FEDEX_RATES = "fedex/getRates";
export const POST_BY_INGRAM_CREATE_ORDER = "ingram/createOrder";
export const POST_BY_SYNNEX_CREATE_ORDER = "synnex/submitPo";
export const POST_BY_WALMART_CREATE_ORDER = `walmart/order/create`;
export const POST_CREATE_PDF = "amazon/create/pdf";
export const ADD_BALANCE = "add-balance";
export const UPDATE_USER = "users/userId";
export const ADD_CUSTOMER = "create-customer";
export const UPDATE_BALANCE = "updateBalance";
export const PURCHASE_SHIPMENT = "amazon/purchase/shipment";
export const PUT_ITEM_SHIPMENT_CANCEL = "amazon/cancel/shipment";
export const DELEVERY_DAY_RATES = "get/delevery-day/rates";
export const AFIVE_RATES = "aFive/getRates";
export const AFIVE_SHIPMENT_CREATE = "aFive/shipment/create";
export const AFIVE_SHIPMENT_CANCEL = "aFive/shipment/cancel";
export const WALMART_RATES = "walmart/rates";
