import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MenuSideBarContext = createContext();

const useMenuSideBarContext = () => {
  return useContext(MenuSideBarContext);
};

const MenuSideBarContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const currentOrigin = window.location.pathname;
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (currentOrigin === "/dashboard") {
      setSelected("Orders");
      setChildSelected("");
      setSettingsOpen("");
    } else if (currentOrigin === "/setting/store") {
      setChildSelected("Store");
      setSettingsOpen("Settings");
      setSelected("");
    } else if (currentOrigin === "/setting/connect") {
      setSettingsOpen("Settings");
      setSelected("");
      setChildSelected("Company Info");
    } else if (currentOrigin === "/setting/payment") {
      setSettingsOpen("Settings");
      setSelected("");
      setChildSelected("Payment");
    }
  }, []);

  const [childSelected, setChildSelected] = useState("");
  const [settingsOpen, setSettingsOpen] = useState("");
  const handleItemsClick = (title, path) => {
    if (title !== "Settings") {
      setSelected(title);
      navigate(path);
      setChildSelected("");
      setSettingsOpen("");
    } else {
      setSettingsOpen(title);
    }
  };
  const handleChildClick = (title, path) => {
    setChildSelected(title);
    navigate(path);
  };

  return (
    <MenuSideBarContext.Provider
      value={{
        selected,
        childSelected,
        setChildSelected,
        handleItemsClick,
        handleChildClick,
        settingsOpen,
      }}
    >
      {children}
    </MenuSideBarContext.Provider>
  );
};

export { MenuSideBarContextProvider, useMenuSideBarContext };
