import React, { useState } from "react";
import {
  Box,
  Card,
  IconButton,
  InputLabel,
  FormControl,
  Grid,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTable from "../../components/Table/CustomTable";
import { useDashboardContext } from "../../contexts/DashboardContext/DashboardContext";
import { useAuth } from "../../contexts/AuthContext";
const ProductTable = (props) => {
  const {
    searchFinalValue,
    handleChange,
    totalPages,
    selectOrderStausValue,
    handleStatusChange,
    paginationValue,
    setPaginationValue,
  } = props;
  const {
    dateRangeType,
    setDateRangeType,
    filterOrderData,
    setFilterOrderData,
    stateDateMulti,
    setStateDateMulti,
    allOrders,
    selectedValue,
  } = useDashboardContext();
  const { user } = useAuth();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const handlePagesizeChange = (pgSize) => {
    let paramValue = {
      ...paginationValue,
      pageSize: pgSize,
    };
    setPaginationValue(paramValue);
  };
  const handlePageChange = (pgNo) => {
    let paramValue = {
      ...paginationValue,
      pageNumber: pgNo,
    };

    setPaginationValue(paramValue);
  };
  const handleTableSelectChange = (selectedOrderRows) => {
    let ids = [];

    selectedOrderRows.selectedRows.forEach((row) => {
      ids.push(row.purchaseOrderId);
    });
    setSelectedRowIds(ids);
  };
  const generateCustomTable = (selectedValue, columns) => (
    <CustomTable
      columns={columns}
      selectedValue={selectedValue}
      handleChange={handleChange}
      totalRecords={totalPages}
      handlePagesizeChange={handlePagesizeChange}
      handlePageChange={handlePageChange}
      searchFinalValue={searchFinalValue}
      selectOrderStausValue={selectOrderStausValue}
      handleStatusChange={handleStatusChange}
      dateRangeType={dateRangeType}
      setDateRangeType={setDateRangeType}
      filterOrderData={filterOrderData}
      setFilterOrderData={setFilterOrderData}
      stateDateMulti={stateDateMulti}
      setStateDateMulti={setStateDateMulti}
      handleTableSelectChange={handleTableSelectChange}
      selectableRows={true}
    />
  );
  return (
    <React.Fragment>
      <Card elevation={4} sx={{ mb: 3, borderRadius: "20px" }}>
        {selectedRowIds?.length > 0 ? (
          <Box
            sx={{
              pt: 2,
              pb: 2,
              pr: 2,
              backgroundColor: "#3d3bde",
              height: "100%",
            }}
          >
            <Grid container>
              <Grid
                item
                md={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  sx={{ width: "120px" }}
                  label={"Delete"}
                  variant="contained"
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid
            container
            // spacing={4}
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 2,
              pr: 2,
              pb: 2,
              pt: 2,
            }}
          >
            <Grid item lg={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  label="Search"
                  type={"text"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  defaultValue={searchFinalValue}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Box overflow="auto">{generateCustomTable(selectedValue)}</Box>
      </Card>
    </React.Fragment>
  );
};

export default ProductTable;
