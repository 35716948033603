import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./app/contexts/AuthContext.js";
import MatxLoading from "./app/components/MatxLoading.jsx";

import LogInPage from "./app/views/sessions/LogIn.js";
import Register from "./app/views/sessions/Register.js";
import PageNotFound from "./app/views/page-not-found/PageNotFound.jsx";
import Orders from "./app/views/dashboard/Orders.jsx";
import Product from "./app/views/Product/Product.jsx";
import OrderDetails from "./app/views/dashboard/OrderDetails";

const AppRouting = () => {
  const { userData, profileLoading } = useAuth();
  if (profileLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <MatxLoading />
      </div>
    );
  }
  return (
    <>
      <Routes>
        {!userData ? (
          <>
            {" "}
            <Route path="/*" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LogInPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<PageNotFound />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Orders />} />
            <Route path="/products" element={<Product />} />
            <Route
              path="/order/order-details/:orderType/:id"
              element={<OrderDetails />}
            />
            <Route path="*" element={<PageNotFound />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default AppRouting;
