import HomeIcon from "@mui/icons-material/Home";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
export const topBarHeight = 84;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

export const OPTIONS_NAVBAR = [
  {
    id: 0,
    label: "Home",
    value: "Home",
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: "Logout",
    value: "Logout",
    icon: <PowerSettingsNewIcon />,
  },
];
export const OPTIONS_STORE = [
  {
    id: 0,
    label: "Amazon",
    value: "amazon",
  },
  {
    id: 1,
    label: "Ebay",
    value: "ebay",
  },
  {
    id: 2,
    label: "Walmart",
    value: "walmart",
  },
];
export const amazon_Option = [
  {
    id: 0,
    label: "AS IT",
    value: "AS IT",
  },
  {
    id: 1,
    label: "TD Synnex",
    value: "TD Synnex",
  },
  {
    id: 2,
    label: "Ingram Micro",
    value: "Ingram Micro",
  },
  {
    id: 3,
    label: "Amazon",
    value: "Amazon",
  },
];
export const Walmart_Option = [
  {
    id: 0,
    label: "AS IT",
    value: "AS IT",
  },
  {
    id: 1,
    label: "TD Synnex",
    value: "TD Synnex",
  },
  {
    id: 2,
    label: "Ingram Micro",
    value: "Ingram Micro",
  },
  {
    id: 3,
    label: "Walmart",
    value: "Walmart",
  },
];
export const Ebay_Option = [
  {
    id: 0,
    label: "AS IT",
    value: "AS IT",
  },
  {
    id: 1,
    label: "TD Synnex",
    value: "TD Synnex",
  },
  {
    id: 2,
    label: "Ingram Micro",
    value: "Ingram Micro",
  },
  {
    id: 3,
    label: "Ebay",
    value: "Ebay",
  },
];
export const Po_Type = [
  {
    id: 0,
    label: "A Simple Po with only required data element",
    value: "Simple",
  },
  {
    id: 1,
    label: "Po Data",
    value: "Data",
  },
];
export const Order_Type_Value = [
  {
    id: 0,
    label: "No Inventory",
    value: "NoInventory",
  },
  {
    id: 1,
    label: "Shipping Address Undeliverable",
    value: "ShippingAddressUndeliverable",
  },
  {
    id: 2,
    label: "Customer Exchange",
    value: "CustomerExchange",
  },
  {
    id: 3,
    label: "Buyer Canceled",
    value: "BuyerCanceled",
  },
  {
    id: 4,
    label: "General Adjustment",
    value: "GeneralAdjustment",
  },
  {
    id: 5,
    label: "Carrier Credit Decision",
    value: "CarrierCreditDecision",
  },
  {
    id: 6,
    label: "Risk Assessment Information Not Valid",
    value: "RiskAssessmentInformationNotValid",
  },
  {
    id: 7,
    label: "Carrier Coverage Failure",
    value: "CarrierCoverageFailure",
  },
  {
    id: 8,
    label: "Customer Return",
    value: "CustomerReturn",
  },
  {
    id: 9,
    label: "Merchandise Not Received",
    value: "MerchandiseNotReceived",
  },
];
export const OPTIONS_STATUS = [
  {
    id: 0,
    label: "All",
    value: "",
  },
  {
    id: 1,
    label: "Unshipped",
    value: "Unshipped",
  },
  {
    id: 2,
    label: "Partially Ship",
    value: "Partially Ship",
  },
  {
    id: 3,
    label: "Shipped",
    value: "Shipped",
  },
  {
    id: 4,
    label: "Cancelled",
    value: "Cancelled",
  },
];
export const TIME_ZONE_OPTIONS = [
  {
    id: 1,
    label: "All Orders By Timezone",
    value: "all",
  },
  {
    id: 2,
    label: "Today",
    value: "today",
  },
  {
    id: 3,
    label: "This Week",
    value: "thisWeek",
  },
  {
    id: 4,
    label: "This Month",
    value: "thisMonth",
  },
  {
    id: 5,
    label: "Custom Range",
    value: "customDateRange",
  },
];

export const CARRIER_LOGOS = {
  Aramex: "Aramex.png",
  "Canada Post": "canadapost.png",
  Canpar: "Canpar.png",
  CPX: "cpx.png",
  "Day And Ross": "day_and_ross.png",
  DHL: "DHL.png",
  Eshipper: "eshipper.png",
  "eShipper Sameday": "eShipperSameday.png",
  "Federal Express": "federalexpress.png",
  FedEx: "FedEx.png",
  FlashBox: "FlashBox.png",
  "Fleet Optics": "fleetoptics.png",
  "Franchise Logo": "franchise-logo.svg",
  Intelcom: "Intelcom.png",
  P44: "p44.png",
  Project44: "project44.png",
  Purolator: "purolator.svg",
  PYK: "pyk.png",
  Rivo: "Rivo.svg",
  Sameday: "sameday.png",
  "SmartePost INTL": "smartepost.png",
  Texity: "texity.png",
  "Uni Uni": "uni-uni.png",
  UPS: "ups.png",
  USPS: "usps.png",
  "Amazon Shipping": "amazon.png",
  Synnex: "synnex.png",
  Ingram: "Ingram.png",
};
