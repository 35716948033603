// import { Toast } from 'app/components/Toaster/Toaster';
import axios from "axios";
/*
    Message from Backend team, Please read before committing changes.
    Note:
    Don't change API URL because it breaks during deployment from Backend code.
    If you want to connect with dev environment please uncomment line - 12
*/

// TODO: env not parsing
export const APIBaseURL =
  process.env.BACKEND_URL || "https://api.multichannel.a5sync.com";
  
  
// export const APIBaseURL = "http://localhost:8003";

// const currentHost = window.location;
// let APIBaseURL;

// if (currentHost?.hostname === 'localhost' || currentHost?.hostname === '192.168.100.116') {
//   APIBaseURL = API_URL;
// } else {
//   APIBaseURL = `${currentHost?.origin}`;
// }

export const BASE_URL = `${APIBaseURL}/api/v1/`;

const axiosApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const successHandler = (response) => {
  if (response?.data?.message) {
  }
  return response;
};

const errorHandler = (error) => {
  //   Toast.error(error?.response?.data?.errorMessage);
  // return Promise.reject(error?.response);

  //   if (error?.response?.status === 401) {
  //     localStorage.clear();
  //   }

  return error?.response;
};

const requestHandler = (config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers["Authorization"] = `${token}`;
  }
  return config;
};
axiosApi.interceptors.request.use(
  (config) => requestHandler(config),
  (error) => Promise.reject(error)
);

axiosApi.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

// Global API Call
export const makeAPICall = async (apiData, withHeaders = false) => {
  const {
    option: { method, url },
    ...rest
  } = apiData;

  return await axiosApi({
    method,
    url,
    ...rest,
    ...apiData.config,
  })
    .then((response) => {
      if (withHeaders) {
        return response;
      }
      return response.data;
    })
    .catch((error) => error.response);
};
