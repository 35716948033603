import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import {
  Box,
  Breadcrumbs,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { menu } from "../../menu";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../contexts/AuthContext";
import logo from "../../assets/images/logo-150x150 1.png";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useMenuSideBarContext } from "../contexts/MenuSideBarContext";
import SingleSelect from "./AppSelect/SingleSelect";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { OPTIONS_NAVBAR } from "../utils/constant";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
export default function MenuSidebar({ children }) {
  const [sideBarAnchor, setSideBardAnchor] = useState(false);

  const { userData } = useAuth();
  const {
    selected,
    childSelected,
    handleItemsClick,
    handleChildClick,
    settingsOpen,
  } = useMenuSideBarContext();

  const [anchor, setAnchor] = useState(null);
  const handleSideBarClose = () => {
    setSideBardAnchor(!sideBarAnchor);
  };
  const handleClose = () => {
    setAnchor(null);
  };
  const handleMenuItemClick = (value) => {
    if (value === "Home") {
      handleItemsClick("Orders", "/dashboard");
    } else {
      handleLogout();
    }
    handleClose();
  };
  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleLogout = () => {
    window.localStorage.clear("accessToken");
    window.location.reload();
  };
  const shadowStyleRight = {
    boxShadow: "5px 0 10px rgba(0, 0, 0, 0.07)",
  };
  const shadowStyleBottom = {
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
  };
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const iconMapping = {
    order: <ShoppingCartIcon />,
    dashboard: <HomeIcon />,
    setting: <SettingsIcon />,
  };
  return (
    <Grid style={{ height: "100vh" }} container>
      <Grid
        style={shadowStyleRight}
        item
        md={sideBarAnchor ? 0.5 : 2}
        position={"relative"}
      >
        <Grid
          position="absolute"
          sx={{
            mt: -6,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            onClick={(e) => handleItemsClick("Orders", "/dashboard")}
            sx={{
              mt: 8,
              ml: 2,
              cursor: "pointer",
              display: sideBarAnchor ? "none" : "flex",
              alignItem: "center",
            }}
            width={"100%"}
          >
            <img src={logo} width={50} alt="Logo" />
          </Box>
          <Box
            mr={sideBarAnchor ? 0.5 : 2}
            mt={sideBarAnchor ? 8 : 8}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: sideBarAnchor ? "center" : "end",
              alignItem: "center",
            }}
          >
            <IconButton
              onClick={() => {
                handleSideBarClose();
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Grid>

        <List
          sx={{ mt: 8 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {menu.map((list) => {
            const isSelected =
              selected === list.title &&
              !childSelected &&
              selected !== "Settings";
            return (
              <Grid>
                <ListItemButton
                  sx={{
                    borderRadius: 10,
                    ml: 1,
                    mr: 1,
                    mt: 1,
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: isSelected ? "#3D3BDE" : "",
                    color: isSelected ? "white" : "black",
                    "&:hover": {
                      backgroundColor: "#5D3EFF",
                      color: "white",
                    },
                  }}
                  onClick={(e) => handleItemsClick(list.title, list.path)}
                >
                  <ListItemIcon
                    sx={{
                      color: isSelected ? "#3D3BDE" : "",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      width={"22px"}
                      height={"22px"}
                      src={list.icon}
                      alt="Custom Icon"
                    />
                  </ListItemIcon>

                  <ListItemText
                    sx={{ display: sideBarAnchor && "none" }}
                    primary={list.title}
                  />
                </ListItemButton>
                <Collapse
                  in={settingsOpen === list.title}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {list.items !== undefined &&
                      list.items.map((item) => {
                        const isChildSelected = childSelected === item.title;
                        return (
                          <>
                            <ListItemButton
                              sx={{
                                borderRadius: 10,
                                mr: 1,
                                display: "flex",
                                justifyContent: "center",
                                pl: sideBarAnchor ? 2 : 6,
                                ml: 1,
                                mt: 1,
                                backgroundColor: isChildSelected
                                  ? "#3D3BDE"
                                  : "transparent",
                                color: isChildSelected ? "white" : "black",
                                "&:hover": {
                                  backgroundColor: "#3D3BDE",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                handleChildClick(item.title, item.path);
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  color: "gray",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.icon}
                              </ListItemIcon>
                              <ListItemText
                                sx={{ display: sideBarAnchor && "none" }}
                                primary={item.title}
                              />
                            </ListItemButton>
                          </>
                        );
                      })}
                  </List>
                </Collapse>
              </Grid>
            );
          })}
        </List>
        <Grid width={"100%"}>
          <ListItemButton
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            onClick={handleLogout}
          >
            <ListItemIcon
              sx={{
                paddingLeft: sideBarAnchor ? 0 : 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ display: sideBarAnchor && "none" }}
              primary={"Logout"}
            />
          </ListItemButton>
        </Grid>
      </Grid>

      <Grid item md={sideBarAnchor ? 11.5 : 10}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...shadowStyleBottom,
            pt: 2,
            pb: 1,
            pr: 2,
          }}
        >
          <Box pl={0}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{ margin: "20px" }}
            >
              {pathnames.map((name, index) => {
                const isLast = index === pathnames.length - 1;
                const capitalizedFirstLetter =
                  name.charAt(0).toUpperCase() + name.slice(1);

                return (
                  <Typography
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: isLast ? "700" : "500",
                    }}
                  >
                    {iconMapping[name] && (
                      <>
                        {iconMapping[name]}
                        <span style={{ margin: "0px 10px" }}> › </span>
                      </>
                    )}
                    <span style={{ margin: "0px 5px" }}>
                      {capitalizedFirstLetter}
                    </span>
                  </Typography>
                );
              })}
            </Breadcrumbs>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Chip size="small" label={`Credit :$ ${userData?.balance}`} />
            <SingleSelect
              title={userData?.username}
              options={OPTIONS_NAVBAR}
              variant={"outlined"}
              border="none"
              fontWeight={600}
              {...{
                anchor,
                handleMenuItemClick,
                handleClick,
                handleClose,
              }}
            />
          </Box>
        </Box>
        {children}
      </Grid>
    </Grid>
  );
}
