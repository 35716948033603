import { createContext, useContext, useState } from "react";
import {
  addBalanceClient,
  addCustomerDetails,
  getAllOrders,
  getOrderDetails,
  postAdminCreateLabel,
  postAmzRates,
  postBulkDeleteOrderItem,
  postByIngramCreateOrder,
  postCreateLabel,
  postCreatePdf,
  postFedExRates,
  postPurchaseShipment,
  postSyncOrders,
  postUpdateOrderStatus,
  postUpdateTackingNumber,
  updateBalace,
  itemShipmentCancel,
  postCreatePackageSlip,
  getDeleverDayRates,
  getAFiveRates,
  shipmentCreate,
  aFiveItemShipmentCancel,
  postBySynnexCreateOrder,
  getShipWarehouse,
  getWalmartRates,
  postByWalmartCreateOrder,
} from "../../backendHelpers/apiCallerHelper";
import { Toast } from "../../components/Toaster/Toaster";
import { useAuth } from "../AuthContext";
const DashboardContext = createContext();

const useDashboardContext = () => {
  return useContext(DashboardContext);
};

const DashboardContextProvider = ({ children }) => {
  const { getMyProfileApiCall } = useAuth();
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [pdfValue, setPdfValue] = useState();
  const [pdfType, setPdfType] = useState("");
  const [selectedValue, setSelectedValue] = useState("amazon");
  const [createLabelLoading, setCreateLabelLoading] = useState(false);
  const [orderDetailsLoading, setOrderDetailsLoading] = useState(true);
  const [amzAllOrderDetails, setAmzAllOrderDetails] = useState(null);
  const [ebayOrderDetails, setEbayOrderDetails] = useState(null);
  const [walmartOrderDetails, setWalmartOrderDetails] = useState(null);
  const [allOrders, setOrders] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [updatingTrackingLoading, setUpdatingTrackingLoading] = useState(false);
  const [ratesLoading, setRatesLoading] = useState(false);
  const [amzRates, setAmzRates] = useState(null);
  const [fedExRates, setFedExRates] = useState(null);
  const [shipWarehouse, setShipWarehouse] = useState(null);
  const [createPdfLoading, setCreatePdfLoading] = useState(false);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [deleveryDayRates, setDeleveryDayRates] = useState(false);
  const [walmartRates, setWalmartRates] = useState(false);
  const [aFiveRates, setAFiveRates] = useState(false);
  const [ingramResponse, setIngramResponse] = useState(null);

  const getAllOrdersApiCall = (orderType, params) => {
    setDashboardLoading(true);
    getAllOrders(params, orderType)
      .then((res) => {
        setOrders(res.orders);
        setTotalPages(res.totalOrders);
      })
      .catch((err) => {
        Toast.log(err);
      })
      .finally(() => {
        setDashboardLoading(false);
      });
  };
  const syncOrdersApiCall = async (userId, platform, getOrderListParams) => {
    let params = {
      userId: userId,
    };
    Toast.info("Syncing in progress...");
    postSyncOrders(params, platform)
      .then((res) => {
        if (res.insertedCount > 0) {
          getAllOrdersApiCall(selectedValue, getOrderListParams);
          Toast.success("Syncing done you can check orders now");
        } else {
          Toast.success("Order syncing is up to date");
        }
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setDashboardLoading(false);
      });
  };
  const bulkDeleteOrderItemApiCall = async (userId, platform, itemIds) => {
    setDashboardLoading(true);
    let data = {
      userId: userId,
      itemIds: itemIds,
      orderType: platform,
    };
    postBulkDeleteOrderItem(data, platform)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setDashboardLoading(false);
      });
  };
  const postCreateLabelApiCall = async (data, id, orderType, userId) => {
    Toast.info("Your label is being created...");
    postCreateLabel(data)
      .then(async (res) => {
        getOrderDetailsApiCall(id, orderType, userId);
        getMyProfileApiCall();
        Toast.success("Your label is created");
      })
      .catch((err) => {
        Toast.error(err?.response?.message);
      })
      .finally((err) => {
        setCreateLabelLoading(false);
      });
  };
  const postAdminCreateLabelApiCall = (data, id, orderType, userId) => {
    Toast.info("Your label is being created...");
    setCreateLabelLoading(true);
    postAdminCreateLabel(data)
      .then((res) => {
        if (res?.success === false) {
          Toast.error(res?.message);
        }
        getOrderDetailsApiCall(id, orderType);
        getMyProfileApiCall();
        Toast.success("Your label is created");
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setCreateLabelLoading(false);
      });
  };
  const resetOrderDetails = () => {
    setAmzAllOrderDetails(null);
    setWalmartOrderDetails(null);
    setEbayOrderDetails(null);
  };
  const getOrderDetailsApiCall = async (id, orderType, openPdf = false) => {
    setOrderDetailsLoading(true);
    resetOrderDetails();
    const params = {
      orderId: id,
    };
    getOrderDetails(params, orderType)
      .then((res) => {
        if (orderType === "amazon") {
          setAmzAllOrderDetails(res?.order);
          if (openPdf) {
            setDisplayPopup(true);
            setPdfValue(res.order.label);
          }
        } else if (orderType === "ebay") {
          setEbayOrderDetails(res?.order);
          if (openPdf) {
            setDisplayPopup(true);
            setPdfValue(res.order.label);
          }
        } else if (orderType === "walmart") {
          setWalmartOrderDetails(res?.order);
          if (openPdf) {
            setDisplayPopup(true);
            setPdfValue(res.order.label);
          }
        }
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setOrderDetailsLoading(false);
      });
  };
  const postCreatePackageSlipApiCall = async (id, shipmentId, orderType) => {
    setDashboardLoading(true);
    const data = {
      shipmentId,
      orderId: id,
    };
    postCreatePackageSlip(data)
      .then((res) => {
        getOrderDetailsApiCall(id, orderType);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setDashboardLoading(false);
      });
  };
  const postUpdateTackingNumberApiCall = (data, orderType) => {
    setUpdatingTrackingLoading(true);
    postUpdateTackingNumber(data, orderType)
      .then((res) => {
        getOrderDetailsApiCall(data.orderId, orderType, data.userId);
        getMyProfileApiCall();
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setUpdatingTrackingLoading(false);
      });
  };
  const postUpdateOrderStatusApiCall = (data, orderType) => {
    postUpdateOrderStatus(data, orderType)
      .then((res) => {
        getOrderDetailsApiCall(data.orderId, orderType, data.userId);
        getMyProfileApiCall();
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {});
  };
  const getAmzRatesApiCall = async (payload) => {
    setRatesLoading(true);
    postAmzRates(payload)
      .then((res) => {
        setAmzRates(res?.rates);
      })
      .catch((err) => {
        setAmzRates(null);
      })
      .finally(() => {
        setRatesLoading(false);
        setFedExRates(null);
      });
  };
  const getFedExRatesAPICALL = async (payload) => {
    setRatesLoading(true);
    postFedExRates(payload)
      .then((res) => {
        setFedExRates(res?.rates);
      })
      .catch((err) => {
        setFedExRates(null);
      })
      .finally(() => {
        setRatesLoading(false);
        setAmzRates(null);
      });
  };

  const postByIngramCreateOrderAPICALL = (
    id,
    payload,
    orderType,
    handleCloseModal
  ) => {
    // setOrderDetailsLoading(true);
    postByIngramCreateOrder(payload, orderType)
      .then((res) => {
        setIngramResponse(res);
        if (res && res?.order) {
          handleCloseModal();
          getOrderDetailsApiCall(id, orderType);
        }
      })
      .catch((err) => {
        setFedExRates(null);
      });
    // .finally(() => {
    //   setOrderDetailsLoading(false);
    // });
  };
  const postByWalmartCreateOrderAPICALL = async (payload, id, orderType) => {
    setOrderDetailsLoading(true);
    postByWalmartCreateOrder(payload)
      .then((res) => {
        getOrderDetailsApiCall(id, orderType);
      })
      .catch((err) => {
        setFedExRates(null);
      })
      .finally(() => {
        setOrderDetailsLoading(false);
      });
  };
  const postByShipWarehouse = async (payload) => {
    getShipWarehouse(payload)
      .then((res) => {
        setShipWarehouse(res);
        {
          res?.message && Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error(err);
      });
  };
  const postBySynnexCreateOrderCall = async (id, payload, orderType) => {
    setOrderDetailsLoading(true);
    postBySynnexCreateOrder(payload, orderType)
      .then((res) => {
        getOrderDetailsApiCall(id, orderType);
        {
          res?.message && Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error(err);
      })
      .finally(() => {
        setOrderDetailsLoading(false);
      });
  };

  const addBalancefunc = async (payload) => {
    let res = await addBalanceClient(payload);

    return res;
  };

  const addCustomer = async (payload) => {
    let res = await addCustomerDetails(payload);

    return res;
  };

  const updateUserBalance = async (payload) => {
    let res = await updateBalace(payload);
    return res;
  };
  const postPurchaseShipmentCallApi = async (payload, id, orderType) => {
    setCreateLabelLoading(true);
    postPurchaseShipment(payload)
      .then((res) => {
        Toast.success(res?.message);
        getOrderDetailsApiCall(id, orderType);
      })
      .catch((err) => {
        Toast.success(err?.error);
      })
      .finally(() => {
        setCreateLabelLoading(false);
      });
  };
  const postPurchaseUpsShipmentCallApi = async (id, orderType, payload) => {
    setCreateLabelLoading(true);
    shipmentCreate(payload)
      .then((res) => {
        Toast.success(res?.message);
        getOrderDetailsApiCall(id, orderType);
      })
      .catch((err) => {
        Toast.success(err?.error);
      })
      .finally(() => {
        setCreateLabelLoading(false);
      });
  };

  const getDeleverDayRatesApi = (data) => {
    setRatesLoading(true);
    getDeleverDayRates(data)
      .then((res) => {
        setDeleveryDayRates(res);
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setRatesLoading(false);
      });
  };
  const getWalmartRatesApi = (data) => {
    setRatesLoading(true);
    getWalmartRates(data)
      .then((res) => {
        setWalmartRates(res);
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setRatesLoading(false);
      });
  };
  const getAFiveRatesApi = (data) => {
    setRatesLoading(true);
    getAFiveRates(data)
      .then((res) => {
        setAFiveRates(res?.aFiveRates);
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setRatesLoading(false);
      });
  };
  const itemShipmentCancelCallApi = async (payload) => {
    setCreateLabelLoading(true);
    let res = await itemShipmentCancel(payload);
    setCreateLabelLoading(false);
    setAmzRates(null);
    return res;
  };
  const aFiveItemShipmentCancelCallApi = async (payload) => {
    setCreateLabelLoading(true);
    let res = await aFiveItemShipmentCancel(payload);
    setCreateLabelLoading(false);
    setAmzRates(null);
    return res;
  };
  const postCreatePdfApiCall = async (data, id, orderType) => {
    setCreatePdfLoading(true);
    const params = { id };
    const openPdf = true;
    postCreatePdf(params, data)
      .then((res) => {
        getOrderDetailsApiCall(id, orderType, openPdf);
      })
      .finally(() => {
        setCreatePdfLoading(false);
      });
  };
  return (
    <DashboardContext.Provider
      value={{
        getAmzRatesApiCall,
        displayPopup,
        setDisplayPopup,
        setAmzRates,
        amzRates,
        ratesLoading,
        getFedExRatesAPICALL,
        postByIngramCreateOrderAPICALL,
        fedExRates,
        getAllOrdersApiCall,
        allOrders,
        totalPages,
        syncOrdersApiCall,
        bulkDeleteOrderItemApiCall,
        pdfType,
        setPdfType,
        dashboardLoading,
        setPdfValue,
        pdfValue,
        postCreateLabelApiCall,
        postAdminCreateLabelApiCall,
        createLabelLoading,
        getOrderDetailsApiCall,
        setOrderDetailsLoading,
        orderDetailsLoading,
        amzAllOrderDetails,
        itemShipmentCancelCallApi,
        ebayOrderDetails,
        walmartOrderDetails,
        addBalancefunc,
        addCustomer,
        postUpdateTackingNumberApiCall,
        updatingTrackingLoading,
        postUpdateOrderStatusApiCall,
        selectedValue,
        setSelectedValue,
        resetOrderDetails,
        updateUserBalance,
        postCreatePdfApiCall,
        createPdfLoading,
        postPurchaseShipmentCallApi,
        postCreatePackageSlipApiCall,
        getDeleverDayRatesApi,
        getWalmartRatesApi,
        deleveryDayRates,
        walmartRates,
        getAFiveRatesApi,
        aFiveRates,
        postPurchaseUpsShipmentCallApi,
        aFiveItemShipmentCancelCallApi,
        postBySynnexCreateOrderCall,
        postByShipWarehouse,
        shipWarehouse,
        setShipWarehouse,
        postByWalmartCreateOrderAPICALL,
        ingramResponse,
        setIngramResponse,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContextProvider, useDashboardContext };
