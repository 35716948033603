import { Grid, Typography, styled, CircularProgress } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useDashboardContext } from "../../contexts/DashboardContext/DashboardContext";
import DashboardOrder from "./DashboardOrder";
import MenuSidebar from "../../components/MenuSideBar";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const Analytics = () => {
  const { user, profileLoading } = useAuth();
  const userId = user?.id;
  const { getAllOrdersApiCall, totalPages, selectedValue, setSelectedValue } =
    useDashboardContext();
  const [searchFinalValue, setSearchFinalValue] = useState("");
  const [selectOrderStausValue, setSelectOrderStausValue] = useState("");
  const [anchorStatus, setAnchorStatus] = useState(null);
  const [paginationValue, setPaginationValue] = useState({
    pageSize: 10,
    pageNumber: 1,
    orderStatus: "",
  });
  useEffect(() => {
    let params = {
      userId: userId,
      pageNumber: paginationValue?.pageNumber,
      pageSize: paginationValue?.pageSize,
      search: searchFinalValue,
      orderStatus: selectOrderStausValue,
    };
    getAllOrdersApiCall(selectedValue, params);
  }, [
    paginationValue,
    selectedValue,
    userId,
    searchFinalValue,
    selectOrderStausValue,
  ]);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleStatusChange = (event) => {
    setSelectOrderStausValue(event.target.value);
  };
  const handleStatusSelectClick = (event) => {
    setAnchorStatus(event.currentTarget);
  };
  const handleStatusSelectClose = () => {
    setAnchorStatus(null);
  };
  const handleStatusMenuItemClick = (value) => {
    setSelectOrderStausValue(value);
    handleStatusSelectClose();
  };
  return (
    <div style={{ display: "flex" }}>
      {/* <Navbar /> */}
      <MenuSidebar>
        {profileLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            <ContentBox className="analytics">
              <Typography variant="h6" marginBottom={"20px"}>
                Welcome {user?.username}
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <DashboardOrder
                    handleChange={handleChange}
                    searchFinalValue={searchFinalValue}
                    setSearchFinalValue={setSearchFinalValue}
                    paginationValue={paginationValue}
                    setPaginationValue={setPaginationValue}
                    totalPages={totalPages}
                    selectOrderStausValue={selectOrderStausValue}
                    handleStatusChange={handleStatusChange}
                    anchorStatus={anchorStatus}
                    handleStatusSelectClick={handleStatusSelectClick}
                    handleStatusSelectClose={handleStatusSelectClose}
                    handleStatusMenuItemClick={handleStatusMenuItemClick}
                  />
                </Grid>
              </Grid>
            </ContentBox>
          </Fragment>
        )}
      </MenuSidebar>
    </div>
  );
};

export default Analytics;
