import { makeAPICall } from "../../common";
import {
  ADD_BALANCE,
  ADD_CUSTOMER,
  GET_AMZ_RATES,
  GET_FEDEX_RATES,
  GET_MY_DETAILS,
  GET_ORDERS,
  GET_ORDER_DETAILS,
  POST_ADMIN_CREATE_LABEL,
  POST_BULK_DELETE_ORDER_ITEM,
  POST_BY_INGRAM_CREATE_ORDER,
  POST_BY_WALMART_CREATE_ORDER,
  POST_CREATE_LABEL,
  POST_CREATE_PDF,
  POST_DISCONNECT_STORE,
  POST_SYNC_ORDERS,
  POST_UPDATE_ORDER_STATUS,
  POST_UPDATE_TRACKING_NUMBER,
  POST_USER_LOGIN,
  POST_USER_REGISTER,
  PURCHASE_SHIPMENT,
  UPDATE_BALANCE,
  UPDATE_USER,
  PUT_ITEM_SHIPMENT_CANCEL,
  POST_PACKAGE_SLIP,
  DELEVERY_DAY_RATES,
  AFIVE_RATES,
  WALMART_RATES,
  AFIVE_SHIPMENT_CREATE,
  AFIVE_SHIPMENT_CANCEL,
  POST_BY_SYNNEX_CREATE_ORDER,
  GET_SHIP_WAREHOUSE,
} from "./urlHelper";

export const getAllOrders = (params, orderType) =>
  makeAPICall({
    option: { method: "get", url: `/${orderType}/${GET_ORDERS}` },
    params,
  });
export const postSyncOrders = (params, orderType) =>
  makeAPICall({
    option: { method: "post", url: `/${orderType}/${POST_SYNC_ORDERS}` },
    params,
  });
export const getShipWarehouse = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${GET_SHIP_WAREHOUSE}` },
    data,
  });
export const postBulkDeleteOrderItem = (data, orderType) =>
  makeAPICall({
    option: {
      method: "post",
      url: `/${orderType}/${POST_BULK_DELETE_ORDER_ITEM}`,
    },
    data,
  });
export const postUserLogin = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_USER_LOGIN}` },
    data,
  });
export const postUserRegister = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_USER_REGISTER}` },
    data,
  });
export const getMyDetails = (params) =>
  makeAPICall({
    option: { method: "get", url: `/${GET_MY_DETAILS}` },
    params,
  });
export const updateUserDetails = (data) =>
  makeAPICall({
    option: { method: "put", url: `/${UPDATE_USER}` },
    data,
  });

export const postDisconnectStore = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_DISCONNECT_STORE}` },
    data,
  });
export const postCreateLabel = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_CREATE_LABEL}` },
    data,
  });
export const postAdminCreateLabel = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_ADMIN_CREATE_LABEL}` },
    data,
  });
export const postPurchaseShipment = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${PURCHASE_SHIPMENT}` },
    data,
  });
export const postUpdateTackingNumber = (data, orderType) =>
  makeAPICall({
    option: {
      method: "post",
      url: `${orderType}/${POST_UPDATE_TRACKING_NUMBER}`,
    },
    data,
  });
export const postUpdateOrderStatus = (data, orderType) =>
  makeAPICall({
    option: {
      method: "post",
      url: `${orderType}/${POST_UPDATE_ORDER_STATUS}`,
    },
    data,
  });
export const getOrderDetails = (params, orderDetailType) =>
  makeAPICall({
    option: { method: "get", url: `/${orderDetailType}/${GET_ORDER_DETAILS}` },
    params,
  });
export const postCreatePackageSlip = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_PACKAGE_SLIP}` },
    data,
  });

export const postAmzRates = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${GET_AMZ_RATES}` },
    data,
  });
export const postFedExRates = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${GET_FEDEX_RATES}` },
    data,
  });

export const postByIngramCreateOrder = (data, orderType) =>
  makeAPICall({
    option: {
      method: "post",
      url: `/${orderType}/${POST_BY_INGRAM_CREATE_ORDER}`,
    },
    data,
  });
export const postByWalmartCreateOrder = (data) =>
  makeAPICall({
    option: {
      method: "post",
      url: `/${POST_BY_WALMART_CREATE_ORDER}`,
    },
    data,
  });
export const postBySynnexCreateOrder = (data, orderType) =>
  makeAPICall({
    option: {
      method: "post",
      url: `/${orderType}/${POST_BY_SYNNEX_CREATE_ORDER}`,
    },
    data,
  });
export const postCreatePdf = (params, data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_CREATE_PDF}` },
    params,
    data,
  });
export const addBalanceClient = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${ADD_BALANCE}` },
    data,
  });

export const addCustomerDetails = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${ADD_CUSTOMER}` },
    data,
  });
export const updateBalace = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${UPDATE_BALANCE}` },
    data,
  });
export const itemShipmentCancel = (data) =>
  makeAPICall({
    option: { method: "put", url: `/${PUT_ITEM_SHIPMENT_CANCEL}` },
    data,
  });
export const aFiveItemShipmentCancel = (data) =>
  makeAPICall({
    option: { method: "put", url: `/${AFIVE_SHIPMENT_CANCEL}` },
    data,
  });
export const getDeleverDayRates = (data) =>
  makeAPICall({
    option: { method: "POST", url: `/${DELEVERY_DAY_RATES}` },
    data,
  });
export const getWalmartRates = (data) =>
  makeAPICall({
    option: { method: "POST", url: `/${WALMART_RATES}` },
    data,
  });
export const getAFiveRates = (data) =>
  makeAPICall({
    option: { method: "POST", url: `/${AFIVE_RATES}` },
    data,
  });
export const shipmentCreate = (data) =>
  makeAPICall({
    option: { method: "POST", url: `/${AFIVE_SHIPMENT_CREATE}` },
    data,
  });
