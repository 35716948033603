export const menu = [
  {
    icon: require("../src/assets/images/orderIcon.png"),
    title: "Orders",
    path: "/dashboard",
    items: [],
  },
  {
    icon: require("../src/assets/images/productIcon.png"),
    title: "Products",
    path: "/products",
    items: [],
  },
];
