import { Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const SingleSelect = ({
  title,
  options,
  handleMenuItemClick,
  fontWeight,
  border,
  variant,
  textTransform,
  width,
  height,
  disabled,
}) => {
  const [anchor, setAnchor] = useState(null);
  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchor(null);
  };
  return (
    <div>
      <Button
        style={{
          border: border,
          borderColor: "#3d3bde",
          textTransform: textTransform,
          width: width,
          height: height,
          justifyContent: "space-between",
        }}
        onClick={handleClick}
        className="filter_text"
        disabled={disabled}
        variant={variant}
      >
        <Typography sx={{ fontWeight: fontWeight, color: "#3D3BDE" }}>
          {title}
        </Typography>
        <ArrowDropDownIcon style={{ color: "#3D3BDE" }} />
      </Button>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: menuStyle,
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option?.value}
            value={option?.value}
            onClick={() => {
              handleMenuItemClick(option?.value);
              handleClose();
            }}
            style={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <div style={{ marginBottom: -2 }}>{option.icon}</div>
            <Typography sx={{ fontSize: 15 }}>{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SingleSelect;
const menuStyle = {
  width: "200px",
  maxHeight: "500px",
};
