import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const ModalForm = styled("div")(() => ({
  marginTop: 10,
  width: "100%",
}));
const ModalTitle = styled("div")(() => ({
  marginTop: 10,
  marginBottom: 10,
  fontSize: 16,
  fontWeight: "700",
}));
const ModalFieldContainer = styled("div")(() => ({
  display: "flex",
  gap: "10px",
  width: "100%",
  marginTop: "5px",
}));

const ShipOption = [
  {
    label: "Bestway Ground--------------$15.07 (1 Days) Free at $1,000.00",
    value: "BWG",
  },
  {
    label: "FedEx Ground----------------$15.07 (1 Days) Free at $1,000.00",
    value: "FG",
  },
  {
    label: "FedEx Home Delivery---------$18.91 (1 Days) Free at $1,000.00",
    value: "FHD",
  },
  {
    label: "UPS Ground------------------$20.10 (1 Days) Free at $1,000.00",
    value: "UP",
  },
  {
    label: "UPS Mail Innov Flats less 1 lb$5.51 (7 Days)",
    value: "UMIF",
  },
  {
    label: "UPS 3 Day Select------------$20.76 (3 Days)",
    value: "U3",
  },
  {
    label: "FedEx Express Saver---------$21.60 (3 Days)",
    value: "FX ",
  },
  {
    label: "UPS Next Day Air------------$24.44 (1 Days)",
    value: "UR ",
  },
  {
    label: "FedEx 2 Day-----------------$24.69 (2 Days)",
    value: "F2 ",
  },
  {
    label: " UPS Next Day Air Saver------$25.06 (1 Days)",
    value: "URS ",
  },
  {
    label: "  FedEx Standard Overnight----$26.97 (1 Days)",
    value: "FO ",
  },
  {
    label: "FedEx Priority Overnight----$29.29 (1 Days)",
    value: "FP ",
  },
  {
    label: "UPS 2 Day Air---------------$30.13 (2 Days)",
    value: "UB ",
  },
  {
    label: " FedEx Saturday Delivery-----$51.78 (1 Days)",
    value: "FS ",
  },
  {
    label: "FedEx First Overnigh--------$85.18 (1 Days)",
    value: "FF ",
  },
];

const SynnexModel = ({
  isModalOpen,
  handleCloseModal,
  selectedLabel,
  handleSynnexSubmit,
  synnnexRegister,
  synnnexSetValue,
  selectItemValue,
  amzAllOrderDetails,
  ebayOrderDetails,
  walmartOrderDetails,
  postBySynnexCreateOrderCall,
  orderType,
  sLicense,
  setSLicense,
  eUser,
  setEUser,
  postByShipWarehouse,
  shipWarehouse,
  itemErrors,
  setItemErrors,
  itemSku,
  setItemSku,
  senderAddressValue,
  setSLicenseChecked,
  sLicenseChecked,
  setEUserChecked,
  eUserChecked,
  setEUserType,
  eUserType,
  endUserTypeOptions,
  id,
  specialOrder,
  setSpecialOrder,
}) => {
  const [itemQuantity, setItemQuantity] = useState([]);
  const [sellerSku, setSellerSku] = useState([]);

  const [shipMCode, setShipMCode] = useState("BWG");

  const [selectedValueOfValue, setSelectedValueOfValue] = useState();
  const [selectedValue, setSelectedValue] = useState(
    Array(selectItemValue.length).fill({ label: "any", value: "any" })
  );

  const [prices, setPrices] = useState("");

  const handleSLicenseChange = (e) => {
    const { name, value } = e.target;
    setSLicense((prev) => ({ ...prev, [name]: value }));
  };
  const handleSLCheckboxChange = (e) => {
    const { checked } = e.target;
    const value = checked ? "Y" : "N";
    setSLicenseChecked(checked);
    setSLicense((prev) => ({ ...prev, sLicenseReOrder: value }));
  };
  const handleSOSCheckboxChange = (e) => {
    const { checked } = e.target;
    const value = checked ? "Y" : "N";
    setSpecialOrder(checked);
  };
  const handleEUCheckboxChange = (e) => {
    const { checked } = e.target;
    const value = checked ? "Y" : "N";
    setEUserChecked(checked);
    setEUser((prev) => ({ ...prev, eUserReOrder: value }));
  };

  const handleEUserChange = (e) => {
    const { name, value } = e.target;
    setEUser((prev) => ({ ...prev, [name]: value }));
  };
  const handleEUserTypeChange = (event, value) => {
    setEUserType(value);
  };
  const updatePrice = (index, price) => {
    setPrices((prevPrices) => {
      const newPrices = [...prevPrices];
      newPrices[index] = price;
      return newPrices;
    });
  };
  const handleItemSkuChange = (index, value) => {
    const updatedSkus = [...itemSku];
    updatedSkus[index] = value;
    setItemSku(updatedSkus);
    setPrices("");
  };

  const handleItemQuantityChange = (index, value) => {
    const updatedQuantities = [...itemQuantity];
    updatedQuantities[index] = value.toString();
    setItemQuantity(updatedQuantities);
  };
  const handleShipWarehouseChange = (event, value, index) => {
    setSelectedValue((prevSelectedValue) => {
      const updatedSelectedValue = [...prevSelectedValue];
      updatedSelectedValue[index] = value || { label: "any", value: "any" };

      if (value?.value === "any") {
        const SynnexItemData = shipWarehouse?.availabilityStatus?.[index];
        const totalQty = SynnexItemData?.availableWarehouse?.reduce(
          (sum, warehouse) => sum + parseInt(warehouse.qty[0], 10),
          0
        );
        setSelectedValueOfValue(totalQty);
      } else {
        setSelectedValueOfValue(value?.value);
      }

      return updatedSelectedValue;
    });
  };
  const handleAutocompleteShipChange = (event, value) => {
    if (value) {
      setShipMCode(value.value);
    } else {
      setShipMCode("");
    }
  };
  const [priceTypeValue, setPriceTypeValue] = useState("");

  const options = [
    "Education SPA",
    "Federal Govt SPA",
    "State Govt SPA",
    "Vendor SPA",
    "Health Care SPA",
  ];

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const synxSubmit = async (data) => {
    const newErrors = Array.from({ length: selectItemValue.length }, () => ({
      quantity: "",
      sku: "",
      check: "",
    }));

    selectItemValue.forEach((item, index) => {
      if (
        itemQuantity?.[index] === "" ||
        parseInt(itemQuantity?.[index]) > selectedValueOfValue ||
        parseInt(itemQuantity?.[index]) <= 0
      ) {
        newErrors[index].quantity = `Invalid quantity.`;
      }
      if (itemQuantity?.[index] > selectedValueOfValue) {
        newErrors[
          index
        ].quantity = `Available quantity is ${selectedValueOfValue}`;
      }
      if (itemSku?.[index] === "") {
        newErrors[index].sku = `SKU is required`;
      } else if (itemSku?.[index] === "Discontinued") {
        newErrors[index].sku = `SKU is Discontinued`;
      } else if (itemSku?.[index] === "Not found") {
        newErrors[index].sku = `SKU is Not found`;
      }

      if (prices?.[index] === null || prices?.[index] === "") {
        newErrors[index].check = `Check availability`;
      }
    });

    if (!newErrors.some((error) => error.sku || error.quantity)) {
      const itemsData = selectItemValue.map((item, index) => ({
        itemId: item?.OrderItemId,
        shipQuantity: itemQuantity?.[index],
      }));

      const synnexItems = selectItemValue.map((item, index) => ({
        lineNumber: index + 1,
        sku: itemSku?.[index] || "",
        customerPartNumber: item?.OrderItemId,
        unitPrice: prices?.[index] || "",
        orderQuantity: itemQuantity?.[index].toString(),
        shipFromWarehouse: selectedValue?.[index]?.value || "any",
      }));

      const payload = {
        orderId: id,
        itemsInfo: itemsData,
        poNumber: data?.basicOrderDetailsPoNumber,
        shipMethodCode: shipMCode,
        shipTo: {
          addressName1: data?.shipToAddressName1,
          addressLine1: data?.shipToAddressLine1,
          city: data?.shipToCity,
          state: data?.shipToState,
          zipCode: data?.shipToZipCode,
          country: data?.shipToCountry,
          email: data?.shipToContactEmailAddress,
          phoneNumber: data?.shipToContactPhoneNumber,
          contactName: data?.shipToContactName,
        },
        softwareLicense: {
          sLicenseAuthNumber: sLicense?.sLicenseAuthNumber || "",
          sLicenseReOrder: sLicense?.sLicenseReOrder || "N",
          sLicenseAddress1: sLicense?.sLicenseAddress1 || "",
          sLicenseAddressLine1: sLicense?.sLicenseAddressLine1 || "",
          sLicenseCity: sLicense?.sLicenseCity || "",
          sLicenseState: sLicense?.sLicenseState || "",
          sLicenseZipCode: sLicense?.sLicenseZipCode || "",
          sLicenseCountry: sLicense?.sLicenseCountry || "",
          sLicenseContactName: sLicense?.sLicenseContactName || "",
          sLicensePhoneNumber: sLicense?.sLicensePhoneNumber || "",
          sLicenseFaxNumber: sLicense?.sLicenseFaxNumber || "",
          sLicenseEmailAddress: sLicense?.sLicenseEmailAddress || "",
        },
        endUser: {
          eUserPONumber: eUser?.eUserPONumber || "",
          eUserReOrder: eUser?.eUserReOrder || "N",
          eUserType: eUserType?.value,
          eUserAddressName1: eUser?.eUserAddressName1 || "",
          eUserAddressLine1: eUser?.eUserAddressLine1 || "",
          eUserCity: eUser?.eUserCity || "",
          eUserState: eUser?.eUserState || "",
          eUserZipCode: eUser?.eUserZipCode || "",
          eUserCountry: eUser?.eUserCountry || "",
          eUserContactName: eUser?.eUserContactName || "",
          eUserPhoneNumber: eUser?.eUserPhoneNumber || "",
          eUserFaxNumber: eUser?.eUserFaxNumber || "",
          eUserEmailAddress: eUser?.eUserEmailAddress || "",
        },
        specialOrderSystem: {
          specialPriceType: data?.specialPriceType,
          specialPriceRefNum: data?.specialPriceReferenceNumber,
        },
        synnexItems: synnexItems,
      };
      let hasEmptyPrice = false;
      newErrors.forEach((error, index) => {
        if (
          prices?.[index] === undefined ||
          prices?.[index] === null ||
          prices?.[index] === ""
        ) {
          hasEmptyPrice = true;
          error.check = `Check availability`;
        }
      });
      console.log(payload, "payload");
      if (!hasEmptyPrice) {
        await postBySynnexCreateOrderCall(id, payload, orderType);
        handleCloseModal();
      }
    }

    setItemErrors(newErrors);
  };

  const handleShipWarehouse = () => {
    let hasError = false;

    const newErrors = selectItemValue.map((item, index) => {
      let customerNumberError = "";
      let skuError = "";
      let quantityError = "";
      console.log(itemQuantity?.[index], "item 1");
      console.log(item.QuantityOrdered, "item 2");

      if (!item?.OrderItemId || item?.OrderItemId.trim() === "") {
        customerNumberError = "Customer Number is required";
        hasError = true;
      }

      if (!itemSku?.[index] || itemSku?.[index].trim() === "") {
        skuError = "SKU is required";
        hasError = true;
      }

      if (
        !itemQuantity?.[index] ||
        itemQuantity?.[index].toString().trim() === ""
      ) {
        quantityError = "Quantity is required";
        hasError = true;
      }
      if (itemQuantity?.[index] > item.QuantityOrdered) {
        quantityError = `Max Quantity is ${item.QuantityOrdered}`;
        hasError = true;
      }
      return {
        customerNumber: customerNumberError,
        sku: skuError,
        quantity: quantityError,
      };
    });

    if (!hasError) {
      const payload = selectItemValue.map((item, index) => ({
        sku: itemSku?.[index] || "",
        quantity: parseInt(itemQuantity?.[index]) || 0,
        shipFromWarehouse: selectedValue?.[index]?.value || "any",
        lineNumber: index + 1 || "",
      }));

      postByShipWarehouse(payload);
    }

    setItemErrors(newErrors);
  };
  useEffect(() => {
    selectItemValue.forEach((item, index) => {
      const SynnexItemData = shipWarehouse?.availabilityStatus?.[index];
      updatePrice(index, SynnexItemData?.price);
    });
  }, [selectItemValue, shipWarehouse]);

  useEffect(() => {
    const totalShipQuantity = selectItemValue.map((item) =>
      item && item.shipmentDetails
        ? item.shipmentDetails.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.quantity;
          }, 0)
        : 0
    );

    const defaultQuantities = selectItemValue.map(
      (item) =>
        (parseInt(item?.QuantityOrdered) || 0) -
        (totalShipQuantity[selectItemValue.indexOf(item)] || 0)
    );
    const defaultSellerSku = selectItemValue.map((item) => item?.SellerSKU);
    console.log(defaultSellerSku, "defaultSellerSku");
    setItemQuantity(defaultQuantities);
    setItemSku(defaultSellerSku);
  }, [selectItemValue]);

  useEffect(() => {
    if (amzAllOrderDetails) {
      const amzSynnexFormatNo = amzAllOrderDetails?.fullAddress?.ShippingAddress
        ?.Phone
        ? amzAllOrderDetails?.fullAddress?.ShippingAddress?.Phone?.replace(
            /[^\d]/g,
            ""
          )?.slice(0, -5) +
          " x" +
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Phone?.match(
            /\b\d{5}\b/
          )
        : "";

      synnnexSetValue(
        "basicOrderDetailsPoNumber",
        amzAllOrderDetails?._id || ""
      );
      synnnexSetValue(
        "shipToAddressName1",
        amzAllOrderDetails?.fullAddress?.ShippingAddress?.Name || ""
      );
      synnnexSetValue(
        "shipToAddressLine1",
        amzAllOrderDetails?.fullAddress?.ShippingAddress?.AddressLine1 || ""
      );
      synnnexSetValue(
        "shipToCity",
        amzAllOrderDetails?.fullAddress?.ShippingAddress?.City || ""
      );
      synnnexSetValue(
        "shipToState",
        amzAllOrderDetails?.fullAddress?.ShippingAddress?.StateOrRegion || ""
      );
      synnnexSetValue(
        "shipToCountry",
        amzAllOrderDetails?.fullAddress?.ShippingAddress?.CountryCode || ""
      );
      synnnexSetValue(
        "shipToContactNumber",
        amzSynnexFormatNo ? amzSynnexFormatNo : ""
      );
      synnnexSetValue(
        "shipToContactName",
        amzAllOrderDetails?.fullAddress?.ShippingAddress?.Name || ""
      );
      synnnexSetValue(
        "shipToContactPhoneNumber",
        amzSynnexFormatNo ? amzSynnexFormatNo : ""
      );
      synnnexSetValue(
        "shipToZipCode",
        amzAllOrderDetails?.fullAddress?.ShippingAddress?.PostalCode || ""
      );
      synnnexSetValue(
        "otherBillToAddressName1",
        amzAllOrderDetails?.DefaultShipFromLocationAddress?.Name || ""
      );
      synnnexSetValue(
        "otherBillToAddressLine1",
        amzAllOrderDetails?.DefaultShipFromLocationAddress?.AddressLine1 || ""
      );
      synnnexSetValue(
        "otherBillToAddressLine2",
        amzAllOrderDetails?.DefaultShipFromLocationAddress?.AddressLine3 || ""
      );
      synnnexSetValue(
        "otherBillToCity",
        amzAllOrderDetails?.DefaultShipFromLocationAddress?.City || ""
      );
      synnnexSetValue(
        "otherBillToCountry",
        amzAllOrderDetails?.DefaultShipFromLocationAddress?.CountryCode || ""
      );
      synnnexSetValue(
        "otherBillToZipCode",
        amzAllOrderDetails?.DefaultShipFromLocationAddress?.PostalCode || ""
      );
      synnnexSetValue(
        "otherBillToState",
        amzAllOrderDetails?.DefaultShipFromLocationAddress?.StateOrRegion || ""
      );
      synnnexSetValue(
        "basicOrderDetailsExpectedShipDate",
        amzAllOrderDetails?.EarliestShipDate || ""
      );
      synnnexSetValue("eUserPhoneNumber", amzSynnexFormatNo);

      if (amzAllOrderDetails && amzAllOrderDetails.orderItems) {
        amzAllOrderDetails.orderItems.forEach((item, index) => {
          synnnexSetValue(`itemsOrderQuantity`, item.QuantityOrdered || "");
          synnnexSetValue(`asin`, item.ASIN || "");
        });
      }
    } else if (ebayOrderDetails) {
      synnnexSetValue(
        "basicOrderDetailsCustomerNumber",
        ebayOrderDetails?._id || ""
      );
      synnnexSetValue("basicOrderDetailsPoNumber", ebayOrderDetails?._id || "");
      synnnexSetValue(
        "shipToAddressLine1",
        ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
          ?.shipTo?.contactAddress?.addressLine1 || ""
      );
      synnnexSetValue(
        "shipToCity",
        ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
          ?.shipTo?.contactAddress?.city || ""
      );
      synnnexSetValue(
        "shipToState",
        ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
          ?.shipTo?.contactAddress?.stateOrProvince || ""
      );
      synnnexSetValue(
        "shipToCountry",
        ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
          ?.shipTo?.contactAddress?.countryCode || ""
      );
      synnnexSetValue(
        "shipToZipCode",
        ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
          ?.shipTo?.contactAddress?.postalCode || ""
      );
      synnnexSetValue(
        "shipToContactEmailAddress",
        ebayOrderDetails?.fulfillmentStartInstructions?.[0]?.shippingStep
          ?.shipTo?.email || ""
      );
      synnnexSetValue(
        "basicOrderDetailsExpectedShipDate",
        ebayOrderDetails?.EarliestShipDate || ""
      );

      if (ebayOrderDetails && ebayOrderDetails.lineItems) {
        ebayOrderDetails.lineItems.forEach((item, index) => {
          synnnexSetValue(`itemsOrderQuantity`, item.quantity || "");
        });
      }
    } else if (walmartOrderDetails) {
      synnnexSetValue(
        "basicOrderDetailsCustomerNumber",
        walmartOrderDetails?._id || ""
      );
      synnnexSetValue(
        "basicOrderDetailsPoNumber",
        walmartOrderDetails?._id || ""
      );
      synnnexSetValue(
        "shipToAddressName1",
        walmartOrderDetails?.shippingInfo?.postalAddress?.name || ""
      );

      synnnexSetValue(
        "shipToAddressLine1",
        walmartOrderDetails?.shippingInfo?.postalAddress?.address1 || ""
      );
      synnnexSetValue(
        "shipToCity",
        walmartOrderDetails?.shippingInfo?.postalAddress?.city || ""
      );
      synnnexSetValue(
        "shipToState",
        walmartOrderDetails?.shippingInfo?.postalAddress?.state || ""
      );
      synnnexSetValue(
        "shipToCountry",
        walmartOrderDetails?.shippingInfo?.postalAddress?.country || ""
      );
      synnnexSetValue(
        "shipToZipCode",
        walmartOrderDetails?.shippingInfo?.postalAddress?.postalCode || ""
      );
      synnnexSetValue(
        "shipToContactNumber",
        walmartOrderDetails?.shippingInfo?.phone || ""
      );

      synnnexSetValue(
        "shipToContactName",
        walmartOrderDetails?.shippingInfo?.postalAddress?.name || ""
      );
      synnnexSetValue(
        "shipToContactPhoneNumber",
        walmartOrderDetails?.shippingInfo?.phone || ""
      );
      synnnexSetValue(
        "basicOrderDetailsExpectedShipDate",
        walmartOrderDetails?.EarliestShipDate || ""
      );

      if (walmartOrderDetails && walmartOrderDetails.orderLines.orderLine) {
        walmartOrderDetails.orderLines.orderLine.forEach((item, index) => {
          synnnexSetValue(
            `itemsOrderQuantity`,
            item.orderLineQuantity?.amount || ""
          );
        });
      }
    }
  }, [
    amzAllOrderDetails,
    ebayOrderDetails,
    walmartOrderDetails,
    synnnexSetValue,
  ]);

  // useEffect(() => {
  //   const setOrderDetailsValues = (orderDetails) => {
  //     if (!orderDetails) return;

  //     const {
  //       _id,
  //       fullAddress,
  //       DefaultShipFromLocationAddress,
  //       EarliestShipDate,
  //       orderItems,
  //       fulfillmentStartInstructions,
  //       shippingInfo,
  //       orderLines,
  //     } = orderDetails;

  //     synnnexSetValue("basicOrderDetailsPoNumber", _id || "");
  //     synnnexSetValue("basicOrderDetailsExpectedShipDate", EarliestShipDate || "");

  //     if (fullAddress && fullAddress.ShippingAddress) {
  //       const shippingAddress = fullAddress.ShippingAddress;
  //       synnnexSetValue("shipToAddressName1", shippingAddress.Name || "");
  //       synnnexSetValue("shipToAddressLine1", shippingAddress.AddressLine1 || "");
  //       synnnexSetValue("shipToCity", shippingAddress.City || "");
  //       synnnexSetValue("shipToState", shippingAddress.StateOrRegion || "");
  //       synnnexSetValue("shipToCountry", shippingAddress.CountryCode || "");
  //       synnnexSetValue("shipToContactNumber", (shippingAddress.Phone || "").replace(/\+|ext.*$/g, "").trim() || "");
  //       synnnexSetValue("shipToContactPhoneNumber", (shippingAddress.Phone || "").replace(/\+|ext.*$/g, "").trim() || "");
  //       synnnexSetValue("shipToZipCode", shippingAddress.PostalCode || "");
  //     }

  //     if (DefaultShipFromLocationAddress) {
  //       const defaultAddress = DefaultShipFromLocationAddress;
  //       synnnexSetValue("otherBillToAddressName1", defaultAddress.Name || "");
  //       synnnexSetValue("otherBillToAddressLine1", defaultAddress.AddressLine1 || "");
  //       synnnexSetValue("otherBillToAddressLine2", defaultAddress.AddressLine3 || "");
  //       synnnexSetValue("otherBillToCity", defaultAddress.City || "");
  //       synnnexSetValue("otherBillToCountry", defaultAddress.CountryCode || "");
  //       synnnexSetValue("otherBillToZipCode", defaultAddress.PostalCode || "");
  //       synnnexSetValue("otherBillToState", defaultAddress.StateOrRegion || "");
  //     }

  //     if (orderItems || fulfillmentStartInstructions || orderLines) {
  //       const items = orderItems || fulfillmentStartInstructions || orderLines.orderLine;
  //       items.forEach((item, index) => {
  //         synnnexSetValue(`itemsOrderQuantity`, item.QuantityOrdered || item.quantity || item.orderLineQuantity?.amount || "");
  //         synnnexSetValue(`asin`, item.ASIN || "");
  //       });
  //     }
  //   };

  //   setOrderDetailsValues(amzAllOrderDetails || ebayOrderDetails || walmartOrderDetails);
  // }, [amzAllOrderDetails, ebayOrderDetails, walmartOrderDetails, synnnexSetValue]);

  useEffect(() => {
    if (amzAllOrderDetails) {
      const dataEUser = amzAllOrderDetails?.fullAddress?.ShippingAddress;
      const defaultSLicense = {
        sLicenseAddress1: senderAddressValue?.name,
        sLicenseAddressLine1: senderAddressValue?.address1,
        sLicenseCity: senderAddressValue?.city,
        sLicenseState: senderAddressValue?.state,
        sLicenseZipCode: senderAddressValue?.zip,
        sLicenseCountry: senderAddressValue?.country,
        sLicenseContactName: senderAddressValue?.company,
        sLicensePhoneNumber: senderAddressValue?.phone,
        sLicenseEmailAddress: senderAddressValue?.email,
      };
      const defaultEUser = {
        eUserAddressName1: dataEUser?.Name,
        eUserAddressLine1: dataEUser?.AddressLine1,
        eUserCity: dataEUser?.City,
        eUserState: dataEUser?.StateOrRegion,
        eUserZipCode: dataEUser?.PostalCode,
        eUserCountry: dataEUser?.CountryCode,
        eUserContactName: dataEUser?.company,
        eUserPhoneNumber: dataEUser?.Phone,
        eUserEmailAddress: dataEUser?.BuyerInfo?.BuyerEmail,
      };
      setSLicense(defaultSLicense);
      setEUser(defaultEUser);
    } else if (walmartOrderDetails) {
      const dataEUser = walmartOrderDetails;
      const defaultSLicense = {
        sLicenseAddress1: senderAddressValue?.name,
        sLicenseAddressLine1: senderAddressValue?.address1,
        sLicenseCity: senderAddressValue?.city,
        sLicenseState: senderAddressValue?.state,
        sLicenseZipCode: senderAddressValue?.zip,
        sLicenseCountry: senderAddressValue?.country,
        sLicenseContactName: senderAddressValue?.company,
        sLicensePhoneNumber: senderAddressValue?.phone,
        sLicenseEmailAddress: senderAddressValue?.email,
      };
      const defaultEUser = {
        eUserAddressName1: dataEUser?.shippingInfo?.postalAddress?.name,
        eUserAddressLine1: dataEUser?.shippingInfo?.postalAddress?.address1,
        eUserCity: dataEUser?.shippingInfo?.postalAddress?.city,
        eUserState: dataEUser?.shippingInfo?.postalAddress?.state,
        eUserZipCode: dataEUser?.shippingInfo?.postalAddress?.postalCode,
        eUserCountry: dataEUser?.shippingInfo?.postalAddress?.country,
        eUserContactName: dataEUser?.shippingInfo?.postalAddress?.company,
        eUserPhoneNumber: dataEUser?.shippingInfo?.phone,
        eUserEmailAddress: dataEUser?.customerEmailId,
      };
      setSLicense(defaultSLicense);
      setEUser(defaultEUser);
    }
  }, [amzAllOrderDetails]);

  return (
    <div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "1000px",
            height: "80%",
            bgcolor: "background.paper",
            p: 2,
            overflowY: "auto",
            overflowX: "hidden",
            border: "none",
          }}
        >
          <form onSubmit={handleSynnexSubmit(synxSubmit)}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 15,
              }}
            >
              <div style={{ fontSize: 22, fontWeight: "bold" }}>
                {selectedLabel}
              </div>
              <div></div>
              <div
                style={{
                  borderRadius: 20,
                  border: "1px #d0d5dd solid",
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                }}
                onClick={handleCloseModal}
              >
                <img
                  style={{ padding: 3 }}
                  src="/assets/images/icon/close.svg"
                  alt=""
                />
              </div>
            </div>

            <ModalForm>
              <ModalTitle>Basic Order Details</ModalTitle>
              <ModalFieldContainer>
                <div>
                  <div>Po Number</div>
                  <TextField
                    disabled
                    size="small"
                    name="basicOrderDetailsPoNumber"
                    {...synnnexRegister("basicOrderDetailsPoNumber")}
                    style={{ width: "485px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </ModalFieldContainer>
              <ModalTitle>Shipment Details</ModalTitle>
              <ModalFieldContainer>
                <div>
                  <div>Ship Method</div>

                  <Autocomplete
                    size="small"
                    fullWidth
                    defaultValue={ShipOption?.[0]}
                    options={ShipOption}
                    onChange={(event, value) =>
                      handleAutocompleteShipChange(event, value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="shipmentDetailsShipMethod"
                        style={{ width: "485px" }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </div>
              </ModalFieldContainer>
              {/* <ModalFieldContainer>
                  <div style={{ display: "flex" }}>
                    <Checkbox {...label} />
                    <div
                      style={{
                        marginTop: 9,
                      }}
                    >
                      Drop Ship Flag
                    </div>
                  </div>
                </ModalFieldContainer> */}
              <ModalTitle>Ship To</ModalTitle>
              <ModalFieldContainer>
                <div>
                  <div>Address Name 1</div>
                  <TextField
                    size="small"
                    fullWidth
                    name="shipToAddressName1"
                    style={{ width: "485px" }}
                    {...synnnexRegister("shipToAddressName1")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <div>Address Line 1</div>
                  <TextField
                    size="small"
                    fullWidth
                    name="shipToAddressLine1"
                    style={{ width: "485px" }}
                    {...synnnexRegister("shipToAddressLine1")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </ModalFieldContainer>
              <ModalFieldContainer>
                <div>
                  <div>City</div>
                  <TextField
                    size="small"
                    name="shipToCity"
                    style={{ width: "485px" }}
                    {...synnnexRegister("shipToCity")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <div>State</div>
                  <TextField
                    size="small"
                    name="shipToState"
                    style={{ width: "485px" }}
                    {...synnnexRegister("shipToState")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </ModalFieldContainer>
              <ModalFieldContainer>
                <div>
                  <div>Zip Code</div>
                  <TextField
                    size="small"
                    name="shipToZipCode"
                    {...synnnexRegister("shipToZipCode")}
                    style={{ width: "485px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <div>Country</div>
                  <TextField
                    size="small"
                    name="shipToCountry"
                    {...synnnexRegister("shipToCountry")}
                    style={{ width: "485px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </ModalFieldContainer>
              <ModalTitle>Ship To Contact</ModalTitle>
              <ModalFieldContainer>
                <div style={{ width: "100%" }}>
                  <div>Contact Name</div>
                  <TextField
                    // defaultValue={formValues.email}
                    size="small"
                    name="shipToContactName"
                    fullWidth
                    {...synnnexRegister("shipToContactName")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div>Phone Number</div>
                  <TextField
                    size="small"
                    name="shipToContactPhoneNumber"
                    fullWidth
                    {...synnnexRegister("shipToContactPhoneNumber")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div>Email Address</div>
                  <TextField
                    fullWidth
                    // defaultValue={formValues.email}
                    size="small"
                    name="shipToContactEmailAddress"
                    {...synnnexRegister("shipToContactEmailAddress")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </ModalFieldContainer>
              <ModalFieldContainer>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={sLicenseChecked}
                    onChange={handleSLCheckboxChange}
                  />
                  <div
                    style={{
                      marginTop: 9,
                    }}
                  >
                    Software License
                  </div>
                </div>
              </ModalFieldContainer>
              {sLicenseChecked === true ? (
                <>
                  <ModalTitle>Software License</ModalTitle>
                  <ModalFieldContainer>
                    <div>
                      <div>Auth Number</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseAuthNumber"
                        value={sLicense?.sLicenseAuthNumber}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalTitle>Software License Address</ModalTitle>
                  <ModalFieldContainer>
                    <div>
                      <div>Address Name 1</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseAddress1"
                        value={sLicense?.sLicenseAddress1}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Address Line 1</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseAddressLine1"
                        value={sLicense?.sLicenseAddressLine1}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalFieldContainer>
                    <div>
                      <div>City</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseCity"
                        value={sLicense?.sLicenseCity}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>State</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseState"
                        value={sLicense?.sLicenseState}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalFieldContainer>
                    <div>
                      <div>Zip Code</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseZipCode"
                        value={sLicense?.sLicenseZipCode}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Country</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseCountry"
                        value={sLicense?.sLicenseCountry}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalTitle>Software License Contact</ModalTitle>
                  <ModalFieldContainer>
                    <div>
                      <div>Contact Name</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseContactName"
                        value={sLicense?.sLicenseContactName}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Phone Number</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicensePhoneNumber"
                        value={sLicense?.sLicensePhoneNumber}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalFieldContainer>
                    <div>
                      <div>Fax Number</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseFaxNumber"
                        value={sLicense?.sLicenseFaxNumber}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Email Address</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="sLicenseEmailAddress"
                        value={sLicense?.sLicenseEmailAddress}
                        onChange={handleSLicenseChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                </>
              ) : (
                <></>
              )}

              <ModalFieldContainer>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={eUserChecked}
                    onChange={handleEUCheckboxChange}
                  />
                  <div
                    style={{
                      marginTop: 9,
                    }}
                  >
                    End User
                  </div>
                </div>
              </ModalFieldContainer>
              {eUserChecked === true && (
                <>
                  <ModalTitle>End User</ModalTitle>
                  <ModalFieldContainer>
                    <div>
                      <div>PO Number</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserPONumber"
                        value={eUser?.eUserPONumber}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Type</div>
                      <Autocomplete
                        size="small"
                        fullWidth
                        value={eUserType}
                        options={endUserTypeOptions}
                        onChange={handleEUserTypeChange}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="eUserType"
                            style={{ width: "485px" }}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalTitle>End User Address</ModalTitle>
                  <ModalFieldContainer>
                    <div>
                      <div>Address Name 1</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserAddressName1"
                        value={eUser?.eUserAddressName1}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Address Line 1</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserAddressLine1"
                        value={eUser?.eUserAddressLine1}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalFieldContainer>
                    <div>
                      <div>City</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserCity"
                        value={eUser?.eUserCity}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>State</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserState"
                        value={eUser?.eUserState}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalFieldContainer>
                    <div>
                      <div>Zip Code</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserZipCode"
                        value={eUser?.eUserZipCode}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Country</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserCountry"
                        value={eUser?.eUserCountry}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalTitle>End User Contact</ModalTitle>
                  <ModalFieldContainer>
                    <div>
                      <div>Contact Name</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserContactName"
                        value={
                          eUser?.eUserContactName
                            ?.replace(/\+|ext.*$/g, "")
                            .trim() || ""
                        }
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Phone Number</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserPhoneNumber"
                        {...synnnexRegister("eUserPhoneNumber")}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalFieldContainer>
                    <div>
                      <div>Fax Number</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserFaxNumber"
                        value={eUser?.eUserFaxNumber}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <div>Email Address</div>
                      <TextField
                        size="small"
                        fullWidth
                        name="eUserEmailAddress"
                        value={eUser?.eUserEmailAddress}
                        onChange={handleEUserChange}
                        style={{ width: "485px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                  <ModalTitle>Additional Order Details</ModalTitle>
                  <ModalFieldContainer>
                    <div>
                      <div>Comment</div>
                      <TextField
                        // defaultValue={formValues.phone}
                        size="small"
                        name="additionalOrderDetailsComment"
                        style={{ width: "485px" }}
                        {...synnnexRegister("additionalOrderDetailsComment")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                </>
              )}
              <ModalFieldContainer>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={specialOrder}
                    onChange={handleSOSCheckboxChange}
                  />
                  <div
                    style={{
                      marginTop: 9,
                    }}
                  >
                    Special Order System
                  </div>
                </div>
              </ModalFieldContainer>
              {specialOrder === true && (
                <>
                  <ModalTitle>Special Order System</ModalTitle>
                  <ModalFieldContainer>
                    <div>
                      <div>Special Price Type</div>
                      <Autocomplete
                        size="small"
                        fullWidth
                        value={priceTypeValue}
                        onChange={(event, newValue) => {
                          setPriceTypeValue(newValue);
                        }}
                        options={options}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="specialPriceType"
                            style={{ width: "485px" }}
                            {...synnnexRegister("specialPriceType")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <div>Special Price Reference Number</div>
                      <TextField
                        size="small"
                        name="specialPriceReferenceNumber"
                        style={{ width: "485px" }}
                        {...synnnexRegister("specialPriceReferenceNumber")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </ModalFieldContainer>
                </>
              )}
              {/* <ModalFieldContainer>
                  <div>
                    <div>Synnex B2B Assigned ID</div>
                    <TextField
                      // defaultValue={formValues.phone}
                      size="small"
                      name="specialPriceSynnexAssignedID"
                      style={{ width: "485px" }}
                      {...synnnexRegister("specialPriceSynnexAssignedID")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </ModalFieldContainer> */}
              <ModalTitle>Items</ModalTitle>
              {selectItemValue.length > 0 && (
                <>
                  {selectItemValue.map((item, index) => {
                    const SynnexItemData =
                      shipWarehouse?.availabilityStatus?.[index];
                    const orderId = item?.OrderItemId || "";
                    const options = SynnexItemData?.availableWarehouse
                      ? [
                          ...SynnexItemData.availableWarehouse.map(
                            (warehouse) => ({
                              label: `${warehouse.warehouseInfo[0].city[0]}, ${warehouse.qty[0]}`,
                              value: warehouse.qty[0],
                            })
                          ),
                          { label: "any", value: "any" },
                        ]
                      : [{ label: "any", value: "any" }];
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                          // backgroundColor:"red"
                        }}
                      >
                        <div style={{ height: 80 }}>
                          <div>Item Line Number *</div>
                          <TextField
                            size="small"
                            value={index + 1}
                            style={{ width: "238px" }}
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <div style={{ color: "red" }}>
                            {itemErrors?.[index]?.customerNumber && (
                              <div style={{ color: "red" }}>
                                {itemErrors?.[index]?.customerNumber}
                              </div>
                            )}
                          </div>
                        </div>
                        <div style={{ height: 80 }}>
                          <div>Sku*</div>
                          <TextField
                            size="small"
                            name={`itemSku${index}`}
                            value={itemSku?.[index] || ""}
                            onChange={(e) =>
                              handleItemSkuChange(index, e.target.value)
                            }
                            style={{ width: "238px" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <div style={{ color: "red" }}>
                            {itemErrors?.[index]?.sku && (
                              <div style={{ color: "red" }}>
                                {itemErrors?.[index]?.sku}
                              </div>
                            )}
                            {SynnexItemData?.status === "Discontinued" && (
                              <div style={{ color: "red" }}>Discontinued</div>
                            )}
                            {SynnexItemData?.status === "Not found" && (
                              <div style={{ color: "red" }}>Not found</div>
                            )}
                          </div>
                        </div>
                        <div style={{ height: 80 }}>
                          <div>Order Quantity*</div>
                          <TextField
                            size="small"
                            defaultValue={item?.[index]?.QuantityOrdered}
                            type="number"
                            style={{ width: "238px" }}
                            value={itemQuantity?.[index] || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              const maxAllowed = itemQuantity?.[index];
                              console.log(newValue, "newValue");
                              console.log(maxAllowed, "maxAllowed");
                              handleItemQuantityChange(
                                index,
                                newValue,
                                maxAllowed
                              );
                            }}
                            name={`itemsOrderQuantity${index}`}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ min: 0 }}
                          />
                          <div style={{ color: "red" }}>
                            {itemErrors?.[index]?.quantity && (
                              <div style={{ color: "red" }}>
                                {itemErrors?.[index]?.quantity}
                              </div>
                            )}
                            {SynnexItemData?.quantityMessage && (
                              <div style={{ color: "red" }}>
                                {SynnexItemData?.quantityMessage}
                              </div>
                            )}
                          </div>
                        </div>
                        <div style={{ marginTop: "-20px" }}>
                          <div>Ship From Warehouse</div>
                          <Autocomplete
                            key={index}
                            size="small"
                            fullWidth
                            options={options}
                            value={selectedValue[index]}
                            onChange={(event, value) =>
                              handleShipWarehouseChange(event, value, index)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={selectedValue[index]?.label || ""}
                                name="shipmentDetailsShipFromWarehouse"
                                style={{ width: "238px" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div style={{ color: "red" }}>
                    {shipWarehouse?.errorMessage}
                  </div>
                  <div style={{ height: 80 }}>
                    <Button
                      onClick={handleShipWarehouse}
                      sx={{
                        color: "white",
                        marginTop: 3,
                        backgroundColor: "#3D3BDE",
                        "&:hover": {
                          backgroundColor: "#423dff",
                        },
                      }}
                    >
                      Check Availability
                    </Button>
                    {Array.isArray(itemErrors) &&
                      itemErrors.map(
                        (error, index) =>
                          error.check && (
                            <div style={{ color: "red" }} key={index}>
                              {error.check}
                            </div>
                          )
                      )}
                  </div>
                </>
              )}
            </ModalForm>

            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  color: "black",
                  border: "0.5px solid black",
                  padding: "auto 15px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  color: "white",
                  backgroundColor: "#3D3BDE",
                  "&:hover": {
                    backgroundColor: "#423dff",
                  },
                }}
              >
                Create Order
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default SynnexModel;
