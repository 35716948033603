import React, { useState } from "react";
import styled from "styled-components";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Toast } from "../../../components/Toaster/Toaster";

const PopUpContainer = styled("div")(({ theme }) => ({
  width: "500px",
  padding: "15px",
}));

const PopUpForm = styled("div")(() => ({
  marginTop: 10,
}));

const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const getUSStates = () => usStates;

const PopUp = ({
  onClose,
  headingText,
  address,
  EbayShipTo,
  WalmartShipTo,
  AmazonShipTo,
  senderAddress,
  setSenderAddress,
}) => {
  const [formValues, setFormValues] = useState({
    name: senderAddress?.name,
    company: senderAddress?.company,
    email: senderAddress?.email,
    phone: senderAddress?.phone,
    country: "US",
    street1: senderAddress?.street1,
    streetLine2: "",
    city: senderAddress?.city,
    state: senderAddress?.state,
    zip: senderAddress?.zip,
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    country: false,
    street1: false,
    city: false,
    state: false,
    zip: false,
  });

  const handleApply = () => {
    const newErrors = {};
    let hasError = false;

    for (const key in formValues) {
      if (
        formValues[key] === "" &&
        key !== "addressType" &&
        key !== "company" &&
        key !== "streetLine2" &&
        key !== "state"
      ) {
        newErrors[key] = true;
        hasError = true;
      } else {
        newErrors[key] = false;
      }
    }

    if (hasError) {
      setErrors({ ...newErrors });
    } else {
      Toast.log(formValues, "Form Values in:");
      setSenderAddress(formValues);
      onClose();
    }
    Toast.log(formValues, "Form Values:");
  };

  const handleChange = (event) => {
    const { name, value } = event.target || event;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === "country") {
      const isUnitedStates = value === "United States";
      const stateError = isUnitedStates && !formValues.state;

      setErrors((prevErrors) => ({
        ...prevErrors,
        state: stateError,
        country: value === "",
      }));
    } else if (name === "state") {
      const isUnitedStates = formValues.country === "United States";
      const stateError = value === "" && isUnitedStates;
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value === "",
        state: stateError,
      }));
    } else if (name === "email") {
      const isEmailValid = value.includes("@");
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value === "" || !isEmailValid,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value === "",
      }));
    }
  };

  const isUnitedStates = formValues.country === "United States";

  const handleStateChange = (event, value) => {
    const updatedState = value || "";

    setFormValues((prevValues) => ({
      ...prevValues,
      state: updatedState,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      state: updatedState === "" && isUnitedStates,
    }));
  };

  return (
    <>
      <PopUpContainer>
        <div style={{ fontSize: "20px", fontWeight: "600" }}>{headingText}</div>
        <PopUpForm>
          <div>
            <div>Name*</div>
            <TextField
              defaultValue={formValues?.name}
              size="small"
              fullWidth
              name="name"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.name}
              helperText={errors.name ? "name is a required field." : ""}
            />
          </div>
          <div style={{ marginTop: "5px" }}>
            <div>Company</div>
            <TextField
              defaultValue={formValues?.company}
              size="small"
              fullWidth
              name="company"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              marginTop: "5px",
            }}
          >
            <div style={{ width: "100%" }}>
              <div>Email</div>
              <TextField
                defaultValue={formValues?.email}
                size="small"
                style={{ width: "100%" }}
                name="email"
                type="email"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.email}
                helperText={errors.email ? "Email is a required field." : ""}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div>Phone</div>
              <TextField
                defaultValue={formValues?.phone}
                size="small"
                style={{ width: "100%" }}
                name="phone"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.phone}
                helperText={errors.phone ? "Phone is a required field." : ""}
              />
            </div>
          </div>
        </PopUpForm>
        {headingText === "Edit Recipient" && (
          <div style={{ marginTop: "10px", fontWeight: "bold" }}>Ship To</div>
        )}
        <PopUpForm style={{ marginTop: "5px" }}>
          <div>
            <div>Country *</div>
            <TextField
              defaultValue={formValues?.country}
              size="small"
              fullWidth
              value={"US"}
              name="country"
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.country}
              helperText={errors.phone ? "country is a required field." : ""}
            />
          </div>
          <div style={{ marginTop: "5px" }}>
            <div>Street *</div>
            <TextField
              defaultValue={formValues?.street1}
              size="small"
              fullWidth
              name="street1"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.street1}
              helperText={errors.phone ? "Street1 is a required field." : ""}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              marginTop: "5px",
            }}
          >
            <div style={{ width: "100%" }}>
              <div>Street (line2)</div>
              <TextField
                size="small"
                style={{ width: "100%" }}
                name="streetLine2"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div>City *</div>
              <TextField
                defaultValue={formValues?.city}
                size="small"
                error={errors.city}
                helperText={errors.city ? "Street1 is a required field." : ""}
                style={{ width: "100%" }}
                name="city"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              marginTop: "5px",
            }}
          >
            <div style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <div>
                  {errors.country && !formValues.state ? "State*" : "State"}
                </div>

                {formValues.country === "United States" ? (
                  <Autocomplete
                    style={{ width: "100%" }}
                    size="small"
                    disablePortal
                    options={getUSStates()}
                    value={formValues?.state}
                    onChange={handleStateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.state}
                        helperText={errors.state ? "State is required" : ""}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    size="small"
                    fullWidth
                    style={{ width: "100%" }}
                    name="state"
                    value={formValues?.state || ""}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div>Postal code / zip *</div>
              <TextField
                defaultValue={formValues?.zip}
                size="small"
                style={{ width: "100%" }}
                name="zip"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.zip}
                helperText={errors.zip ? "Zip is a required field." : ""}
              />
            </div>
          </div>
        </PopUpForm>
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            onClick={onClose}
            sx={{
              color: "black",
              border: "0.5px solid black",
              padding: "auto 15px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApply}
            sx={{
              color: "white",
              backgroundColor: "#3d3bde",
              "&:hover": {
                backgroundColor: "#3d3bde",
              },
            }}
          >
            Apply
          </Button>
        </div>
      </PopUpContainer>
    </>
  );
};

export default PopUp;
