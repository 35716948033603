import { Dialog, DialogContent } from "@mui/material";
import React, { useEffect } from "react";
import { useDashboardContext } from "../../contexts/DashboardContext/DashboardContext";
import PdfViewer from "../dashboard/shared/PdfViewer";
import { Toast } from "../../components/Toaster/Toaster";

const BatchModal = ({ displayPopup, setDisplayPopup }) => {
  const { pdfValue, pdfType } = useDashboardContext();
  useEffect(() => {
    if (pdfValue) {
      try {
        setDisplayPopup(true);
      } catch (error) {
        Toast.error("Error decoding base64:", error);
      }
    }
  }, [pdfValue]);

  return (
    <>
      <Dialog
        onClose={() => {
          setDisplayPopup(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={displayPopup}
        fullWidth={true}
      >
        <DialogContent>
          {pdfValue && <PdfViewer pdfContent={pdfValue} pdfType={pdfType} />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BatchModal;
