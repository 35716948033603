import React from "react";
import { LoadingButton } from "@mui/lab";
import { Card, Checkbox, Grid, TextField } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useAuth } from "../../contexts/AuthContext";
import { Formik } from "formik";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo-150x150 1.png";
import * as Yup from "yup";
import { Toast } from "../../components/Toaster/Toaster";

const FlexBox = styled(Box)(() => ({ display: "flex", alignItems: "center" }));

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  username: "",
  password: "",
  phonenumber: "",
  remember: true,
};
const validationSchema = Yup.object().shape({
  firstname: Yup.string().required(""),
  lastname: Yup.string().required(""),
  email: Yup.string().email("Invalid Email address").required(""),
  username: Yup.string().required(""),
  password: Yup.string().required(""),
  phonenumber: Yup.string().required(""),
});
const Register = () => {
  const { postRegisterApiCall, getMyProfileApiCall } = useAuth();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    setDisable(true);
    try {
      const res = await postRegisterApiCall(values);
      if (res?.success) {
        getMyProfileApiCall();
        Toast.success(res.message);
        navigate("/dashboard");
        Toast.error(res.message);
      } else {
        Toast.error(res.message);
        setLoading(false);
        setDisable(false);
      }
    } catch (e) {
      setDisable(false);
      setLoading(false);
    }
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#e9f1f5",
      }}
      container
    >
      <Grid item md={3}>
        <Card
          sx={{ minHeight: 400, textAlign: "center", m: 2, borderRadius: 4 }}
          elevation={4}
        >
          <div style={{ marginTop: 10 }}>
            <img src={logo} width={100} alt="Logo" />
          </div>
          <Grid>
            <Box p={4} height="100%">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      name="firstname"
                      type="text"
                      label="First Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.firstname}
                      onChange={handleChange}
                      helperText={touched.firstname && errors.firstname}
                      error={
                        touched.firstname &&
                        (Boolean(errors.firstname) || values.firstname === "")
                      }
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      name="lastname"
                      type="text"
                      label="Last Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.lastname}
                      onChange={handleChange}
                      helperText={touched.lastname && errors.lastname}
                      error={
                        touched.lastname &&
                        (Boolean(errors.lastname) || values.lastname === "")
                      }
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={
                        touched.email &&
                        (Boolean(errors.email) || values.email === "")
                      }
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="username"
                      label="Username"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.username}
                      onChange={handleChange}
                      helperText={touched.username && errors.username}
                      error={
                        touched.username &&
                        (Boolean(errors.username) || values.username === "")
                      }
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      error={
                        touched.password &&
                        (Boolean(errors.password) || values.password === "")
                      }
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="phonenumber"
                      type="number"
                      label="Phone Number"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.phonenumber}
                      onChange={handleChange}
                      helperText={touched.phonenumber && errors.phonenumber}
                      error={
                        touched.phonenumber &&
                        (Boolean(errors.phonenumber) ||
                          values.phonenumber === "")
                      }
                      sx={{ mb: 2 }}
                    />

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="remember"
                        onChange={handleChange}
                        checked={values.remember}
                        sx={{ padding: 0 }}
                      />

                      <div fontSize={13}>
                        I have read and agree to the terms of service.
                      </div>
                    </FlexBox>
                    <LoadingButton
                      type="submit"
                      disabled={disable}
                      style={{
                        backgroundColor: "#3D3BDE",
                        textTransform: "none",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      loading={loading}
                      variant="contained"
                      sx={{ my: 2 }}
                    >
                      Register
                    </LoadingButton>
                    <div>
                      Already have an account?
                      <NavLink
                        to="/login"
                        style={{
                          color: "#3D3BDE",
                          marginLeft: 5,
                        }}
                      >
                        Login
                      </NavLink>
                    </div>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Register;
