import { Grid, Typography, styled, CircularProgress } from "@mui/material";
import { Fragment, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import MenuSidebar from "../../components/MenuSideBar";
import ProductTable from "./ProductTable";
const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const Product = () => {
  const { user, profileLoading } = useAuth();
  const [searchFinalValue, setSearchFinalValue] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    pageSize: 10,
    pageNumber: 1,
    orderStatus: "",
  });
  return (
    <div style={{ display: "flex" }}>
      {/* <Navbar /> */}
      <MenuSidebar>
        {profileLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            <ContentBox className="analytics">
              <Typography variant="h6" marginBottom={"20px"}>
                Welcome {user?.username}
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ProductTable
                    searchFinalValue={searchFinalValue}
                    setSearchFinalValue={setSearchFinalValue}
                    paginationValue={paginationValue}
                    setPaginationValue={setPaginationValue}
                  />
                </Grid>
              </Grid>
            </ContentBox>
          </Fragment>
        )}
      </MenuSidebar>
    </div>
  );
};

export default Product;
