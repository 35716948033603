import {
  styled,
  Button,
  Card,
  IconButton,
  Chip,
  Box,
  Modal,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { Toast } from "../../components/Toaster/Toaster";
import CancelIcon from "@mui/icons-material/Cancel";
import { APIBaseURL } from "../../../common";
const ModalContainer = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "5px",
}));
const ModalForm = styled("div")(() => ({
  marginTop: 10,
  width: "100%",
}));
const OrderIdContainer = styled("div")(() => ({
  display: "flex",
  fontWeight: "600",
  fontSize: "16px",
  fontFamily:
    " Roboto,pragmatica-web,Helvetica Neue,Helvetica,Arial,sans-serif",
}));
const OrderDetailContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));
const BuyButtonContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));
const OrderDetailNav = ({
  orderId,
  isBuyButtonDisabled,
  handleCreateLabel,
  currentStatusValue,
  orderType,
  userId,
  trackingUpdated,
  resetOrderDetails,
  selectItemValue,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const [orderTypeValue, setOrderTypeValue] = useState("No Inventory");
  const handleStatusMenuItemClick = (value) => {
    setOrderTypeValue(value);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleRefundCloseModal = () => {
    setRefundModal(false);
  };
  const handleConfirmModelOpen = () => {
    setOpenConfirmModel(true);
  };

  const handleConfirmModelClose = () => {
    setOpenConfirmModel(false);
  };
  const handleLabelCreate = () => {
    handleCreateLabel();
    handleConfirmModelClose();
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard");
    resetOrderDetails();
  };

  const handleCancelOrder = () => {
    if (!selectItemValue?.length > 0) {
      return Toast.error("Please select at least one Item");
    }

    const payload = {
      orderId: orderId,
      cancel_reason: orderTypeValue,
      items: selectItemValue,
    };
    axios
      .post(`${APIBaseURL}/api/v1/amazon/order/cancel`, payload)
      .then((res) => {
        Toast.success(res.data.message);
      });
  };
  return (
    <>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "65%",
            bgcolor: "background.paper",
            p: 2,
            overflowY: "auto",
            overflowX: "hidden",
            border: "none",
          }}
        >
          <ModalContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 15,
              }}
            >
              <div style={{ fontSize: 22, fontWeight: "600" }}>
                Cancel Order
              </div>
              <div></div>
              <div
                style={{
                  borderRadius: 20,
                  border: "1px #d0d5dd solid",
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                }}
                onClick={handleCloseModal}
              >
                <img
                  style={{ padding: 3 }}
                  src="/assets/images/icon/close.svg"
                  alt=""
                />
              </div>
            </div>
            <ModalForm>
              <div style={{ height: 400 }}>
                <div>Cancel Type</div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={orderOptionTypeValue}
                  value={orderTypeValue}
                  onChange={(event, newValue) =>
                    handleStatusMenuItemClick(newValue)
                  }
                  sx={{ width: "500px", marginTop: 2 }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    color: "black",
                    border: "0.5px solid black",
                    padding: "auto 15px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCancelOrder}
                  sx={{
                    color: "white",
                    backgroundColor: "#3D3BDE",
                    "&:hover": {
                      backgroundColor: "#423dff",
                    },
                  }}
                >
                  Confirm
                </Button>
              </div>
            </ModalForm>
          </ModalContainer>
        </Box>
      </Modal>
      <Modal open={refundModal} onClose={handleRefundCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "65%",
            bgcolor: "background.paper",
            p: 2,
            overflowY: "auto",
            overflowX: "hidden",
            border: "none",
          }}
        >
          <ModalContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 15,
              }}
            >
              <div style={{ fontSize: 22, fontWeight: "600" }}>
                Refund Order
              </div>
              <div></div>
              <div
                style={{
                  borderRadius: 20,
                  border: "1px #d0d5dd solid",
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                }}
                onClick={handleRefundCloseModal}
              >
                <img
                  style={{ padding: 3 }}
                  src="/assets/images/icon/close.svg"
                  alt=""
                />
              </div>
            </div>
            <ModalForm>
              <div style={{ height: 400 }}>
                <div>Refund Type</div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={orderOptionTypeValue}
                  value={orderTypeValue}
                  onChange={(event, newValue) =>
                    handleStatusMenuItemClick(newValue)
                  }
                  sx={{ width: "500px", marginTop: 2 }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Button
                  onClick={handleRefundCloseModal}
                  sx={{
                    color: "black",
                    border: "0.5px solid black",
                    padding: "auto 15px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    color: "white",
                    backgroundColor: "#3D3BDE",
                    "&:hover": {
                      backgroundColor: "#423dff",
                    },
                  }}
                >
                  Confirm
                </Button>
              </div>
            </ModalForm>
          </ModalContainer>
        </Box>
      </Modal>
      <Card
        elevation={4}
        sx={{
          ml: 2,
          mr: 2,
          mt: 2,
          display: "flex",
          p: 2,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={goBack}>
            <ArrowBackIcon />
          </IconButton>
          <OrderDetailContainer>
            <OrderIdContainer>#{orderId}</OrderIdContainer>
          </OrderDetailContainer>

          <Box sx={{ marginY: "10px", ml: 1 }}>
            <Chip
              size="small"
              color={
                currentStatusValue === "Cancelled"
                  ? "error"
                  : currentStatusValue === "Partially Ship"
                  ? "primary"
                  : currentStatusValue === "Shipped"
                  ? "success"
                  : "default"
              }
              label={currentStatusValue ? currentStatusValue : "Unshipped"}
            />
          </Box>

          <Chip
            size="small"
            label="Paid"
            sx={{ color: "#fff", backgroundColor: "#3d3bde", ml: 1 }}
          />
        </div>
        <BuyButtonContainer>
          {currentStatusValue !== "Ready for Ship" && (
            <div style={{ marginLeft: 10 }}>
              <Button
                disabled={trackingUpdated}
                width={150}
                variant="contained"
                onClick={handleModalOpen}
                label={"Cancel Order"}
              >
                Cancel Order{" "}
              </Button>
            </div>
          )}
          <div style={{ marginLeft: 10 }}>
            <Button
              variant="contained"
              disabled={isBuyButtonDisabled}
              sx={{ width: 120 }}
              onClick={handleConfirmModelOpen}
              label={"Buy"}
            >
              Buy
            </Button>
          </div>
        </BuyButtonContainer>
      </Card>
      <Dialog open={openConfirmModel}>
        <div
          style={{
            width: 400,
            height: 250,
            borderRadius: 8,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <DialogTitle
            sx={{
              fontWeight: 600,
              pt: "15px",
              pb: "10px",
              background: "#f0f0f0",
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Confirmation</div>
            <CancelIcon
              style={{ color: "#666666", cursor: "pointer", fontSize: 27 }}
              onClick={handleConfirmModelClose}
            />
          </DialogTitle>
          <hr style={{ margin: 0, background: "#e0e0e0" }} />
          <DialogContent>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Are you certain you wish to buy this?
            </DialogContentText>
          </DialogContent>
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <hr style={{ margin: 0, background: "#e0e0e0" }} />
            <DialogActions
              style={{
                background: "#f0f0f0",
                padding: "10px",
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              <Button
                onClick={handleConfirmModelClose}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button onClick={handleLabelCreate} variant="contained">
                Confirm
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default OrderDetailNav;
const orderOptionTypeValue = [
  {
    id: 0,
    label: "No Inventory",
    value: "NoInventory",
  },
  {
    id: 1,
    label: "Shipping Address Undeliverable",
    value: "ShippingAddressUndeliverable",
  },
  {
    id: 2,
    label: "Customer Exchange",
    value: "CustomerExchange",
  },
  {
    id: 3,
    label: "Buyer Canceled",
    value: "BuyerCanceled",
  },
  {
    id: 4,
    label: "General Adjustment",
    value: "GeneralAdjustment",
  },
  {
    id: 5,
    label: "Carrier Credit Decision",
    value: "CarrierCreditDecision",
  },
  {
    id: 6,
    label: "Risk Assessment Information Not Valid",
    value: "RiskAssessmentInformationNotValid",
  },
  {
    id: 7,
    label: "Carrier Coverage Failure",
    value: "CarrierCoverageFailure",
  },
  {
    id: 8,
    label: "Customer Return",
    value: "CustomerReturn",
  },
  {
    id: 9,
    label: "Merchandise Not Received",
    value: "MerchandiseNotReceived",
  },
  {
    id: 10,
    label: "Cannot Verify Information",
    value: "CannotVerifyInformation",
  },
  {
    id: 11,
    label: "Pricing Error",
    value: "PricingError",
  },
  {
    id: 12,
    label: "Reject Order",
    value: "RejectOrder",
  },
  {
    id: 13,
    label: "WeatherDelay",
    value: "Weather Delay",
  },
];
