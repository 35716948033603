// LoginComponent.js
import React, { useState } from "react";
import {
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Box,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../contexts/AuthContext";
import { Toast } from "../../components/Toaster/Toaster";
import logo from "../../../assets/images/logo-150x150 1.png";
import MatxLoading from "../../components/MatxLoading";
const FlexBox = styled(Box)(() => ({ display: "flex", alignItems: "center" }));

const initialValues = {
  userName: "",
  password: "",
  remember: true,
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required(""),
  userName: Yup.string().required(""),
});
const LogInPage = () => {
  const navigate = useNavigate();
  const { postLoginApiCall, getMyProfileApiCall, profileLoading } = useAuth();

  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values) => {
    setDisable(true);
    setLoading(true);

    try {
      const res = await postLoginApiCall(values.userName, values.password);
      if (res?.success) {
        Toast.success(res.message);
        getMyProfileApiCall();
        navigate("/dashboard");
      } else {
        Toast.error(res.message);
        setDisable(false);
        setLoading(false);
      }
    } catch (e) {
      Toast.error("Authentication error:", e);
      setDisable(false);
      setLoading(true);
    }
  };
  if (profileLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <MatxLoading />
      </div>
    );
  }
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#e9f1f5",
      }}
      container
    >
      <Grid item md={3}>
        <Card
          sx={{ minHeight: 400, textAlign: "center", m: 2, borderRadius: 4 }}
          elevation={4}
        >
          <div style={{ marginTop: 10 }}>
            <img src={logo} width={100} alt="Logo" />
          </div>
          <Grid>
            <Box p={4} height="100%">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="userName"
                      label="Username"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.userName}
                      onChange={handleChange}
                      helperText={touched.userName && errors.userName}
                      error={
                        touched.userName &&
                        (Boolean(errors.userName) || values.userName === "")
                      }
                      sx={{ mb: 3 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        size="small"
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        onBlur={handleBlur}
                        value={values.password}
                        onChange={handleChange}
                        helperText={touched.password && errors.password}
                        error={
                          touched.password &&
                          (Boolean(errors.password) || values.password === "")
                        }
                        sx={{ mb: 1.5 }}
                        notched
                      />
                    </FormControl>
                    <FlexBox
                      style={{ marginTop: 10 }}
                      justifyContent="space-between"
                    >
                      <FlexBox gap={1}>
                        <Checkbox
                          size="small"
                          name="remember"
                          onChange={handleChange}
                          checked={values.remember}
                          sx={{ padding: 0 }}
                        />

                        <div>Remember Me</div>
                      </FlexBox>

                      <NavLink
                        style={{
                          color: "#3D3BDE",
                        }}
                      >
                        Forgot password?
                      </NavLink>
                    </FlexBox>
                    <Box sx={{ py: 4 }}>
                      <LoadingButton
                        type="submit"
                        disabled={disable}
                        style={{
                          backgroundColor: "#3D3BDE",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        loading={loading}
                        variant="contained"
                        sx={{ my: 2 }}
                      >
                        Login
                      </LoadingButton>
                    </Box>

                    <div>
                      Don't have an account?
                      <NavLink
                        to="/register"
                        style={{
                          marginLeft: 5,
                          color: "#3D3BDE",
                        }}
                      >
                        Register
                      </NavLink>
                    </div>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LogInPage;
