// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import {
  getMyDetails,
  postUserLogin,
  postUserRegister,
  updateUserDetails,
} from "../backendHelpers/apiCallerHelper";
import { Toast } from "../components/Toaster/Toaster";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const updateUser = async (data) => {
    updateUserDetails(data).then((res) => {
      Toast.success("User updated successfully");
    });
  };

  const postLoginApiCall = async (username, password) => {
    try {
      const data = {
        username,
        password,
      };
      const response = await postUserLogin(data);
      window.localStorage.setItem("accessToken", response.token);
      return response;
    } catch (error) {
      Toast.error(error.response.message);
    }
  };
  const getMyProfileApiCall = async (data) => {
    setProfileLoading(true);
    getMyDetails(data)
      .then((res) => {
        if (res?.user) {
          setUserData(res?.user);
        }
        if (res?.message === "Invalid token") {
          Toast.error("Session expire sign in again");
          window.localStorage.clear("accessToken");
        }
      })
      .catch((err) => {
        Toast.error(err.message);
      })
      .finally(() => {
        setProfileLoading(false);
      });
  };
  const getMyProfileCompanyApiCall = async () => {
    try {
      const response = await getMyDetails();
      return response;
    } catch (error) {
      // Handle errors
      return error?.response;
    }
  };

  const postRegisterApiCall = async (values) => {
    try {
      const data = { ...values };
      const response = await postUserRegister(data);
      window.localStorage.setItem("accessToken", response.token);
      return response;
    } catch (error) {
      Toast.error(error.response.message);
    }
  };

  useEffect(() => {
    (async () => {
      getMyProfileApiCall();
    })();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        setUser,
        user,
        postLoginApiCall,
        postRegisterApiCall,
        getMyProfileApiCall,
        profileLoading,
        setProfileLoading,
        getMyProfileCompanyApiCall,
        updateUser,
        userData,
        setUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
